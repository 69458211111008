import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberCh',
  pure: true
})
export class NumberChPipe implements PipeTransform {
  transform(value: string | null, ...args: unknown[]): string | null {
    return value ? value.replace(',','\'') : null;
  }
}
