import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatSelectModule} from "@angular/material/select";
import {MatInputModule} from "@angular/material/input";
import {MatCardModule} from '@angular/material/card';
import {MatDividerModule} from "@angular/material/divider";
import {HTTP_INTERCEPTORS} from "@angular/common/http";

import {LibStorageClientModule} from "@alpq/lib-storage-client";
import {LibLanguageModule} from "@alpq/lib-language";

import {AuthenticationRoutingModule} from "./lib-authentication-routing.module";
import { LibAuthenticationComponent } from './lib-authentication.component';
import { SigninComponent } from './signin/signin.component';
import { SignupComponent } from './signup/signup.component';
import {AuthenticationInterceptor} from "./authentication.interceptor";


@NgModule({
  declarations: [
    LibAuthenticationComponent,
    SigninComponent,
    SignupComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatCardModule,
    MatDividerModule,
    LibStorageClientModule,
    LibLanguageModule,
    AuthenticationRoutingModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true }
  ],
  exports: [LibAuthenticationComponent, SigninComponent, SignupComponent]
})
export class LibAuthenticationModule {
  public static forRoot(environment: any): ModuleWithProviders<any> {
    return {
      ngModule: LibAuthenticationModule,
      providers: [
        {
          provide: 'env', // you can also use InjectionToken
          useValue: environment
        }
      ]
    };
  }
}
