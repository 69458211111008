<mat-tab-group [(selectedIndex)]="selectedTab.index">
  <mat-tab label="{{tabs[0].name}}">
    <ng-template matTabContent>
      <lib-report></lib-report>
    </ng-template>
  </mat-tab>
  <mat-tab label="{{tabs[1].name}}" [disabled]="eodDisabled">
    <ng-template matTabContent>
      <lib-eod></lib-eod>
    </ng-template>
  </mat-tab>
  <mat-tab label="{{tabs[2].name}}">
    <ng-template matTabContent>
      <lib-run></lib-run>
    </ng-template>
  </mat-tab>
  <mat-tab label="{{tabs[3].name}}">
    <ng-template matTabContent>
      <lib-cockpit></lib-cockpit>
    </ng-template>
  </mat-tab>
  <mat-tab label="{{tabs[4].name}}">
    <ng-template matTabContent>
      <lib-monthly-switch></lib-monthly-switch>
    </ng-template>
  </mat-tab>
</mat-tab-group>
