import { Injectable } from '@angular/core';
import {Observable} from "rxjs";


import {LibApiService} from "../lib-api.service";
import {ICheckMeta} from "@alpq/lib-tsvpe";


export interface IAssetResponse {
  extrinsic?: string[];
  intrinsic?: string[];
  intrinsic_ST: string[];
  intrinsic_LT: string[];
  extrinsic_ST: string[];
  extrinsic_LT: string[];
}

export interface IValidationReportKV {
  [key: string]: string | number;
}

export interface IValidationReportData {
  [key: string]: IValidationReportKV;
  total: IValidationReportKV;
  cob1: IValidationReportKV;
  cob2: IValidationReportKV;
  run1: IValidationReportKV;
  run2: IValidationReportKV;
  stamp1: IValidationReportKV;
  stamp2: IValidationReportKV;
  modified1: IValidationReportKV;
  modified2: IValidationReportKV;
  d2_valid_status: IValidationReportKV;
}

export interface IValidationReportResponse {
  data: IValidationReportData;
  errors: any;
}

export interface IDeltaParams {
  metric?: string;
  freq?: string;
  agg?: string;
  dec?: string;
  aggCol?: string;
  last_day?: string;
}

export interface IDeltaData {
  [key: string]: string | number;
  asset: string;
  runid1: number;
  runid2: number;
}

export interface IDeltaResponse {
  data?: IDeltaData;
  error?: string;
}

export interface IRunCheck {
  runid: number;
  cob: number;
  official: boolean;
  valid: boolean;
  validation_comment: string;
}

export interface ICheck {
  number: number;
  name: string;
  granularity: string;
  purpose: string;
}

export interface IMetadataCheck {
  asset: string;
  check: ICheck;
  main_asset: string;
  type: string;
  run1: IRunCheck;
  run2: IRunCheck;
  threshold_first_hydro_year?: number;
  threshold_remaining_hydro_years?: number;
  threshold_per_period?: number;
}

export interface ICheckResponse {
  check: 'OK' | 'NOK';
  delta: {
    [key: string]: string | number;
  };
  fails: number;
  metadata: IMetadataCheck;
  violations: any;
  violations2: any;
  url?: string;
}

export interface IEodFullInput2 extends IEodInput2 {
  intrinsic_cob1?: number;
  intrinsic_cob2?: number;
  extrinsic_cob1?: number;
  extrinsic_cob2?: number;
}

export interface IEodFullInput extends IEodInput {
  cob1: number;
  cob2: number;
}

export interface IEodInput {
  asset: string;
  runId1: number;
  runId2: number;
}

export interface IEodInput2 {
  asset: string;
  intrinsic_runId1?: number;
  intrinsic_runId2?: number;
  extrinsic_runId1?: number;
  extrinsic_runId2?: number;
}

export interface IEodInput3 {
  cob: string;
  horizon: string;
  HourlyRiskTaskIdentifier?: string;
  HourlyRiskStatus?: string;
  assets: IEodFullInput2[];
}

export interface IEodReportStructure {
  index: string[];
  columns: string[][];
  data: number[][];
  index_names: string[];
  column_names: string[];
}

export interface IEodReportTime {
  days: IEodReportStructure;
  weekends: IEodReportStructure;
  weeks: IEodReportStructure;
  months: IEodReportStructure;
}

export interface IEodReport {
  main: IEodReportTime;
  pzone?: IEodReportTime;
}

export interface IEodData {
  EOD_check: IEodInput[];
  int_pos_abs_MWH?: IEodReport;
  int_pos_abs_MW?: IEodReport;
  int_pos_diff_MWH?: IEodReport;
  int_pos_diff_MW?: IEodReport;
  ext_hedge_diff_MWH?: IEodReport;
  ext_hedge_diff_MW?: IEodReport;
  ext_pos_diff_MWH?: IEodReport;
  ext_pos_diff_MW?: IEodReport;
  ext_Hminusint_diff_MWH?: IEodReport;
  ext_Hminusint_diff_MW?: IEodReport;
  ext_hedge_abs_MWH?: IEodReport;
  ext_hedge_abs_MW?: IEodReport;
  ext_pos_abs_MWH?: IEodReport;
  ext_pos_abs_MW?: IEodReport;
  ext_Hminusint_abs_MWH?: IEodReport;
  ext_Hminusint_abs_MW?: IEodReport;
  [key: string]: any;
}

export interface IDeltaTableRowForExport {
  asset: string;
  years: {[key: string]: number}[];
  cob1: number | null;
  cob2: number | null;
  runid1: number | null;
  runid2: number | null;
  check: string | null;
  version1: number | null;
  version2: number | null;
  valid1: boolean | null;
  official1: boolean | null;
  run1OfficialNotLast: boolean | null;
  to_export1: boolean | null;
  checks: ICheckMeta[];
}

export interface IRunIdPair {
  intrinsic_runId: number;
  extrinsic_runId: number;
  intrinsic_cob: number;
  extrinsic_cob: number;
}

export interface IExportExtrinsic {
  [asset: string]: IRunIdPair;
}

export interface IAssetDependencies {
  tree: { [asset: string]: string[]; };
  groups: { [asset: string]: string[]; };
}

export const VALIDATION_URL = 'validation';
export const ASSETS_VALIDATION_URL = 'validation/assets';
export const ASSETS_DEPENDENCIES_URL = 'validation/assets/dependencies';
export const V_REPORT_VALIDATION_URL = 'validation/validation_report';
export const CHECKS_VALIDATION_URL = 'validation/reports/checks';
export const EOD_URL = 'validation/EODcheck';
export const EOD_2_URL = 'validation/reports/EODsnapshot2';
export const SEND_REPORT_URL = 'validation/reports/sendReport';
export const EXPORT_EXTRINSIC_URL = 'validation/reports/exportExtrinsic';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  constructor(private _apiService: LibApiService) { }

  getAssets$(): Observable<IAssetResponse> {
    return this._apiService.get$<IAssetResponse>(ASSETS_VALIDATION_URL);
  }

  getAssetDependencies$(): Observable<IAssetDependencies> {
    return this._apiService.get$<IAssetDependencies>(ASSETS_DEPENDENCIES_URL);
  }

  /*getValidationReport$(stoch: boolean, databook: string, assetArrayString?: string, cob?: string): Observable<IValidationReportResponse> {
    return this._apiService.get$<IValidationReportResponse>(V_REPORT_VALIDATION_URL, {asset: assetArrayString, cob, stoch: stoch.toString(), databook});
  }

  getDelta$(runId1: number, runId2: number, asset: string, params?: IDeltaParams): Observable<IDeltaResponse> {
    return this._apiService.get$<IDeltaResponse>(VALIDATION_URL + `/${runId1}` + `/${runId2}` + `/${asset}` + '/delta', params);
  }*/

  getCheck$(check: number, runId1: number, runId2: number, asset: string, stoch: boolean): Observable<ICheckResponse> {
    return this._apiService.get$<ICheckResponse>(CHECKS_VALIDATION_URL + `/${check}` + `/${runId1}` + `/${runId2}` + `/${asset}`, {stoch: stoch.toString()});
  }

  /*postEodCheck$(assetRuns: IEodInput[]): Observable<IEodData> {
    return this._apiService.post$<IEodData>(EOD_URL, assetRuns);
  }*/

  postEodCheck2$(eodInput: IEodInput3): Observable<IEodData> {
    return this._apiService.post$<IEodData>(EOD_2_URL, eodInput);
  }

  postSendReport$(reports: {intrinsic: IDeltaTableRowForExport[], extrinsic: IDeltaTableRowForExport[], eod: IEodData, comment: string, horizon: string}): Observable<any> {
    return this._apiService.post$<any>(SEND_REPORT_URL, reports);
  }

  postExportExtrinsic$(runIdExport: IExportExtrinsic): Observable<any> {
    return this._apiService.post$<any>(EXPORT_EXTRINSIC_URL, runIdExport);
  }
}
