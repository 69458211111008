<h1 mat-dialog-title>
  Logs
</h1>
<div mat-dialog-content>
  <!--<mat-list *ngIf="data && data.length > 0">
    <mat-list-item *ngFor="let log of data">
      <textarea mat-line class="error-textarea" title="{{log}}">{{log}}</textarea>
    </mat-list-item>
  </mat-list>-->

  <div *ngIf="data && data.length > 0">
    <div class="error-div" *ngFor="let log of data" title="{{log.error.message ? log.error.message : log.error.reason ? log.error.reason : log.error}}">
      <span class="error-msg">{{log.error.message ? log.error.message : log.error.reason ? log.error.reason : log.error}}</span>
      <span class="error-url"><a href="{{log.url}}" target=”_blank”>{{log.url}}</a></span>
    </div>
  </div>
</div>
