<h1 mat-dialog-title>
  Runs
</h1>
<div mat-dialog-content>
  <div class="run-panel" *ngIf="data.rowSelected.runid1 && run1Version && run1Version.runId">
    <h4 class="run-panel-title">
      {{data.rowSelected.runid1}}
      <button mat-icon-button aria-label="Edit"
              (click)="onEditClick(data.rowSelected.runid1!)">
        <!--<mat-icon>edit</mat-icon>-->
        <mat-icon matDatepickerToggleIcon svgIcon="mode_edit"></mat-icon>
      </button>
    </h4>
    <mat-grid-list cols="5" [rowHeight]="20">

      <mat-grid-tile colspan="2">Run Id</mat-grid-tile>
      <mat-grid-tile class="tile-disabled" colspan="3">
        {{run1Version.runId}}
      </mat-grid-tile>

      <mat-grid-tile colspan="2">Version</mat-grid-tile>
      <mat-grid-tile class="tile-disabled" colspan="3">
        {{run1Version.version}}
      </mat-grid-tile>

      <mat-grid-tile colspan="2">Cob</mat-grid-tile>
      <mat-grid-tile class="tile-disabled" colspan="3">
        {{run1Version.cob}}
      </mat-grid-tile>

      <mat-grid-tile colspan="2">Databook</mat-grid-tile>
      <mat-grid-tile class="tile-disabled" colspan="3">
        {{run1Version.databook}}
      </mat-grid-tile>

      <mat-grid-tile colspan="2">Asset</mat-grid-tile>
      <mat-grid-tile class="tile-disabled" colspan="3">
        {{run1Version.asset}}
      </mat-grid-tile>

      <mat-grid-tile colspan="2">Fuel</mat-grid-tile>
      <mat-grid-tile class="tile-disabled" colspan="3">
        {{run1Version.fuel}}
      </mat-grid-tile>

      <mat-grid-tile colspan="2">Timestamp</mat-grid-tile>
      <mat-grid-tile class="tile-disabled" colspan="3">
        {{run1Version.timestamp}}
      </mat-grid-tile>

      <mat-grid-tile colspan="2">Modified</mat-grid-tile>
      <mat-grid-tile class="tile-disabled" colspan="3">
        {{run1Version.modified}}
      </mat-grid-tile>

      <mat-grid-tile colspan="2">Official</mat-grid-tile>
      <mat-grid-tile class="tile-disabled" colspan="3">
        {{run1Version.official}}
      </mat-grid-tile>

      <mat-grid-tile colspan="2">To Export</mat-grid-tile>
      <mat-grid-tile class="tile-disabled" colspan="3">
        {{run1Version.to_export}}
      </mat-grid-tile>

      <mat-grid-tile colspan="2">Valid</mat-grid-tile>
      <mat-grid-tile class="tile-disabled" colspan="3">
        {{run1Version.valid}}
      </mat-grid-tile>

      <mat-grid-tile colspan="2" rowspan="3">Reason</mat-grid-tile>
      <mat-grid-tile class="tile-disabled scrollable" colspan="3" rowspan="3">
        {{run1Version.reason}}
      </mat-grid-tile>

      <mat-grid-tile colspan="2" rowspan="2">Validation by</mat-grid-tile>
      <mat-grid-tile class="tile-disabled" colspan="3" rowspan="2">
        {{run1Version.validationBy}}
      </mat-grid-tile>

      <mat-grid-tile colspan="2">Pzone</mat-grid-tile>
      <mat-grid-tile class="tile-disabled" colspan="3">
        {{run1Version.pzone}}
      </mat-grid-tile>

      <mat-grid-tile colspan="2">Scen</mat-grid-tile>
      <mat-grid-tile class="tile-disabled" colspan="3">
        {{run1Version.scen}}
      </mat-grid-tile>

      <mat-grid-tile colspan="2">Stoch</mat-grid-tile>
      <mat-grid-tile class="tile-disabled" colspan="3">
        {{run1Version.stoch}}
      </mat-grid-tile>

      <mat-grid-tile rowspan="4">Export</mat-grid-tile>
      <mat-grid-tile>Id</mat-grid-tile>
      <mat-grid-tile class="tile-disabled" colspan="3">
        {{run1Version.export?.exportId}}
      </mat-grid-tile>

      <mat-grid-tile>Sent</mat-grid-tile>
      <mat-grid-tile class="tile-disabled" colspan="3">
        {{run1Version.export?.sent}}
      </mat-grid-tile>

      <mat-grid-tile>System</mat-grid-tile>
      <mat-grid-tile class="tile-disabled" colspan="3">
        {{run1Version.export?.system}}
      </mat-grid-tile>

      <mat-grid-tile>Who</mat-grid-tile>
      <mat-grid-tile class="tile-disabled" colspan="3">
        {{run1Version.export?.who}}
      </mat-grid-tile>

      <mat-grid-tile colspan="2" rowspan="3">Comment</mat-grid-tile>
      <mat-grid-tile class="tile-disabled scrollable" colspan="3" rowspan="3">
        {{run1Version.comment}}
      </mat-grid-tile>

      <mat-grid-tile colspan="2" rowspan="3">Tags</mat-grid-tile>
      <mat-grid-tile class="tile-disabled scrollable" colspan="3" rowspan="3">
        {{run1Version.tags | json}}
      </mat-grid-tile>
    </mat-grid-list>
  </div>
  &nbsp;&nbsp;
  <div class="run-panel" *ngIf="data.rowSelected.runid2 && run2Version && run2Version.runId">
    <h4 class="run-panel-title">
      {{data.rowSelected.runid2}}
      <button mat-icon-button aria-label="Edit"
              (click)="onEditClick(data.rowSelected.runid2!)">
        <!--<mat-icon>edit</mat-icon>-->
        <mat-icon matDatepickerToggleIcon svgIcon="mode_edit"></mat-icon>
      </button>
    </h4>
    <mat-grid-list cols="5" [rowHeight]="20">

      <mat-grid-tile colspan="2">Run Id</mat-grid-tile>
      <mat-grid-tile class="tile-disabled" colspan="3">
        {{run2Version.runId}}
      </mat-grid-tile>

      <mat-grid-tile colspan="2">Version</mat-grid-tile>
      <mat-grid-tile class="tile-disabled" colspan="3">
        {{run2Version.version}}
      </mat-grid-tile>

      <mat-grid-tile colspan="2">Cob</mat-grid-tile>
      <mat-grid-tile class="tile-disabled" colspan="3">
        {{run2Version.cob}}
      </mat-grid-tile>

      <mat-grid-tile colspan="2">Databook</mat-grid-tile>
      <mat-grid-tile class="tile-disabled" colspan="3">
        {{run2Version.databook}}
      </mat-grid-tile>

      <mat-grid-tile colspan="2">Asset</mat-grid-tile>
      <mat-grid-tile class="tile-disabled" colspan="3">
        {{run2Version.asset}}
      </mat-grid-tile>

      <mat-grid-tile colspan="2">Fuel</mat-grid-tile>
      <mat-grid-tile class="tile-disabled" colspan="3">
        {{run2Version.fuel}}
      </mat-grid-tile>

      <mat-grid-tile colspan="2">Timestamp</mat-grid-tile>
      <mat-grid-tile class="tile-disabled" colspan="3">
        {{run2Version.timestamp}}
      </mat-grid-tile>

      <mat-grid-tile colspan="2">Modified</mat-grid-tile>
      <mat-grid-tile class="tile-disabled" colspan="3">
        {{run2Version.modified}}
      </mat-grid-tile>

      <mat-grid-tile colspan="2">Official</mat-grid-tile>
      <mat-grid-tile class="tile-disabled" colspan="3">
        {{run2Version.official}}
      </mat-grid-tile>

      <mat-grid-tile colspan="2">To Export</mat-grid-tile>
      <mat-grid-tile class="tile-disabled" colspan="3">
        {{run2Version.to_export}}
      </mat-grid-tile>

      <mat-grid-tile colspan="2">Valid</mat-grid-tile>
      <mat-grid-tile class="tile-disabled" colspan="3">
        {{run2Version.valid}}
      </mat-grid-tile>

      <mat-grid-tile colspan="2" rowspan="3">Reason</mat-grid-tile>
      <mat-grid-tile class="tile-disabled scrollable" colspan="3" rowspan="3">
        {{run2Version.reason}}
      </mat-grid-tile>

      <mat-grid-tile colspan="2" rowspan="2">Validation by</mat-grid-tile>
      <mat-grid-tile class="tile-disabled" colspan="3" rowspan="2">
        {{run2Version.validationBy}}
      </mat-grid-tile>

      <mat-grid-tile colspan="2">Pzone</mat-grid-tile>
      <mat-grid-tile class="tile-disabled" colspan="3">
        {{run2Version.pzone}}
      </mat-grid-tile>

      <mat-grid-tile colspan="2">Scen</mat-grid-tile>
      <mat-grid-tile class="tile-disabled" colspan="3">
        {{run2Version.scen}}
      </mat-grid-tile>

      <mat-grid-tile colspan="2">Stoch</mat-grid-tile>
      <mat-grid-tile class="tile-disabled" colspan="3">
        {{run2Version.stoch}}
      </mat-grid-tile>

      <mat-grid-tile rowspan="4">Export</mat-grid-tile>
      <mat-grid-tile>Id</mat-grid-tile>
      <mat-grid-tile class="tile-disabled" colspan="3">
        {{run2Version.export?.exportId}}
      </mat-grid-tile>

      <mat-grid-tile>Sent</mat-grid-tile>
      <mat-grid-tile class="tile-disabled" colspan="3">
        {{run2Version.export?.sent}}
      </mat-grid-tile>

      <mat-grid-tile>System</mat-grid-tile>
      <mat-grid-tile class="tile-disabled" colspan="3">
        {{run2Version.export?.system}}
      </mat-grid-tile>

      <mat-grid-tile>Who</mat-grid-tile>
      <mat-grid-tile class="tile-disabled" colspan="3">
        {{run2Version.export?.who}}
      </mat-grid-tile>

      <mat-grid-tile colspan="2" rowspan="3">Comment</mat-grid-tile>
      <mat-grid-tile class="tile-disabled scrollable" colspan="3" rowspan="3">
        {{run2Version.comment}}
      </mat-grid-tile>

      <mat-grid-tile colspan="2" rowspan="3">Tags</mat-grid-tile>
      <mat-grid-tile class="tile-disabled scrollable" colspan="3" rowspan="3">
        {{run2Version.tags | json}}
      </mat-grid-tile>
    </mat-grid-list>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button class="alpq-btn-secondary" (click)="onNoClick()">
    Cancel
  </button>
</div>
