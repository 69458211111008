import { NgModule } from '@angular/core';
import { LibProgressSpinnerComponent } from './lib-progress-spinner.component';



@NgModule({
  declarations: [
    LibProgressSpinnerComponent
  ],
  imports: [
  ],
  exports: [
    LibProgressSpinnerComponent
  ]
})
export class LibProgressSpinnerModule { }
