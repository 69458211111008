import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {MatButton} from "@angular/material/button";
import {OverlayContainer} from "@angular/cdk/overlay";
import {
  catchError,
  concatMap, EMPTY, filter, finalize,
  interval,
  merge, mergeMap,
  Observable,
  of,
  Subject,
  switchMap,
  takeUntil,
  tap
} from "rxjs";

import moment from 'moment';

import {
  IAssetGroup,
  IDatabook,
  IMonthlySwitchReport,
  IMonthlySwitchReportResponse, IMonthlySwitchStatusResponse,
  IRun, ISummaryGroupKey,
  ReportingService,
  RunloaderService
} from "@alpq/lib-api";
import {LibNotificationService} from "@alpq/lib-notification";
import {HttpErrorResponse} from "@angular/common/http";



const ASSET_GROUPS_MOCK: IAssetGroup[] = [
  {group: 'GD', assets: ["GD", "GDphysique"]},
  {group: 'NDD', assets: ["NDD P", "NDD T", "NDDfiktiv P", "NDDfiktiv T"]},
  {group: 'MKW', assets: ["Maggiaalpiq2", "Rhowag"]},
  {group: 'VPPNDD', assets: ["VPP1830NDD1", "VPP1830NDD2"]},
  {group: 'VPSSKSBB', assets: ["VPSSKSBB", "VPSSKSBBfiktiv"]},
  {group: 'capaESA', assets: ["FlowcapacityEmosson", "FlowcapacityEmossonToP"]},
  {group: 'catt', assets: ["Cattenom", "FlowCattenom"]},
  {group: 'fmhl', assets: ["FMHL P", "FMHL T", "FMHLfiktiv P", "FMHLfiktiv T", "FMHLgrpE P", "FMHLgrpE T"]},
  {group: 'pb', assets: ["FlowB300", "PB"]}
];

const DATABOOK_MOCK: IDatabook = {
  databook: [
    "Optimisation",
    "userA",
    "userB",
    "userC",
    "userSDL",
    "calcDelta",
    "ADN",
    "AVA",
    "AVA2",
    "DevTest",
    "Friday",
    "JAN",
    "KAE",
    "MEG",
    "MEG2",
    "MEGDev",
    "OptimisationLT",
    "STreopt",
    "teamLT",
    "userDVT"
  ],
  default: [
    "Optimisation",
    "calcDelta",
    "Optimisation"
  ]
}

export interface ISwitchRun {
  runId?: number;
  databook?: string;
  status?: string;
  timestamp?: string;
  manuallyEdited?: boolean;
  missing?: boolean;
}

interface IAssetRun {
  asset: string;
  new?: ISwitchRun;
  calcDelta?: ISwitchRun;
  old?: ISwitchRun;
}

interface IGroupAssetRun {
  group: string;
  assets: IAssetRun[];
}

export enum ASSET_RUN_TYPE {
  NEW,
  CALC_DELTA,
  OLD
}

export enum REPORT_STATUS {
  OK='OK',
  NOK='NOK',
  NOT_READY='NOT_READY',
  EXPIRED_OR_NOT_FOUND='EXPIRED_OR_NOT_FOUND'
}

export interface IStatusPipe {
  group?: string;
  taskId: string;
  interval$: Observable<any>;
  stopper$: Subject<any>;
}

export interface IReportStatus {
  group: string;
  task_id: string;
  message?: string;
  status?: string;
  path?: string;
}

@Component({
  selector: 'lib-monthly-switch',
  templateUrl: './monthly-switch.component.html',
  styleUrls: ['./monthly-switch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MonthlySwitchComponent implements OnInit, OnDestroy {
  private readonly _unsubscribeS$: Subject<any> = new Subject<any>();
  private readonly _load$: Subject<any> = new Subject<any>();

  private readonly _dateTimePattern = '^\\d{4}\\-(0[1-9]|1[012])\\-(0[1-9]|[12][0-9]|3[01])T(?:[01]\\d|2[0123]):(?:[012345]\\d):(?:[012345]\\d)$';
  today = moment();
  databookOptions: string[] = [];
  readonly statusOptions = ['last valid official', 'last valid', 'last'];

  fgMonthlySwitch = this._formBuilder.group({
    dateControlNew: [moment().startOf('day').toDate(), Validators.required],
    dateControlOld: [moment().subtract(1, "days").startOf('day').toDate(), Validators.required],
    // suffix: ['', [Validators.maxLength(64)]],
    databookNew: ['', Validators.required],
    databookCalculDelta: ['', Validators.required],
    databookOld: ['', Validators.required],
    statusNew: [this.statusOptions[0], Validators.required],
    statusCalculDelta: [this.statusOptions[2], Validators.required],
    statusOld: [this.statusOptions[0], Validators.required],
    beforeNew: ['', [Validators.maxLength(19), Validators.pattern(this._dateTimePattern)]],
    beforeCalculDelta: ['', [Validators.maxLength(19), Validators.pattern(this._dateTimePattern)]],
    beforeOld: ['', [Validators.maxLength(19), Validators.pattern(this._dateTimePattern)]]
  });

  suffixControl = new FormControl(null, [Validators.maxLength(64)]);

  assetGroupsControl = new FormControl();
  assetGroups: IAssetGroup[];
  assetGroupsSelected: IGroupAssetRun[];

  loadBtnClass = 'alpq-btn-primary';

  get loaded(): boolean {
    return this.assetGroupsSelected.some((assetGroup: IGroupAssetRun) => assetGroup.assets.some((assetRun: IAssetRun) => assetRun.new || assetRun.calcDelta || assetRun.old));
  }
  disableReports = false;

  private _loadBtnInterval$ = interval(700)
    .pipe(
      takeUntil(this._unsubscribeS$),
      takeUntil(this._load$),
      tap(() => {
        this.loadBtnClass = this.loadBtnClass === 'alpq-btn-primary' ? 'alpq-btn-primary alpq-btn-primary-hover' : 'alpq-btn-primary';
        this.disableReports = true;
        this._cdRef.markForCheck();
      }),
      finalize(() => {
        this.loadBtnClass = 'alpq-btn-primary';
        this.disableReports = false;
        this._cdRef.markForCheck();
      })
    );

  private _reportStatusArr$: IStatusPipe[] = [];

  reportStatuses: IReportStatus[] = [];

  @ViewChild('loadBtn') loadBtn: MatButton;

  constructor(private _formBuilder: FormBuilder,
              private _runloaderService: RunloaderService,
              private _reportingService: ReportingService,
              private _notifService: LibNotificationService,
              private _cdRef: ChangeDetectorRef,
              private _overlayContainer: OverlayContainer) { }

  ngOnInit(): void {
    this._runloaderService.getCobs$()
      .pipe(
        takeUntil(this._unsubscribeS$),
        tap((cobs: number[]) => {
          this.fgMonthlySwitch.get('dateControlNew')!.patchValue(cobs[0] ? moment(cobs[0], "YYYYMMDD").startOf('day').toDate() : moment().startOf('day').toDate());
          this.fgMonthlySwitch.get('dateControlOld')!.patchValue(cobs[1] ? moment(cobs[1], "YYYYMMDD").startOf('day').toDate() : moment().subtract(1, "days").startOf('day').toDate());
        }),
        switchMap(() => this._reportingService.getDatabook$().pipe(catchError(() => of(DATABOOK_MOCK)))),
        tap((dataBook: IDatabook) => {
          this.databookOptions = dataBook.databook;
          this.fgMonthlySwitch.get('databookNew')!.patchValue(dataBook.default[0] ? dataBook.default[0] : '');
          this.fgMonthlySwitch.get('databookCalculDelta')!.patchValue(dataBook.default[1] ? dataBook.default[1] : '');
          this.fgMonthlySwitch.get('databookOld')!.patchValue(dataBook.default[2] ? dataBook.default[2] : '');
        }),
        switchMap(() => this._reportingService.getAssetGroups$().pipe(catchError(() => of(ASSET_GROUPS_MOCK)))),
        tap((assetGroups: IAssetGroup[]) => this.assetGroups = assetGroups)
      )
      .subscribe();

    this.assetGroupsControl.valueChanges
      .pipe(
        takeUntil(this._unsubscribeS$),
        tap((assetGroupsSelected: IAssetGroup[]) => {
          this.assetGroupsSelected = [];
          assetGroupsSelected.forEach((assetGroup: IAssetGroup) => {
            const groupAssetRun: IGroupAssetRun = {group: assetGroup.group, assets: []};
            assetGroup.assets.forEach((asset: string) => groupAssetRun.assets.push({asset: asset}));
            this.assetGroupsSelected.push(groupAssetRun);
          })
        })
      )
      .subscribe();

    this.fgMonthlySwitch.valueChanges
      .pipe(
        takeUntil(this._unsubscribeS$),
        concatMap(() => (this.assetGroupsSelected && this.assetGroupsSelected.length > 0 && this.loaded) ? this._loadBtnInterval$ : EMPTY)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._load$.next(null);
    this._load$.complete();
    this._unsubscribeS$.next(null);
    this._unsubscribeS$.complete();
  }

  public getRun(group: string, asset: string, type: ASSET_RUN_TYPE, cobControl: string, dataBookControl: string, statusControl: string, beforeControl: string): Observable<IRun[]> {
    const validParam: string = this.fgMonthlySwitch.get(statusControl)?.value.includes('valid') ? this.fgMonthlySwitch.get(statusControl)?.value.includes('valid').toString() : undefined;
    const officialParam: string = this.fgMonthlySwitch.get(statusControl)?.value.includes('official') ? this.fgMonthlySwitch.get(statusControl)?.value.includes('official').toString() : undefined;
    return this._runloaderService.getLastRuns$({
      asset: asset,
      cob: moment(this.fgMonthlySwitch.get(cobControl)?.value).format('YYYYMMDD'),
      databook: this.fgMonthlySwitch.get(dataBookControl)?.value!,
      before: this.fgMonthlySwitch.get(beforeControl)?.value!,
      timeReference: this.fgMonthlySwitch.get(beforeControl)?.value ? 'InsertionTime' : undefined,
      // closest: 'true',
      stoch: 'false',
      lastVersionOnly: 'true',
      valid: validParam,
      official: officialParam
    }).pipe(
      catchError((err: any) => of()),
      tap((runs: IRun[]) => {
        const assetGroup = this.assetGroupsSelected.find((groupAssetRun: IGroupAssetRun) => groupAssetRun.group === group)!;
        const assetRun = assetGroup.assets.find((assetRun: IAssetRun) => assetRun.asset === asset)!;
        let filledAssetRun: ISwitchRun;

        if (runs && runs.length === 1) {
          const run = runs[0];
          const status = 'last' + (run.valid ? run.official ? ' valid official' : ' valid' : '');
          filledAssetRun = {runId: run.runId, databook: run.databook, status: status, timestamp: run.timestamp};
        } else {
          filledAssetRun = {runId: undefined, missing: true};
        }

        switch (type) {
          case ASSET_RUN_TYPE.NEW:
            assetRun.new = filledAssetRun
            break;
          case ASSET_RUN_TYPE.CALC_DELTA:
            assetRun.calcDelta = filledAssetRun;
            break;
          case ASSET_RUN_TYPE.OLD:
            assetRun.old = filledAssetRun;
            break;
          default: break;
        }
        this._cdRef.markForCheck();
      })
    );
  }

  public load(): void {
    if (!this.assetGroupsSelected || this.assetGroupsSelected.length === 0) return;
    this._load$.next(null);
    let lastRunsArr$: Observable<IRun[]>[] = [];
    this.assetGroupsSelected.forEach((assetGroup: IGroupAssetRun) => {
      assetGroup.assets.forEach((asset: IAssetRun) => {
        const lastRunNew$ = this.getRun(assetGroup.group, asset.asset, ASSET_RUN_TYPE.NEW, 'dateControlNew', 'databookNew', 'statusNew', 'beforeNew');
        const lastRunCalculDelta$ = this.getRun(assetGroup.group, asset.asset, ASSET_RUN_TYPE.CALC_DELTA,'dateControlNew', 'databookCalculDelta', 'statusCalculDelta', 'beforeCalculDelta')
          .pipe(filter((runs: IRun[]) => !runs || runs.length === 0), concatMap(() => this.getRun(assetGroup.group, asset.asset, ASSET_RUN_TYPE.CALC_DELTA, 'dateControlNew', 'databookNew', 'statusNew', 'beforeNew')));
        const lastRunOld$ = this.getRun(assetGroup.group, asset.asset, ASSET_RUN_TYPE.OLD,'dateControlOld', 'databookOld', 'statusOld', 'beforeOld');
        lastRunsArr$.push(lastRunNew$);
        lastRunsArr$.push(lastRunCalculDelta$);
        lastRunsArr$.push(lastRunOld$);
      });
    });
    merge(...lastRunsArr$)
      .pipe(takeUntil(this._unsubscribeS$))
      .subscribe(
        {
          next: () => { this._load$.next(null); },
          error: (error: any) => { this._load$.next(null); },
          complete: () => { this._load$.next(null); }
        }
      );
  }

  public updateRun(group: string, assetSelected: IAssetRun, runType: ASSET_RUN_TYPE, runId: number): void {
    this._runloaderService.getRun$(runId)
      .pipe(
        takeUntil(this._unsubscribeS$),
        tap((runs: IRun[]) => {

          const assetGroup = this.assetGroupsSelected.find((groupAssetRun: IGroupAssetRun) => groupAssetRun.group === group)!;
          const assetRun = assetGroup.assets.find((assetRun: IAssetRun) => assetRun.asset === assetSelected.asset)!;
          switch (runType) {
            case ASSET_RUN_TYPE.NEW:
              assetRun.new = {runId: undefined, manuallyEdited: true};
              break;
            case ASSET_RUN_TYPE.CALC_DELTA:
              assetRun.calcDelta = {runId: undefined, manuallyEdited: true};
              break;
            case ASSET_RUN_TYPE.OLD:
              assetRun.old = {runId: undefined, manuallyEdited: true};
              break;
            default: break;
          }

          if (runs && runs.length > 0) {
            const lastVersionRun = runs[0];
            if (lastVersionRun.asset === assetSelected.asset) {
              const status = 'last' + (lastVersionRun.valid ? lastVersionRun.official ? ' valid official' : ' valid' : '');
              const filledAssetRun = {runId: lastVersionRun.runId, databook: lastVersionRun.databook, status: status, timestamp: lastVersionRun.timestamp, manuallyEdited: true};
              switch (runType) {
                case ASSET_RUN_TYPE.NEW:
                  assetRun.new = filledAssetRun;
                  break;
                case ASSET_RUN_TYPE.CALC_DELTA:
                  assetRun.calcDelta = filledAssetRun;
                  break;
                case ASSET_RUN_TYPE.OLD:
                  assetRun.old = filledAssetRun;
                  break;
                default: break;
              }
            } else {
              this._notifService.warn(`RunId ${runId} belongs to ${lastVersionRun.asset}, not to ${assetSelected.asset}`);
            }
          } else {
            this._notifService.warn(`There is no information for RunId ${runId}`);
          }
          this._cdRef.markForCheck();
        })
      )
      .subscribe();
  }

  public createReports(): void {
    this.reportStatuses = [];
    let reportReqArr$: Observable<IMonthlySwitchReportResponse>[] = [];

    this.assetGroupsSelected.forEach((group: IGroupAssetRun) => {

      const reportBody: IMonthlySwitchReport = {
        configuration: {
          cobs: {
            new: moment(this.fgMonthlySwitch.get('dateControlNew')!.value).format('YYYY-MM-DD'),
            old: moment(this.fgMonthlySwitch.get('dateControlOld')!.value).format('YYYY-MM-DD')
          },
          databook: {
            new: {
              databook: this.fgMonthlySwitch.get('databookNew')?.value!,
              status: this.fgMonthlySwitch.get('statusNew')?.value!,
              before: this.fgMonthlySwitch.get('beforeNew')?.value ? this.fgMonthlySwitch.get('beforeNew')?.value! : null
            },
            'calcul-delta': {
              databook: this.fgMonthlySwitch.get('databookCalculDelta')?.value!,
              status: this.fgMonthlySwitch.get('statusCalculDelta')?.value!,
              before: this.fgMonthlySwitch.get('beforeCalculDelta')?.value ? this.fgMonthlySwitch.get('beforeCalculDelta')?.value! : null
            },
            old: {
              databook: this.fgMonthlySwitch.get('databookOld')?.value!,
              status: this.fgMonthlySwitch.get('statusOld')?.value!,
              before: this.fgMonthlySwitch.get('beforeOld')?.value ? this.fgMonthlySwitch.get('beforeOld')?.value! : null
            }
          },
          group: {
            [`${group.group}`]: group.assets.map((assetRun: IAssetRun) => assetRun.asset)
          },
          folder: this.suffixControl?.value!
        },
        output: {
          runs: {
            assets: {}
          }
        }
      };

      group.assets.forEach((assetRun: IAssetRun) => {

        reportBody.output.runs.assets[`${assetRun.asset}`] = {
          new: {
            runId: assetRun.new?.runId,
            databook: assetRun.new?.databook,
            status: assetRun.new?.status,
            timestamp: assetRun.new?.timestamp
          },
          'calcul-delta': {
            runId: assetRun.calcDelta?.runId!,
            databook: assetRun.calcDelta?.databook,
            status: assetRun.calcDelta?.status,
            timestamp: assetRun.calcDelta?.timestamp
          },
          old: {
            runId: assetRun.old?.runId!,
            databook: assetRun.old?.databook,
            status: assetRun.old?.status,
            timestamp: assetRun.old?.timestamp
          }
        };

      });

      const reportReq$: Observable<IMonthlySwitchReportResponse> = this._reportingService.createReport$(reportBody).pipe(catchError((err: any) => of()));
      reportReqArr$.push(reportReq$);

    });

    const readinessCheckSize = reportReqArr$.length;
    let readinessCheckIndex = 0;

    merge(...reportReqArr$)
      .pipe(
        takeUntil(this._unsubscribeS$),
        tap((reportResp: IMonthlySwitchReportResponse) => this._notifService.success(reportResp.message)),
        mergeMap((reportResp: IMonthlySwitchReportResponse) => {
          this.reportStatuses.push({group: reportResp.group, task_id: reportResp.task_id, message: reportResp.message});
          const stopper$ = new Subject<any>();
          const interval$ = interval(10000).pipe(
            takeUntil(this._unsubscribeS$),
            takeUntil(stopper$),
            concatMap(() => this._reportingService.getReportStatus$(reportResp.task_id)
              .pipe(takeUntil(this._unsubscribeS$),
                catchError((error: HttpErrorResponse) => {
                  if (error.error.task_id) {
                    const failingStatusPipe = this._reportStatusArr$.find((statusPipe: IStatusPipe) => statusPipe.taskId === error.error.task_id)!;
                    failingStatusPipe.stopper$.next(null);
                    if (error.error.status) {
                      const statusUpdatable = this.reportStatuses.find((repStatus: IReportStatus) => repStatus.task_id === error.error.task_id)!;
                      statusUpdatable.status = error.error.status;
                      statusUpdatable.message = error.error.message;
                      statusUpdatable.path = error.error.path;
                    }
                  }
                  this._cdRef.markForCheck();
                  return of();
                })))
          );
          this._reportStatusArr$.push({
            group: reportResp.group,
            taskId: reportResp.task_id,
            interval$: interval$,
            stopper$: stopper$
          });
          return interval$;
        }),
        tap((reportStatus: IMonthlySwitchStatusResponse) => {
          const statusPipe = this._reportStatusArr$.find((statusPipe: IStatusPipe) => statusPipe.taskId === reportStatus.task_id)!;
          const statusUpdatable = this.reportStatuses.find((repStatus: IReportStatus) => repStatus.task_id === reportStatus.task_id)!;
          statusUpdatable.status = reportStatus.status;
          statusUpdatable.message = reportStatus.message;
          statusUpdatable.path = reportStatus.path;
          switch (reportStatus.status) {
            case REPORT_STATUS.OK:
              statusPipe.stopper$.next(null);
              readinessCheckIndex++;
              break;
            case REPORT_STATUS.NOK:
              statusPipe.stopper$.next(null);
              readinessCheckIndex++;
              break;
            case REPORT_STATUS.EXPIRED_OR_NOT_FOUND:
              statusPipe.stopper$.next(null);
              readinessCheckIndex++;
              break;
            case REPORT_STATUS.NOT_READY:
              break;
            default:
              statusPipe.stopper$.next(null);
              readinessCheckIndex++;
              break;
          }
          this._cdRef.markForCheck();
        }),
        filter(() => readinessCheckIndex === readinessCheckSize),
        switchMap(() => {
          const groups: ISummaryGroupKey[] = [];
          this.assetGroupsSelected.forEach((assetGroup: IGroupAssetRun) => {
            const groupSummary: ISummaryGroupKey = {[`${assetGroup.group}`] : {}};
            assetGroup.assets.forEach((asset: IAssetRun) => {
              groupSummary[`${assetGroup.group}`][`${asset.asset}`] = {
                runIds: {
                  new: asset.new?.runId,
                  'calcul-delta': asset.calcDelta?.runId,
                  old: asset.old?.runId
                }
              };
            });
            groups.push(groupSummary);
          });
          return this._reportingService.generateSummary$({
            MonthlySwitchReport: {
              suffix: this.suffixControl?.value!,
              timestamp: moment().format('YYYY-MM-DD HH:mm:ss'),
              date_new: moment(this.fgMonthlySwitch.get('dateControlNew')!.value).format('YYYY-MM-DD'),
              date_old: moment(this.fgMonthlySwitch.get('dateControlOld')!.value).format('YYYY-MM-DD'),
              Groups: groups
            }
          }).pipe(tap((summaryResp: any) => summaryResp.message ? this._notifService.success(summaryResp.message) : this._notifService.success('OK')))
        })
      )
      .subscribe();
  }

  assetOpened(assetWindowOpened: boolean) {
    if (assetWindowOpened) {
      const overlayContainerHTMLElement: HTMLElement = this._overlayContainer.getContainerElement();
      overlayContainerHTMLElement.onclick = ((mouseEvent: MouseEvent) => {
        const loadBtnNativeElement: HTMLElement = this.loadBtn._elementRef.nativeElement;
        const totalOffset = this.getTotalOffset(loadBtnNativeElement, {offsetTop: loadBtnNativeElement.offsetTop, offsetLeft: loadBtnNativeElement.offsetLeft});
        // if clicked on the overlay and on top of the load button
        if ((mouseEvent.target as HTMLElement).outerHTML.includes('cdk-overlay')
          && (mouseEvent.offsetX > totalOffset.offsetLeft)
          && (mouseEvent.offsetX < (totalOffset.offsetLeft + loadBtnNativeElement.offsetWidth))
          && (mouseEvent.offsetY > totalOffset.offsetTop)
          && (mouseEvent.offsetY < (totalOffset.offsetTop + loadBtnNativeElement.offsetHeight))) {
          this.load();
        }
      });
    }

  }

  //TODO - this helper function can be moved in a special pure utility HTML helper lib
  getTotalOffset(element: HTMLElement, currOffset: {offsetTop: number, offsetLeft: number}): {offsetTop: number, offsetLeft: number} {
    if (element.offsetParent && (element.offsetParent as HTMLElement)) {
      currOffset.offsetTop += (element.offsetParent as HTMLElement).offsetTop;
      currOffset.offsetLeft += (element.offsetParent as HTMLElement).offsetLeft;
      return this.getTotalOffset(element.offsetParent as HTMLElement, currOffset);
    } else {
      return currOffset;
    }
  }
}
