import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {MAT_SNACK_BAR_DEFAULT_OPTIONS} from "@angular/material/snack-bar";
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from "@angular/material-moment-adapter";
import {DefaultMatCalendarRangeStrategy, MAT_DATE_RANGE_SELECTION_STRATEGY} from "@angular/material/datepicker";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";


import {LibApiModule} from "@alpq/lib-api";
import {LibTsvpeModule} from "@alpq/lib-tsvpe";
import {LibAuthenticationModule} from "@alpq/lib-authentication";
import {LibMaterialModule} from "@alpq/lib-material";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TsvpeComponent } from './tsvpe/tsvpe.component';



import {environment} from "../environments/environment";



export const MY_DEFAULT_DATE_FORMAT = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MM.YYYY',
    dateA11yLabel: 'DD.MM.YYYY',
    monthYearA11yLabel: 'MM.YYYY',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    TsvpeComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    LibAuthenticationModule.forRoot(environment),
    LibTsvpeModule,
    AppRoutingModule,
    MatIconModule,
    MatButtonModule,
    LibMaterialModule,
    LibApiModule.forRoot(environment)
  ],
  providers: [
    { provide: 'environment', useValue: environment },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { horizontalPosition: 'center', duration: 6000 } },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_DEFAULT_DATE_FORMAT},
    //used for range selection that will use MomentDateAdapter also
    { provide: MAT_DATE_RANGE_SELECTION_STRATEGY, useClass: DefaultMatCalendarRangeStrategy}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
