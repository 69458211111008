import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';

@Component({
  selector: 'lib-progress-spinner',
  templateUrl: './lib-progress-spinner.component.html',
  styleUrls: ['./lib-progress-spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LibProgressSpinnerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
