import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {take, tap} from "rxjs/operators";

import {LibStorageClientService} from "@alpq/lib-storage-client";
import {LibApiService} from "@alpq/lib-api";


@Injectable({
  providedIn: 'root'
})
export class LibLanguageService {
  messages: any = {};
  private _languageBS$: BehaviorSubject<string> = new BehaviorSubject<string>(this.getLanguage());

  language$(): Observable<string> {
    return this._languageBS$.asObservable();
  }

  constructor(
    private _storageClientService: LibStorageClientService,
    private _apiService: LibApiService,
    @Inject('env') private readonly _env: any) {
    this._initLanguage();
  }

  private _initLanguage() {
    this.getTranslation(this.getLanguage())
      .pipe(
        take(1),
        tap(() => this.broadcastNewLanguage())
      )
      .subscribe();
  }

  getLanguages(): string[] {
    return this._env.languages;
  }

  getLanguage(): string {
    return this._storageClientService.language;
  }

  broadcastNewLanguage(): void {
    this._languageBS$.next(this._storageClientService.language);
  }

  getTranslation(language: string): Observable<any> {
    return this._apiService.get$(`./assets/locale/${language}.json`)
      .pipe(
        tap((messagesJson) => {
          //set new storage language
          this._storageClientService.language = language;
          //set messages
          this.messages = messagesJson;
        })
      );
  }
}
