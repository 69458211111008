import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {HttpErrorResponse} from "@angular/common/http";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'lib-dialog-logs',
  templateUrl: './dialog-logs.component.html',
  styleUrls: ['./dialog-logs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogLogsComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DialogLogsComponent>,
              @Inject(MAT_DIALOG_DATA) public data: HttpErrorResponse[]) { }

  ngOnInit(): void {
  }

}
