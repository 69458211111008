<section class="tsvpe-eod">
  <section class="tsvpe-eod-form" #filterSection>
    <mat-form-field>
      <mat-select [formControl]="reportTypeControl" name="reportType">
        <mat-option *ngFor="let reportType of REPORTS" [value]="reportType">{{reportType}}</mat-option>
      </mat-select>
    </mat-form-field>
    &nbsp;&nbsp;&nbsp;&nbsp;
    <button mat-button type="button" class="alpq-btn-primary"
            (click)="export()">Export</button> <!--[disabled]="loading$ | async" -->
  </section>
  &nbsp;
  <div class="tsvpe-eod-table" *ngIf="(loading$ | async) || (dataSource && dataSource.data && dataSource.data.length > 0)">
    <lib-progress-spinner *ngIf="loading$ | async"></lib-progress-spinner>
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" *ngIf="!(loading$ | async)">
      <ng-container matColumnDef="{{column.id}}" *ngFor="let column of columns" [sticky]="column.id === 'time'">
        <th mat-header-cell *matHeaderCellDef> {{column.header}} </th>
        <td mat-cell *matCellDef="let element; let i = index"
            [ngClass]="[isNegativeValue(element[column.id]) ? 'negative-val' : '',
            isGroupEnd(i, i+1) ? 'group-end' : '']">
            <!--(column.upperHeader === 'CH' || column.upperHeader === 'FR') ? 'zone-bg' : '']">-->
          <span>{{column.id === 'time' ? element[column.id] : (element[column.id] | number | numberCh)}}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="{{columnUpperHeader.upperHeader}}"
                    *ngFor="let columnUpperHeader of columnUpperHeaders"
                    [sticky]="columnUpperHeader.upperHeader === 'Stamp'">
        <th mat-header-cell *matHeaderCellDef [attr.colspan]="columnUpperHeader.colspan" [ngClass]="['upper-header-cell']">
          <div class="upper-header-div">
            <span>{{columnUpperHeader.upperHeader}}</span>
            <span *ngIf="columnUpperHeader.cob1" class="upper-header-div-runs">
              <span>{{columnUpperHeader.cob1!.toString().substring(4,8)}} - {{columnUpperHeader.runId1}}</span>
              <span *ngIf="!reportTypeControl.value!.includes('abs')"> &nbsp;&nbsp; {{columnUpperHeader.cob2!.toString().substring(4,8)}} - {{columnUpperHeader.runId2}}</span>
            </span>
          </div>
        </th>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumnUpperHeaders; sticky: true"></tr>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</section>
