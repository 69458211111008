<div class="auth-bar">
  <span class="auth-bar-user">Logged: {{userName}}</span>
  &nbsp;&nbsp;&nbsp;
  <span>V: {{appVersion}}</span>
  &nbsp;&nbsp;&nbsp;
  <button mat-icon-button disableRipple class="auth-bar-logo" (click)="goHome()">
    <mat-icon svgIcon="alpiq-logo" [inline]="true" class="auth-bar-logo-icon"></mat-icon>
  </button>
</div>
<router-outlet></router-outlet>
