<div class="tsvpe-monthly-switch">

  <form [formGroup]="fgMonthlySwitch" ngNativeValidate>

    <section class="tsvpe-monthly-switch-section-meta">

        <div class="tsvpe-monthly-switch-first-meta-div">
            <mat-form-field class="tsvpe-monthly-switch-date">
              <mat-label>
                Date new
              </mat-label>
              <input matInput [matDatepicker]="datePickerNew" [max]="today"
                     formControlName="dateControlNew" name="date">
              <mat-datepicker-toggle matPrefix [for]="datePickerNew"></mat-datepicker-toggle>
              <mat-datepicker-toggle matSuffix [for]="datePickerNew">
                <mat-icon matDatepickerToggleIcon svgIcon="expand_more"></mat-icon>
              </mat-datepicker-toggle>
              <mat-datepicker #datePickerNew></mat-datepicker>
            </mat-form-field>

            <mat-form-field class="tsvpe-monthly-switch-date">
              <mat-label>
                Date old
              </mat-label>
              <input matInput [matDatepicker]="datePickerOld" [max]="today"
                     formControlName="dateControlOld" name="date">
              <mat-datepicker-toggle matPrefix [for]="datePickerOld"></mat-datepicker-toggle>
              <mat-datepicker-toggle matSuffix [for]="datePickerOld">
                <mat-icon matDatepickerToggleIcon svgIcon="expand_more"></mat-icon>
              </mat-datepicker-toggle>
              <mat-datepicker #datePickerOld></mat-datepicker>
            </mat-form-field>

        </div>

        <div class="tsvpe-monthly-switch-second-meta-div">
          <mat-grid-list cols="7" rowHeight="2rem">
            <mat-grid-tile class="first-column-meta"></mat-grid-tile>
            <mat-grid-tile class="first-row-meta"  colspan="2"><span>Databook</span></mat-grid-tile>
            <mat-grid-tile class="first-row-meta"  colspan="2"><span>Status</span></mat-grid-tile>
            <mat-grid-tile class="first-row-meta"  colspan="2"><span>Before</span></mat-grid-tile>

            <mat-grid-tile class="first-column-meta"><span>New</span></mat-grid-tile>
            <mat-grid-tile colspan="2">
              <mat-form-field>
                <mat-select formControlName="databookNew">
                  <mat-option *ngFor="let databook of databookOptions" [value]="databook">{{databook}}</mat-option>
                </mat-select>
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile colspan="2">
              <mat-form-field>
                <mat-select formControlName="statusNew">
                  <mat-option *ngFor="let status of statusOptions" [value]="status">{{status}}</mat-option>
                </mat-select>
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile colspan="2">
              <mat-form-field class="tsvpe-monthly-switch-input-before">
                <input matInput formControlName="beforeNew" maxlength="19" placeholder="YYYY-MM-DDTHH:mm:ss">
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile class="first-column-meta"><span>Calcul-Delta</span></mat-grid-tile>
            <mat-grid-tile colspan="2">
              <mat-form-field>
                <mat-select formControlName="databookCalculDelta">
                  <mat-option *ngFor="let databook of databookOptions" [value]="databook">{{databook}}</mat-option>
                </mat-select>
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile colspan="2">
              <mat-form-field>
                <mat-select formControlName="statusCalculDelta">
                  <mat-option *ngFor="let status of statusOptions" [value]="status">{{status}}</mat-option>
                </mat-select>
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile colspan="2">
              <mat-form-field class="tsvpe-monthly-switch-input-before">
                <input matInput formControlName="beforeCalculDelta" maxlength="19" placeholder="YYYY-MM-DDTHH:mm:ss">
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile class="first-column-meta"><span>Old</span></mat-grid-tile>
            <mat-grid-tile colspan="2">
              <mat-form-field>
                <mat-select formControlName="databookOld">
                  <mat-option *ngFor="let databook of databookOptions" [value]="databook">{{databook}}</mat-option>
                </mat-select>
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile colspan="2">
              <mat-form-field>
                <mat-select formControlName="statusOld">
                  <mat-option *ngFor="let status of statusOptions" [value]="status">{{status}}</mat-option>
                </mat-select>
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile colspan="2">
              <mat-form-field class="tsvpe-monthly-switch-input-before">
                <input matInput formControlName="beforeOld" maxlength="19" placeholder="YYYY-MM-DDTHH:mm:ss">
              </mat-form-field>
            </mat-grid-tile>
          </mat-grid-list>

        </div>

        <div class="tsvpe-monthly-switch-third-meta-div">
          <mat-form-field class="tsvpe-monthly-switch-input">
            <mat-label>Suffix</mat-label>
            <input matInput placeholder="Suffix" [formControl]="suffixControl"> <!--formControlName="suffix"-->
          </mat-form-field>

          <button mat-button type="button" [ngClass]="loadBtnClass" #loadBtn
                  (click)="load()" [disabled]="!assetGroupsControl.value || assetGroupsControl.value.length === 0">Load RunIDs</button>

          <button mat-button type="button" class="alpq-btn-secondary"
                  (click)="createReports()"
                  [disabled]="!assetGroupsControl.value || assetGroupsControl.value.length === 0 || !suffixControl.value || !loaded || disableReports">
            Create Reports
          </button> <!--!fgMonthlySwitch.get('suffix')!.value-->
        </div>

    </section>

    <section class="tsvpe-monthly-switch-section-assets">
      <mat-form-field class="tsvpe-monthly-switch-input-asset-groups">
        <mat-label>Asset Groups</mat-label>
        <mat-select [formControl]="assetGroupsControl" multiple disableOptionCentering panelClass="underneath" (openedChange)="assetOpened($event)">
          <mat-option *ngFor="let assetGroup of assetGroups" [value]="assetGroup">{{assetGroup.group}}</mat-option>
        </mat-select>
      </mat-form-field>
      <div>
        <h3 class="run-title" *ngIf="assetGroupsControl.value && assetGroupsControl.value.length > 0">Runs</h3>
        <div class="tsvpe-monthly-switch-section-assets-dynamic" *ngIf="assetGroupsControl.value">
          <!--<div *ngFor="let assetGroupSelected of assetGroupsControl.value" class="tsvpe-monthly-switch-section-assets-dynamic-group">-->
          <div *ngFor="let assetGroupSelected of assetGroupsSelected" class="tsvpe-monthly-switch-section-assets-dynamic-group">
            <div *ngFor="let assetSelected of assetGroupSelected.assets" class="tsvpe-monthly-switch-section-assets-dynamic-group-asset">
              <mat-grid-list cols="10" rowHeight="2rem">
                <mat-grid-tile class="first-column-meta tile-asset" colspan="2"><span>{{assetSelected.asset}}</span></mat-grid-tile>
                <mat-grid-tile class="first-row-meta"  colspan="2"><span>RunIDs</span></mat-grid-tile>
                <mat-grid-tile class="first-row-meta"  colspan="2"><span>Databook</span></mat-grid-tile>
                <mat-grid-tile class="first-row-meta"  colspan="2"><span>Status</span></mat-grid-tile>
                <mat-grid-tile class="first-row-meta"  colspan="2"><span>Run date/time</span></mat-grid-tile>

                <mat-grid-tile class="first-column-meta" colspan="2"><span>New</span></mat-grid-tile>
                <mat-grid-tile colspan="2" class="tile-runId" [ngClass]="[assetSelected.new?.manuallyEdited ? 'run-edited' : '', assetSelected.new?.missing ? 'run-missing' : '']"><!--{{assetSelected.new?.runId}}-->
                  <mat-form-field *ngIf="assetSelected.new">
                    <input matInput placeholder="RunId" [(ngModel)]="assetSelected.new!.runId" [ngModelOptions]="{standalone: true}" type="number"
                           (keydown.enter)='updateRun(assetGroupSelected.group, assetSelected, 0, assetSelected.new!.runId!)'> <!--(change)="updateRun(assetSelected.new!.runId!)" -->
                  </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile colspan="2" [ngClass]="[assetSelected.new?.manuallyEdited ? 'run-edited' : '', assetSelected.new?.missing ? 'run-missing' : '']">{{assetSelected.new?.databook}}</mat-grid-tile>
                <mat-grid-tile colspan="2" [ngClass]="[assetSelected.new?.manuallyEdited ? 'run-edited' : '', assetSelected.new?.missing ? 'run-missing' : '']">{{assetSelected.new?.status}}</mat-grid-tile>
                <mat-grid-tile colspan="2" [ngClass]="[assetSelected.new?.manuallyEdited ? 'run-edited' : '', assetSelected.new?.missing ? 'run-missing' : '']">{{assetSelected.new?.timestamp}}</mat-grid-tile>

                <mat-grid-tile class="first-column-meta" colspan="2"><span>Calcul-Delta</span></mat-grid-tile>
                <mat-grid-tile colspan="2" class="tile-runId" [ngClass]="[assetSelected.calcDelta?.manuallyEdited ? 'run-edited' : '', assetSelected.calcDelta?.missing ? 'run-missing' : '']"><!--{{assetSelected.calcDelta?.runId}}-->
                  <mat-form-field *ngIf="assetSelected.calcDelta">
                    <input matInput placeholder="RunId" [(ngModel)]="assetSelected.calcDelta!.runId" [ngModelOptions]="{standalone: true}" type="number"
                           (keydown.enter)='updateRun(assetGroupSelected.group, assetSelected, 1, assetSelected.calcDelta!.runId!)'>
                  </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile colspan="2" [ngClass]="[assetSelected.calcDelta?.manuallyEdited ? 'run-edited' : '', assetSelected.calcDelta?.missing ? 'run-missing' : '']">{{assetSelected.calcDelta?.databook}}</mat-grid-tile>
                <mat-grid-tile colspan="2" [ngClass]="[assetSelected.calcDelta?.manuallyEdited ? 'run-edited' : '', assetSelected.calcDelta?.missing ? 'run-missing' : '']">{{assetSelected.calcDelta?.status}}</mat-grid-tile>
                <mat-grid-tile colspan="2" [ngClass]="[assetSelected.calcDelta?.manuallyEdited ? 'run-edited' : '', assetSelected.calcDelta?.missing ? 'run-missing' : '']">{{assetSelected.calcDelta?.timestamp}}</mat-grid-tile>

                <mat-grid-tile class="first-column-meta" colspan="2"><span>Old</span></mat-grid-tile>
                <mat-grid-tile colspan="2" class="tile-runId" [ngClass]="[assetSelected.old?.manuallyEdited ? 'run-edited' : '', assetSelected.old?.missing ? 'run-missing' : '']"><!--{{assetSelected.old?.runId}}-->
                  <mat-form-field *ngIf="assetSelected.old">
                    <input matInput placeholder="RunId" [(ngModel)]="assetSelected.old!.runId" [ngModelOptions]="{standalone: true}" type="number"
                           (keydown.enter)='updateRun(assetGroupSelected.group, assetSelected, 2, assetSelected.old!.runId!)'>
                  </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile colspan="2" [ngClass]="[assetSelected.old?.manuallyEdited ? 'run-edited' : '', assetSelected.old?.missing ? 'run-missing' : '']">{{assetSelected.old?.databook}}</mat-grid-tile>
                <mat-grid-tile colspan="2" [ngClass]="[assetSelected.old?.manuallyEdited ? 'run-edited' : '', assetSelected.old?.missing ? 'run-missing' : '']">{{assetSelected.old?.status}}</mat-grid-tile>
                <mat-grid-tile colspan="2" [ngClass]="[assetSelected.old?.manuallyEdited ? 'run-edited' : '', assetSelected.old?.missing ? 'run-missing' : '']">{{assetSelected.old?.timestamp}}</mat-grid-tile>
              </mat-grid-list>
            </div>
          </div>
        </div>

      </div>
    </section>

    <section *ngIf="reportStatuses && reportStatuses.length > 0" class="padded">
      <h3 class="run-title">Reports</h3>
      <mat-grid-list cols="8" rowHeight="4rem" class="bordered">
        <mat-grid-tile class="first-row-meta first-column-meta tile-header" colspan="1"><span>Group</span></mat-grid-tile>
        <mat-grid-tile class="first-row-meta tile-header" colspan="2"><span>Task</span></mat-grid-tile>
        <mat-grid-tile class="first-row-meta tile-header" colspan="1"><span>Status</span></mat-grid-tile>
        <mat-grid-tile class="first-row-meta tile-header" colspan="2"><span>Message</span></mat-grid-tile>
        <mat-grid-tile class="first-row-meta tile-header" colspan="2"><span>Path</span></mat-grid-tile>

        <ng-container *ngFor="let reportStatus of reportStatuses">
          <mat-grid-tile class="first-column-meta" colspan="1"><span>{{reportStatus.group}}</span></mat-grid-tile>
          <mat-grid-tile class="" colspan="2"><span>{{reportStatus.task_id}}</span></mat-grid-tile>
          <mat-grid-tile class="scrollable" colspan="1"><span>{{reportStatus.status}}</span></mat-grid-tile>
          <mat-grid-tile class="scrollable" colspan="2"><span>{{reportStatus.message}}</span></mat-grid-tile>
          <mat-grid-tile class="scrollable" colspan="2"><span>{{reportStatus.path}}</span></mat-grid-tile>
        </ng-container>
      </mat-grid-list>
    </section>
  </form>
</div>
