<h1 mat-dialog-title>
  Checks for {{data.rowSelected.asset}}
</h1>

<div mat-dialog-content>
  <table mat-table [dataSource]="data.checks" class="mat-elevation-z8">

    <!-- Position Column -->
    <ng-container matColumnDef="no">
      <th mat-header-cell *matHeaderCellDef> No. </th>
      <td mat-cell *matCellDef="let element"> {{element.metadata.check.number}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef> Status </th>
      <td mat-cell *matCellDef="let element" [ngClass]="[element.check === 'OK' ? 'ok' : 'not-ok']"> {{element.metadata.check.number !== 1 ? element.check : ''}} </td>
    </ng-container>

    <!-- URL Column -->
    <ng-container matColumnDef="url">
      <th mat-header-cell *matHeaderCellDef> Url </th>
      <td mat-cell *matCellDef="let element"> <a href="{{element.url}}" target=”_blank”>{{element.metadata.check.name}}</a> </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
