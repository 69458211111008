import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import {AuthenticationGuardService as AuthGuard} from "@alpq/lib-authentication";

import {TsvpeComponent} from "./tsvpe/tsvpe.component";

const routes: Routes = [
  { path: 'home', pathMatch: 'full', component: TsvpeComponent, canActivate:[AuthGuard]},
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: '**', redirectTo: 'home'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
