<div class="simple-notification">
  <span class="simple-notification__text">{{data.message.text}}</span>
  <div *ngIf="data.action">
    <button mat-button (click)="action()">
      {{data.action.name | uppercase}}
    </button>
  </div>
  <div *ngIf="!!data.action && data.action.type === 0">
    <mat-icon (click)="dismiss()">close</mat-icon>
  </div>
</div>
