<div class="tsvpe-run">
  <div class="tsvpe-run-form">
    <mat-form-field class="run-input">
      <input matInput [formControl]="runIdControl" type="number"
             name="runId" placeholder="" maxlength="8"
             (keyup.enter)="selectRunId()">
    </mat-form-field>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <button mat-button type="button" class="alpq-btn-primary"
            (click)="selectRunId()" [disabled]="!runIdControl.value">Select</button>
  </div>
  <div class="tsvpe-run-paginator" *ngIf="runs && runs.length > 1">
    <!--<button mat-icon-button aria-label="Left"
            (click)="goLeft()" [disabled]="runIndex === runs.length - 1">
      <mat-icon>chevron_left</mat-icon>
    </button>
    <button mat-icon-button aria-label="Right"
            (click)="goRight()" [disabled]="runIndex === 0">
      <mat-icon>chevron_right</mat-icon>
    </button>-->
    <button mat-icon-button aria-label="Left" (click)="goLeft()" [disabled]="runIndex === runs.length - 1">
      <mat-icon svgIcon="chevron_left"></mat-icon>
    </button>
    <button mat-icon-button aria-label="Right" (click)="goRight()" [disabled]="runIndex === 0">
      <mat-icon svgIcon="chevron_right"></mat-icon>
    </button>
  </div>
  <div class="tsvpe-run-table" *ngIf="runs && runCurr">
    <mat-grid-list cols="5" [rowHeight]="20">

      <mat-grid-tile colspan="2">Run Id</mat-grid-tile>
      <mat-grid-tile class="tile-disabled" colspan="3">
        {{runCurr.runId}}
      </mat-grid-tile>

      <mat-grid-tile colspan="2">Version</mat-grid-tile>
      <mat-grid-tile class="tile-disabled" colspan="3">
        {{runCurr.version}}
      </mat-grid-tile>

      <mat-grid-tile colspan="2">Cob</mat-grid-tile>
      <mat-grid-tile class="tile-disabled" colspan="3">
        {{runCurr.cob}}
      </mat-grid-tile>

      <mat-grid-tile colspan="2">Databook</mat-grid-tile>
      <mat-grid-tile class="tile-disabled" colspan="3">
        {{runCurr.databook}}
      </mat-grid-tile>

      <mat-grid-tile colspan="2">Asset</mat-grid-tile>
      <mat-grid-tile class="tile-disabled" colspan="3">
        {{runCurr.asset}}
      </mat-grid-tile>

      <mat-grid-tile colspan="2">Fuel</mat-grid-tile>
      <mat-grid-tile class="tile-disabled" colspan="3">
        {{runCurr.fuel}}
      </mat-grid-tile>

      <mat-grid-tile colspan="2">Timestamp</mat-grid-tile>
      <mat-grid-tile class="tile-disabled" colspan="3">
        {{runCurr.timestamp}}
      </mat-grid-tile>

      <mat-grid-tile colspan="2">Modified</mat-grid-tile>
      <mat-grid-tile class="tile-disabled" colspan="3">
        {{runCurr.modified}}
      </mat-grid-tile>

      <mat-grid-tile colspan="2">Official</mat-grid-tile>
      <mat-grid-tile class="tile-disabled" colspan="3">
        {{runCurr.official}}
      </mat-grid-tile>

      <mat-grid-tile colspan="2">To Export</mat-grid-tile>
      <mat-grid-tile [ngClass]="[runIndex !== 0 ? 'tile-disabled' : 'tile-enabled']" colspan="3">
        <ng-container *ngIf="runIndex !== 0">
          {{runCurr.to_export}}
        </ng-container>
        <mat-select [formControl]="toExportControl" name="toExport" *ngIf="runIndex === 0">
          <mat-option *ngFor="let bool of BOOLS" [value]="bool">{{bool}}</mat-option>
        </mat-select>
      </mat-grid-tile>

      <mat-grid-tile colspan="2">Valid</mat-grid-tile>
      <mat-grid-tile [ngClass]="[runIndex !== 0 ? 'tile-disabled' : 'tile-enabled',
          runIndex !== 0 && runCurr.valid ? 'valid-bg' : '']" colspan="3">
        <ng-container *ngIf="runIndex !== 0">
          {{runCurr.valid}}
        </ng-container>
        <mat-select [formControl]="validControl" name="valid" *ngIf="runIndex === 0" [ngClass]="[validControl.value ? 'valid-bg' : '']">
          <mat-option *ngFor="let bool of BOOLS" [value]="bool">{{bool}}</mat-option>
        </mat-select>
      </mat-grid-tile>

      <mat-grid-tile colspan="2" rowspan="3">Reason</mat-grid-tile>
      <mat-grid-tile [ngClass]="[runIndex !== 0 ? 'tile-disabled scrollable' : 'tile-enabled']" colspan="3" rowspan="3">
        <ng-container *ngIf="runIndex !== 0">{{runCurr.reason}}</ng-container>
        <textarea matInput class="textarea" *ngIf="runIndex === 0" [formControl]="reasonControl" name="reason"></textarea>
      </mat-grid-tile>

      <mat-grid-tile colspan="2" rowspan="2">Validation by</mat-grid-tile>
      <mat-grid-tile class="tile-disabled" colspan="3" rowspan="2">
        {{runCurr.validationBy}}
      </mat-grid-tile>

      <mat-grid-tile colspan="2">Pzone</mat-grid-tile>
      <mat-grid-tile class="tile-disabled" colspan="3">
        {{runCurr.pzone}}
      </mat-grid-tile>

      <mat-grid-tile colspan="2">Scen</mat-grid-tile>
      <mat-grid-tile class="tile-disabled" colspan="3">
        {{runCurr.scen}}
      </mat-grid-tile>

      <mat-grid-tile colspan="2">Stoch</mat-grid-tile>
      <mat-grid-tile class="tile-disabled" colspan="3">
        {{runCurr.stoch}}
      </mat-grid-tile>

      <mat-grid-tile rowspan="4">Export</mat-grid-tile>
      <mat-grid-tile>Id</mat-grid-tile>
      <mat-grid-tile class="tile-disabled" colspan="3">
        {{runCurr.export?.exportId}}
      </mat-grid-tile>

      <mat-grid-tile>Sent</mat-grid-tile>
      <mat-grid-tile class="tile-disabled" colspan="3">
        {{runCurr.export?.sent}}
      </mat-grid-tile>

      <mat-grid-tile>System</mat-grid-tile>
      <mat-grid-tile [ngClass]="[runIndex !== 0 ? 'tile-disabled' : 'tile-enabled']" colspan="3">
        <ng-container *ngIf="runIndex !== 0">{{runCurr.export?.system}}</ng-container>
        <mat-select [formControl]="exportSystemControl" name="exportSystem" *ngIf="runIndex === 0"
                    [compareWith]="compareExportSystems" (closed)="selectExportSystem()">
          <mat-option *ngFor="let system of systems" [value]="system">{{system.name}}</mat-option>
        </mat-select>
      </mat-grid-tile>

      <mat-grid-tile>Who</mat-grid-tile>
      <mat-grid-tile class="tile-disabled" colspan="3">
        {{runCurr.export?.who}}
      </mat-grid-tile>

      <mat-grid-tile colspan="2" rowspan="3">Comment</mat-grid-tile>
      <mat-grid-tile [ngClass]="[runIndex !== 0 ? 'tile-disabled scrollable' : 'tile-enabled']" colspan="3" rowspan="3">
        <ng-container class="scrollable" *ngIf="runIndex !== 0">{{runCurr.comment}}</ng-container>
        <textarea matInput class="textarea" *ngIf="runIndex === 0" [formControl]="commentControl" name="comment"></textarea>
      </mat-grid-tile>

      <mat-grid-tile colspan="2" rowspan="5">Tags</mat-grid-tile>
      <mat-grid-tile [ngClass]="[runIndex !== 0 ? 'tile-disabled scrollable' : 'tile-enabled']" colspan="3" rowspan="5">
        <ng-container *ngIf="runIndex !== 0 && runCurr.tags">{{runCurr.tags | json}}</ng-container>
        <textarea matInput class="textarea" *ngIf="runIndex === 0" [formControl]="tagsControl" name="tags"></textarea>
      </mat-grid-tile>
    </mat-grid-list>

    <button mat-button type="button" class="alpq-btn-primary tsvpe-run-table-save" *ngIf="runs && runCurr"
            (click)="save()" [disabled]="(!changedExport && !changedToExport && !changedValid && !changedAnnotate) || runIndex !== 0">
      Save
    </button>
  </div>
</div>
