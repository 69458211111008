export const environment = {
  production: false,
  env: 'dev',
  appTitle: 'Run Manager',
  homePage: 'home', // necessary if using authentication lib, as a path to go for redirect after signin-in
  internal: true,
  authUrl: 'https://api.onepassport.preprod.alpiq.io/user/login/sso?nocookie&redirect=',
  apiBaseUrl: 'https://runmanager.dev.tsvp.alpiq.services/',
  languages: ['en', 'de']
};
