import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Subject, takeUntil, tap} from "rxjs";

import {IRun, RunloaderService} from "@alpq/lib-api";

import {IDeltaTableRow} from "./../../lib-tsvpe.service";


@Component({
  selector: 'lib-dialog-run-info',
  templateUrl: './dialog-run-info.component.html',
  styleUrls: ['./dialog-run-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogRunInfoComponent implements OnInit, OnDestroy {
  private readonly _unsubscribeS$: Subject<any> = new Subject<any>();

  run1Version: IRun;
  run2Version: IRun;

  constructor(public dialogRef: MatDialogRef<DialogRunInfoComponent>,
              @Inject(MAT_DIALOG_DATA) public data: {rowSelected: IDeltaTableRow},
              private _runloaderService: RunloaderService,
              private _cdRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    if (this.data.rowSelected.runid1) {
      this._runloaderService.getRun$(this.data.rowSelected.runid1)
        .pipe(
          takeUntil(this._unsubscribeS$),
          tap((runs: IRun[]) => {
            this.run1Version = runs.find((run: IRun) => run.version === this.data.rowSelected.version1)!;
            this._cdRef.markForCheck();
          })
        )
        .subscribe();
    }
    if (this.data.rowSelected.runid2) {
      this._runloaderService.getRun$(this.data.rowSelected.runid2)
        .pipe(
          takeUntil(this._unsubscribeS$),
          tap((runs: IRun[]) => {
            this.run2Version = runs.find((run: IRun) => run.version === this.data.rowSelected.version2)!;
            this._cdRef.markForCheck();
          })
        )
        .subscribe();
    }
  }

  onNoClick(): void {
    this.dialogRef.close(null);
  }

  onEditClick(runToEdit: number): void {
    this.dialogRef.close(runToEdit);
  }

  ngOnDestroy(): void {
    this._unsubscribeS$.next(null);
    this._unsubscribeS$.complete();
  }
}
