import {Inject, Injectable} from "@angular/core";
import {Router} from "@angular/router";
import {Observable} from "rxjs";
import {switchMap, tap} from "rxjs/operators";


import {LibApiService} from "@alpq/lib-api";
import {LibNotificationService} from "@alpq/lib-notification";
import {LibStorageClientService} from "@alpq/lib-storage-client";
import {LibLanguageService} from "@alpq/lib-language";


export const AUTH_URL = 'user/login';
export const ROLES_URL = 'user/roles';

@Injectable({
  providedIn: 'root'
})
export class SigninService {
  constructor(private _router: Router,
              private _apiService: LibApiService,
              private _notificationService: LibNotificationService,
              private _storageClientService: LibStorageClientService,
              private _languageService: LibLanguageService,
              @Inject('env') private readonly _env: any) {}

  signIn$(data: any): Observable<any> {
    return this._apiService.post$<any>(AUTH_URL, data)
      .pipe(
        tap((response: any) => {
          this._storageClientService.token = response.jwt;
          this._storageClientService.userId = data.username;
          const expDate = JSON.parse(window.atob(response.jwt.split('.')[1]))?.exp;
          this._storageClientService.exp = expDate;
        }),
        //tap(() => this._router.navigate([this._env.homePage])),
        //here we'll request for the user data
        switchMap(() => this._apiService.get$<string[]>(ROLES_URL)),
        //check if the external user has metis-external role attached -> if not, log him out
        tap((roles: string[]) => {
          if(roles.find((role: string) => role === this._env.signInRole)) {
            this._storageClientService.roles = roles;
            this._router.navigate([this._env.homePage]);
            //TODO - should get and set the user preferences (language, theme, etc)
          } else {
            this.signOut();
            // @ts-ignore
            this._notificationService.error(this._languageService.messages['signin.notice.no-rights.error']);
          }
        })
      );
  }

  signOut() {
    this._storageClientService.clearStorage();
    this._router.navigate(['signin']);
  }
}
