import { Injectable } from '@angular/core';

import {LibStorageClientService} from "@alpq/lib-storage-client";


@Injectable({
  providedIn: 'root'
})
export class LibAuthenticationService {
  // @ts-ignore
  private _isAuthenticated: boolean;

  get isAuthenticated(): boolean {
    return this._storageClientService.isAuthenticated;
  }

  constructor(private _storageClientService: LibStorageClientService) { }

}
