import { Injectable } from '@angular/core';
import {Observable, Subject} from "rxjs";


export interface UserData {
  id: string;
  name?: string;
  roles?: string[];
}

export interface User {
  auth: {
    token: string;
    expDate?: number;
  };
  data?: UserData;
}

export interface UserSettings {
  theme: string;
  language: string;
  geolocation?: {};
}

export interface UserStorage {
  user?: User;
  settings: UserSettings;
}

export interface ICatalogState {
  table?: string[];
}

export interface IOnboardState {
  table?: string[];
}

export interface State {
  breadcrumb?: any[];
  page?: {
    [controlKey: string]: string;
  }
}

export const STORAGE_KEY_USER = 'user';
export const STORAGE_KEY_STATE = 'state';


@Injectable({
  providedIn: 'root'
})
export class LibStorageClientService {

  // @ts-ignore
  private _userStorage: UserStorage;
  // @ts-ignore
  private _state: State;

  private _refreshStorage$: Subject<any> = new Subject<any>();



  get isAuthenticated(): boolean {
    //check user object w/ token and expiration date to be greater than now
    // @ts-ignore
    return (this.userStorage?.user && this.userStorage.user.auth.expDate * 1000 > new Date().getTime()) ? true : false;
  }

  get userStorage(): UserStorage {
    // @ts-ignore
    const userStorage = JSON.parse(localStorage.getItem(STORAGE_KEY_USER));
    return userStorage;
  }

  set userStorage(value: UserStorage) {
    localStorage.setItem(STORAGE_KEY_USER, JSON.stringify(value));
    this._refreshStorage();
  }

  get token(): string {
    const userStorage = this.userStorage;
    // @ts-ignore
    return userStorage.user?.auth?.token;
  }

  set token(value: string) {
    const userStorage = this.userStorage;
    userStorage.user ? userStorage.user.auth.token = value : userStorage.user = {auth: {token: value}};
    localStorage.setItem(STORAGE_KEY_USER, JSON.stringify(userStorage));
    this._refreshStorage();
  }

  get exp(): number {
    const userStorage = this.userStorage;
    // @ts-ignore
    return userStorage.user?.auth?.expDate;
  }

  set exp(value: number) {
    const userStorage = this.userStorage;
    //second branch should not happen anyway
    // @ts-ignore
    userStorage.user ? userStorage.user.auth.expDate = value : userStorage.user = {auth: {token: null, expDate: value}};
    localStorage.setItem(STORAGE_KEY_USER, JSON.stringify(userStorage));
    this._refreshStorage();
  }

  get user(): User {
    const userStorage = this.userStorage;
    // @ts-ignore
    return userStorage.user;
  }

  set user(value: User) {
    const userStorage = this.userStorage;
    userStorage.user = value;
    localStorage.setItem(STORAGE_KEY_USER, JSON.stringify(userStorage));
    this._refreshStorage();
  }

  get userId(): string {
    const userStorage = this.userStorage;
    // @ts-ignore
    return userStorage.user?.data?.id;
  }

  set userId(userId: string) {
    let userStorage = this.userStorage;
    // @ts-ignore
    userStorage.user?.data ? userStorage.user.data.id = userId : userStorage.user = {...userStorage.user, data: {id: userId}};
    localStorage.setItem(STORAGE_KEY_USER, JSON.stringify(userStorage));
    this._refreshStorage();
  }

  get roles(): string[] {
    const userStorage = this.userStorage;
    // @ts-ignore
    return userStorage.user?.data?.roles;
  }

  set roles(roles: string[]) {
    let userStorage = this.userStorage;
    // @ts-ignore
    userStorage.user?.data ? userStorage.user.data.roles = roles : userStorage.user = {...userStorage.user, data: {id: this.userId, roles: roles}};
    localStorage.setItem(STORAGE_KEY_USER, JSON.stringify(userStorage));
  }

  get language(): string {
    const userStorage = this.userStorage;
    return userStorage.settings.language;
  }

  set language(value: string) {
    const userStorage = this.userStorage;
    userStorage.settings.language = value;
    localStorage.setItem(STORAGE_KEY_USER, JSON.stringify(userStorage));
  }

  get theme(): string {
    const userStorage = this.userStorage;
    return userStorage.settings.theme;
  }

  set theme(value: string) {
    const userStorage = this.userStorage;
    userStorage.settings.theme = value;
    localStorage.setItem(STORAGE_KEY_USER, JSON.stringify(userStorage));
  }

  get settings(): UserSettings {
    const userStorage = this.userStorage;
    return userStorage.settings;
  }

  set settings(userSettings: UserSettings) {
    let userStorage = this.userStorage;
    userStorage ? userStorage.settings = userSettings : userStorage = {settings: userSettings};
    localStorage.setItem(STORAGE_KEY_USER, JSON.stringify(userStorage));
  }

  get state(): State {
    // @ts-ignore
    this._state = JSON.parse(localStorage.getItem(STORAGE_KEY_STATE)) as State;
    return this._state;
  }

  set state(value: State) {
    this._state = value;
    localStorage.setItem(STORAGE_KEY_STATE, JSON.stringify(this._state));
  }

  constructor() {
    this._init();
  }

  private _init() {
    //check if no storage and set the initial one with light theme and en language
    if (!this.userStorage) {
      this.userStorage = {settings: {theme: 'light', language: 'de'}};
    }
  }

  //to be used @signout
  clearStorage() {
    //clear all entire storage but keep the public user settings
    const userSettings = this.settings;
    localStorage.clear();
    sessionStorage.clear();
    this.settings = userSettings;
  }


  connectRefreshStorage(): Observable<any> {
    return this._refreshStorage$.asObservable();
  }

  private _refreshStorage(): void {
    this._refreshStorage$.next(null);
  }
}
