import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {FormControl} from "@angular/forms";
import {MatTableDataSource} from "@angular/material/table";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {MatMenuTrigger} from "@angular/material/menu";
import {
  merge,
  Observable,
  Subject,
  switchMap,
  takeUntil,
  tap,
  EMPTY,
  BehaviorSubject,
  mergeMap,
  filter,
  retryWhen,
  delay,
  take,
  debounceTime,
  distinctUntilChanged,
  concat,
  catchError, of, finalize
} from "rxjs";
import {WebSocketSubject} from "rxjs/webSocket";

import moment from 'moment';

import {
  HOURLY_RISK_STATUS_ENUM,
  IAssetDependencies,
  IAssetResponse,
  ICheckResponse,
  IDeltaResponse,
  IEodFullInput,
  IEodFullInput2,
  IEodInput2,
  IEodInput3,
  IExportExtrinsic,
  IExportPayload,
  IExportResponse, IHourlyRiskStatusResponse,
  IMurexExpMessage,
  IRun,
  MurexExpService,
  RunloaderService,
  ValidationService
} from "@alpq/lib-api";
import {LibNotificationService} from "@alpq/lib-notification";

import {DialogChangeRunComponent} from "./dialog-change-run/dialog-change-run.component";
import {DialogChecksComponent} from "./dialog-checks/dialog-checks.component";
import {DialogLogsComponent} from "./dialog-logs/dialog-logs.component";
import {DialogRunInfoComponent} from "./dialog-run-info/dialog-run-info.component";
import {DialogHourlyRiskComponent} from "./dialog-hourly-risk/dialog-hourly-risk.component";
import {IReportPageSnapshot, LibTsvpeService, IDeltaTableRow} from "./../lib-tsvpe.service";


export interface IReportParam {
  asset: string;
  run1: IRun | undefined;
  run2: IRun | undefined;
  run1OfficialNotLast?: boolean | undefined;
}

export interface IListType {
  id: number;
  name: string;
}

export enum TABLE_TYPE {
  INTRINSIC = 0,
  EXTRINSIC = 1
}

export const INTRINSIC_CHECKS_TOTAL = 6;
export const EXTRINSIC_CHECKS_TOTAL = 1;


export interface IRunNotification {
  runId: number;
}

export interface IRealtimeTimeSeriesKafka {
  runNotification: IRunNotification;
}

export interface IWssPayload {
  data: IRealtimeTimeSeriesKafka;
}

export interface IWssMessage {
  id: string;
  type: string | 'data' | 'ka' | 'connection_ack' | 'ping' | 'pong' | 'next' | 'complete';
  payload: IWssPayload;
}

export interface IWsStatusContent {
  official: number[];
}

export interface IWsStatus {
  msgType: 'subscribe' | 'subscription' | 'sent' | 'publish';
  content?: IWsStatusContent;
  routingKey?: string;
}

@Component({
  selector: 'lib-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportComponent implements OnInit, OnDestroy, AfterViewInit {
  private readonly _unsubscribeS$: Subject<any> = new Subject<any>();
  private readonly _loadingBS$ = new BehaviorSubject<boolean>(false);
  public loading$ = this._loadingBS$.asObservable();

  /*private _assetsIntrinsic: string[];
  private _assetsExtrinsic: string[];*/

  private _assetsIntrinsicST: string[] | undefined;
  private _assetsIntrinsicLT: string[] | undefined;
  private _assetsExtrinsicST: string[] | undefined;
  private _assetsExtrinsicLT: string[] | undefined;

  get assetsIntrinsicST(): string[] | undefined {
    return this._assetsIntrinsicST;
  }

  set assetsIntrinsicST(value: string[] | undefined) {
    this._assetsIntrinsicST = value;
  }

  get assetsIntrinsicLT(): string[] | undefined {
    return this._assetsIntrinsicLT;
  }

  set assetsIntrinsicLT(value: string[] | undefined) {
    this._assetsIntrinsicLT = value;
  }

  get assetsExtrinsicST(): string[] | undefined {
    return this._assetsExtrinsicST;
  }

  set assetsExtrinsicST(value: string[] | undefined) {
    this._assetsExtrinsicST = value;
  }

  get assetsExtrinsicLT(): string[] | undefined {
    return this._assetsExtrinsicLT;
  }

  set assetsExtrinsicLT(value: string[] | undefined) {
    this._assetsExtrinsicLT = value;
  }

  assetDependencies: IAssetDependencies;

  today = moment();
  dateControl = new FormControl(moment().startOf('day').toDate(), []);
  commentControl = new FormControl('', []);

  private _runsArrIntrinsic: IReportParam[] = [];
  private _runsArrExtrinsic: IReportParam[] = [];

  get runsArrIntrinsic(): IReportParam[] {
    return this._runsArrIntrinsic;
  }

  set runsArrIntrinsic(value: IReportParam[]) {
    this._runsArrIntrinsic = value;
  }

  get runsArrExtrinsic(): IReportParam[] {
    return this._runsArrExtrinsic;
  }

  set runsArrExtrinsic(value: IReportParam[]) {
    this._runsArrExtrinsic = value;
  }

  rowSelected: IDeltaTableRow;
  tableRowsIntrinsic: IDeltaTableRow[] = [];
  tableRowsExtrinsic: IDeltaTableRow[] = [];
  dataSourceIntrinsic = new MatTableDataSource<IDeltaTableRow>();
  dataSourceExtrinsic = new MatTableDataSource<IDeltaTableRow>();

  readonly INITIAL_COLUMNS = ['asset', 'cob1', 'cob2', 'runid1', 'runid2', 'check'];
  private _displayedColumns1: string[] = this.INITIAL_COLUMNS;
  private _displayedColumns2: string[] = this.INITIAL_COLUMNS;

  get displayedColumns1(): string[] {
    return this._displayedColumns1;
  }

  set displayedColumns1(value: string[]) {
    this._displayedColumns1 = value;
  }

  get displayedColumns2(): string[] {
    return this._displayedColumns2;
  }

  set displayedColumns2(value: string[]) {
    this._displayedColumns2 = value;
  }

  private _hiddenColumns: string[] = ['version1', 'official1', 'fuel1', 'timestamp1', 'pzone1', 'valid1',
    'version2', 'official2', 'fuel2', 'timestamp2', 'pzone2', 'valid2', 'run1OfficialNotLast', 'run2OfficialNotLast',
    'violation', 'stoch'];

  get hiddenColumns(): string[] {
    return this._hiddenColumns;
  }

  set hiddenColumns(value: string[]) {
    this._hiddenColumns = value;
  }

  checkResponsesIntrinsic: ICheckResponse[] = [];
  checkResponsesExtrinsic: ICheckResponse[] = [];

  readonly LIST_TYPES: IListType[] = [
    { id: 1, name: 'ST'},
    { id: 2, name: 'LT'}
  ];
  listTypeSelected: IListType = this.LIST_TYPES[0];
  readonly RUN_LIST_TYPES: IListType[] = [
    { id: 1, name: 'Intrinsic'},
    { id: 2, name: 'Extrinsic'}
  ];
  runListTypeSelected: IListType = this.RUN_LIST_TYPES[0];

  @ViewChild(MatMenuTrigger, {static:false}) actionsMenuTrigger: MatMenuTrigger;

  private _notificationWebSocketSubject$: WebSocketSubject<any> =
    this._murexExpService.initNotificationSubscription$();

  private _runStatusWebSocketSubject$: WebSocketSubject<IWsStatus> =
    this._murexExpService.initWSRunStatus$();

  private _runId1Selected: boolean;

  get runId1Selected(): boolean {
    return !!((this.dataSourceIntrinsic.data.find((row: IDeltaTableRow) => row['markedRunId1']) ||
      this.dataSourceExtrinsic.data.find((row: IDeltaTableRow) => row['markedRunId1'])));
  }

  public pageLoadTime: number;
  private _initialTimestamp: number;
  private _finalTimestamp: number;

  finishedQueries = 0;
  totalQueries = 0;
  queryProgress = 0;

  private _allRuns: IRun[] = [];

  private _hourlyRiskTaskIdentifier: string;
  private _hourlyRiskTaskStatus: string | null;

  constructor(private _validationService: ValidationService,
              private _runloaderService: RunloaderService,
              private _cdRef: ChangeDetectorRef,
              private _notifService: LibNotificationService,
              private _dialog: MatDialog,
              private _tsvpeService: LibTsvpeService,
              private _murexExpService: MurexExpService,
              private _elRef: ElementRef) { }

  ngOnInit(): void {
    this._notifService.startRecordingLogs();
    this._tsvpeService.reportPageSnapshotBS$
      .pipe(
        // takeUntil(this._unsubscribeS$),
        tap((pageSnapshot: IReportPageSnapshot) => {
          if (pageSnapshot
            && pageSnapshot.mainCob
            && (pageSnapshot.assetsIntrinsicST || pageSnapshot.assetsIntrinsicLT)
            && (pageSnapshot.assetsExtrinsicST || pageSnapshot.assetsExtrinsicLT)
            && pageSnapshot.dataSourceIntrinsic && pageSnapshot.dataSourceExtrinsic
            && pageSnapshot.checkResponsesIntrinsic
            && pageSnapshot.checkResponsesExtrinsic
            && pageSnapshot.displayedColumns1
            && pageSnapshot.displayedColumns2) {
            this.dateControl.patchValue(new Date(pageSnapshot.mainCob));
            this.assetsIntrinsicST = pageSnapshot.assetsIntrinsicST;
            this.assetsIntrinsicLT = pageSnapshot.assetsIntrinsicLT;
            this.assetsExtrinsicST = pageSnapshot.assetsExtrinsicST;
            this.assetsExtrinsicLT = pageSnapshot.assetsExtrinsicLT;
            this.dataSourceIntrinsic.data = pageSnapshot.dataSourceIntrinsic;
            this.dataSourceExtrinsic.data = pageSnapshot.dataSourceExtrinsic;
            this.checkResponsesIntrinsic = pageSnapshot.checkResponsesIntrinsic;
            this.checkResponsesExtrinsic = pageSnapshot.checkResponsesExtrinsic;
            this.displayedColumns1 = pageSnapshot.displayedColumns1;
            this.displayedColumns2 = pageSnapshot.displayedColumns2;
            this.commentControl.patchValue(pageSnapshot.comment ? pageSnapshot.comment : '');
            this.listTypeSelected = pageSnapshot.horizon ? this.LIST_TYPES.find((listType: IListType) => listType.name === pageSnapshot.horizon)! : this.LIST_TYPES[0];
          }
        }))
      .subscribe();
    /*this._murexExpService.messageStreamMurexExpBS$
      .pipe(takeUntil(this._unsubscribeS$),
        tap((response: IMurexExpMessage | null) => {
          // console.log(response);
          if (this.dataSourceIntrinsic.data && this.dataSourceIntrinsic.data.length > 0 && response) {
            const rowFound = this.dataSourceIntrinsic.data.find((tableRowIntrinsic: IDeltaTableRow) => tableRowIntrinsic.runid1 === response?.runId);
            if (rowFound) {
              rowFound.official1 = true;
              this._cdRef.markForCheck();
            }
          }
        }))
      .subscribe();*/
    // this._murexExpService.initStompConn();

    this._notificationWebSocketSubject$
      .pipe(
        takeUntil(this._unsubscribeS$),
        tap((response: IWssMessage) => {
          switch (response.type) {
            case 'ka':
              console.log('ka');
              break;
            case 'connection_ack':
              console.log('connection ack');
              break;
            case 'complete':
              console.log('sub completed');
              break;
            case 'next':
              if (this.dataSourceIntrinsic.data && this.dataSourceIntrinsic.data.length > 0 && response.payload.data.runNotification.runId) {
                const rowsFound = this.dataSourceIntrinsic.data.filter((tableRowIntrinsic: IDeltaTableRow) => tableRowIntrinsic.runid1 === response.payload.data.runNotification.runId);
                if (rowsFound && rowsFound.length > 0) {
                  rowsFound.forEach((row: IDeltaTableRow) => row.official1 = true);
                  this._cdRef.markForCheck();
                }
              }
              break;
            default:
              // not parsed
              console.log(response);
              break;
          }
        }),
        retryWhen((errors: Observable<any>) =>
          errors.pipe(
            delay(20),
            tap(err => console.error('Connection error (WS-Notification)', (err as Error)?.message ? (err as Error)?.message : err))
          )
        ),
        /*repeatWhen((notifs: Observable<any>) =>
          notifs.pipe(
            delay(20),
            tap(notif => {
              console.error('Got notification (wss)', notif);
              // this._startNotificationFlow();
            })
          ))*/
      )
      .subscribe({
        next: (msg: any) => {},
        error: (err) => console.log('WS Notification error: ', err),
        complete: () => console.log('WS Notification complete: ', moment())
      });

    this._watchComment();

    this._validationService.getAssetDependencies$()
      .pipe(takeUntil(this._unsubscribeS$))
      .subscribe({next: (deps: IAssetDependencies) => this.assetDependencies = deps});

    this._runStatusWebSocketSubject$.pipe(
      takeUntil(this._unsubscribeS$),
      tap((response: IWsStatus) => {
        switch (response.msgType) {
          case 'sent':
            if (this.dataSourceIntrinsic.data && this.dataSourceIntrinsic.data.length > 0 && response.content?.official && response.content?.official.length > 0) {
              response.content?.official.forEach((runIdUpdated: number) => {
                const rowsFound = this.dataSourceIntrinsic.data.filter((tableRowIntrinsic: IDeltaTableRow) => tableRowIntrinsic.runid1 === runIdUpdated);
                if (rowsFound && rowsFound.length > 0) {
                  rowsFound.forEach((tableRowIntrinsic: IDeltaTableRow) => tableRowIntrinsic.official1 = true);
                }
              });
              this._cdRef.markForCheck();
            }
            break;
          case 'subscription':
            console.log('WS Status Updates Subscription initiated.');
            break;
          default:
            // not parsed
            console.log(response);
            break;
        }
      }),
      retryWhen((errors: Observable<any>) =>
        errors.pipe(
          delay(20),
          tap(err => console.error('Connection error (WS-Status)', (err as Error)?.message ? (err as Error)?.message : err))
        )
      )
    ).subscribe({
      next: (msg: any) => {},
      error: (err) => console.log('WS Status error: ', err),
      complete: () => console.log('WS Status complete: ', moment())
    });
  }

  private _initRunStatusWSConn(): void {
    this._runStatusWebSocketSubject$.next({
      msgType: 'subscribe',
      routingKey: 'runManager'
    });
  }

  private _startNotificationFlow(): void {
    this._notificationWebSocketSubject$.next({
      type: "connection_init"
    });
    /*this._notificationWebSocketSubject$.next({
      type: "ping"
    });*/
    const query = 'subscription {runNotification {runId}}';
    this._notificationWebSocketSubject$.next({
      id: 1,
      type: 'subscribe',
      payload: {
        query
      }
    });
  }

  private _watchComment(): void {
    this.commentControl.valueChanges
      .pipe(takeUntil(this._unsubscribeS$),
        debounceTime(900),
        distinctUntilChanged(),
        tap((newVal: string | null) => this._tsvpeService.updateReportPageSnapshot({
          ...this._tsvpeService.getReportPageSnapshot(),
          comment: newVal ? newVal : ''
        }))
      )
      .subscribe();
  }

  load(): void {
    this._tsvpeService.updateEodTabEnable(false);
    this._tsvpeService.updateEodPageSnapshot({});
    this._initialTimestamp = moment().valueOf();
    this._loadingBS$.next(true);

    this._allRuns = [];
    this.runsArrIntrinsic = [];
    this.runsArrExtrinsic = [];
    this.displayedColumns1 = this.INITIAL_COLUMNS;
    this.displayedColumns2 = this.INITIAL_COLUMNS;
    this.dataSourceIntrinsic = new MatTableDataSource<IDeltaTableRow>();
    this.dataSourceExtrinsic = new MatTableDataSource<IDeltaTableRow>();
    this.tableRowsIntrinsic = [];
    this.tableRowsExtrinsic = [];

    this.checkResponsesIntrinsic = [];
    this.checkResponsesExtrinsic = [];

    this._validationService.getAssets$()
      .pipe(
        // takeUntil(this._unsubscribeS$),
        tap((assetResponse: IAssetResponse) => {
          this.assetsIntrinsicST = assetResponse.intrinsic_ST;
          this.assetsIntrinsicLT = assetResponse.intrinsic_LT;
          this.assetsExtrinsicST = assetResponse.extrinsic_ST;
          this.assetsExtrinsicLT = assetResponse.extrinsic_LT;
          this.totalQueries = this.listTypeSelected.name === 'ST' ?
            this.assetsIntrinsicST.length * (INTRINSIC_CHECKS_TOTAL + 1) + this.assetsExtrinsicST.length * EXTRINSIC_CHECKS_TOTAL :
            this.assetsIntrinsicLT.length * (INTRINSIC_CHECKS_TOTAL + 1) + this.assetsExtrinsicLT.length * EXTRINSIC_CHECKS_TOTAL;
        }),
        switchMap(() => {
          const lastRunsDeltasArr$: Observable<IDeltaResponse | ICheckResponse>[] = [];
          const bigCob = moment(this.dateControl.value).format('YYYYMMDD');
          // provided the fact that intrinsic asset list contains all possible assets,
          // otherwise, it should be split for intrinsic and extrinsic asset lists
          const assetsIntrinsic = this.listTypeSelected.name === 'ST' ? this.assetsIntrinsicST : this.assetsIntrinsicLT;
          const assetsExtrinsic = this.listTypeSelected.name === 'ST' ? this.assetsExtrinsicST : this.assetsExtrinsicLT;
          assetsIntrinsic?.forEach((asset: string) => {
            // const lastRunDelta$ = this._runloaderService.getClosestRuns$({
            const lastRunDelta$ = this._runloaderService.getLastRuns$({
                asset: asset.split(" ")[0],
                databook: this.listTypeSelected.name === 'ST' ? 'Optimisation' : 'OptimisationLT',
                cob: bigCob,
                lastVersionOnly: 'true',
                closest: 'true',
                limit: '100'})
              .pipe(switchMap((runs: IRun[]) => {
                this._allRuns.push(...runs);
                if (runs && runs.length > 0) {
                  this._updateQueryProgressBar();

                  const runsIntrinsic = runs.filter((run: IRun) => !run.stoch);
                  const runsExtrinsic = runs.filter((run: IRun) => run.stoch);
                  const fullReportParamIntrinsic = this._buildRunsArrIntrinsic(runsIntrinsic, asset);
                  const fullReportParamExtrinsic = this._buildRunsArrExtrinsic(runsExtrinsic, asset);

                  let checksArr$: Observable<ICheckResponse>[] = [];
                  if (fullReportParamIntrinsic.run1 && fullReportParamIntrinsic.run2) {
                    const checksArrIntrinsic$: Observable<ICheckResponse>[] = [];
                    for(let i = 1; i <= INTRINSIC_CHECKS_TOTAL; i++) {
                      const check$ = this._validationService.getCheck$(i, fullReportParamIntrinsic.run1.runId, fullReportParamIntrinsic.run2.runId, fullReportParamIntrinsic.asset, false)
                        .pipe(
                          catchError((err: any) => of()),
                          tap((check: ICheckResponse) => {
                            // adding deltas to the table, which is contained by check no. 1
                            if (check.metadata.check.number === 1) {
                              // enrich delta response with run info
                              let tableRow: IDeltaTableRow = {
                                asset: check.metadata.asset,
                                ...check.delta,
                                cob1: <number>fullReportParamIntrinsic.run1?.cob,
                                cob2: <number>fullReportParamIntrinsic.run2?.cob,
                                runid1: check.metadata.run1.runid,
                                runid2: check.metadata.run2.runid,
                                valid1: fullReportParamIntrinsic.run1?.valid,
                                official1: fullReportParamIntrinsic.run1?.official,
                                version1: fullReportParamIntrinsic.run1?.version,
                                version2: fullReportParamIntrinsic.run2?.version,
                                run1OfficialNotLast: fullReportParamIntrinsic.run1OfficialNotLast,
                                run2OfficialNotLast: true,
                                violation: {
                                  red: check.violations?.prod ? Object.keys(check.violations?.prod) : undefined,
                                  yellow: check.violations2?.prod? Object.keys(check.violations2?.prod) : undefined
                                },
                                stoch: false
                              };
                              // we set the columns only for the first time
                              if (this.displayedColumns1.length === 6) {
                                // all the keys minus the "hidden column" ones
                                const deltaRows = Object.keys(tableRow).filter((column: string) => !this.hiddenColumns.find((hiddenColumn: string) => column === hiddenColumn));
                                this.displayedColumns1 = deltaRows;
                              }
                              this.tableRowsIntrinsic.push(tableRow);
                            }
                            this.checkResponsesIntrinsic.push(check);

                            this._updateQueryProgressBar();
                          })
                        );
                      checksArrIntrinsic$.push(check$);
                    }
                    checksArr$ = checksArr$.concat(checksArrIntrinsic$);
                  }

                  if (fullReportParamExtrinsic.run1 && fullReportParamExtrinsic.run2 && assetsExtrinsic?.find((assetExtr: string) => assetExtr === asset)) {
                    const checksArrExtrinsic$: Observable<ICheckResponse>[] = [];

                    for(let i = 1; i <= EXTRINSIC_CHECKS_TOTAL; i++) {
                      const check$ = this._validationService.getCheck$(i, fullReportParamExtrinsic.run1.runId, fullReportParamExtrinsic.run2.runId, fullReportParamExtrinsic.asset, true)
                        .pipe(
                          catchError((err: any) => of()),
                          tap((check: ICheckResponse) => {
                            // adding deltas to the table, which is contained by check no. 1
                            if (check.metadata.check.number === 1) {
                              // enrich delta response with run info
                              let tableRow: IDeltaTableRow = {
                                asset: check.metadata.asset,
                                ...check.delta,
                                cob1: <number>fullReportParamExtrinsic.run1?.cob,
                                cob2: <number>fullReportParamExtrinsic.run2?.cob,
                                runid1: check.metadata.run1.runid,
                                runid2: check.metadata.run2.runid,
                                valid1: fullReportParamExtrinsic.run1?.valid,
                                official1: fullReportParamExtrinsic.run1?.official,
                                version1: fullReportParamExtrinsic.run1?.version,
                                version2: fullReportParamExtrinsic.run2?.version,
                                run1OfficialNotLast: fullReportParamExtrinsic.run1OfficialNotLast,
                                run2OfficialNotLast: true,
                                violation: {
                                  red: check.violations?.prod ? Object.keys(check.violations?.prod) : undefined,
                                  yellow: check.violations2?.prod? Object.keys(check.violations2?.prod) : undefined
                                },
                                stoch: true
                              };
                              // we set the columns only for the first time
                              if (this.displayedColumns2.length === 6) {
                                // all the keys minus the "hidden column" ones
                                const deltaRows = Object.keys(tableRow).filter((column: string) => !this.hiddenColumns.find((hiddenColumn: string) => column === hiddenColumn));
                                this.displayedColumns2 = deltaRows;
                              }
                              this.tableRowsExtrinsic.push(tableRow);
                            }
                            this.checkResponsesExtrinsic.push(check);

                            this._updateQueryProgressBar();
                          })
                        );
                      checksArrExtrinsic$.push(check$);
                    }
                    checksArr$ = checksArr$.concat(checksArrExtrinsic$);
                  }

                  if(checksArr$.length > 0) {
                    return merge(...checksArr$);
                    // return concat(...checksArr$);
                  }
                  else {
                    return EMPTY;
                  }
                } else {
                  return EMPTY;
                }
              }));
            lastRunsDeltasArr$.push(lastRunDelta$);
          });
          return concat(...lastRunsDeltasArr$).pipe(/*takeUntil(this._unsubscribeS$)*/);
        })
      )
      .subscribe({
        next: () => {},
        error: (error: any) => {
          this._resetQueryProgressBar();
          this._notifService.error(error.toString());
        },
        complete: () => {
          this._loadingBS$.next(false);
          if (this.tableRowsIntrinsic.length > 0) {
            const orderedTableRows = this._orderCompleteAssetRows(this.tableRowsIntrinsic, TABLE_TYPE.INTRINSIC);
            const orderedCheckedTableRows = this._addCheckColumnData(orderedTableRows, TABLE_TYPE.INTRINSIC);
            this.dataSourceIntrinsic.data = orderedCheckedTableRows;
          }
          if (this.tableRowsExtrinsic.length > 0) {
            const orderedTableRows = this._orderCompleteAssetRows(this.tableRowsExtrinsic, TABLE_TYPE.EXTRINSIC);
            const orderedCheckedTableRows = this._addCheckColumnData(orderedTableRows, TABLE_TYPE.EXTRINSIC);
            this.dataSourceExtrinsic.data = orderedCheckedTableRows;
          }
          this._tsvpeService.updateReportPageSnapshot({
            mainCob: this.dateControl.value?.toString(),
            assetsIntrinsicST: this.assetsIntrinsicST,
            assetsIntrinsicLT: this.assetsIntrinsicLT,
            assetsExtrinsicST: this.assetsExtrinsicST,
            assetsExtrinsicLT: this.assetsExtrinsicLT,
            dataSourceIntrinsic: this.dataSourceIntrinsic.data,
            dataSourceExtrinsic: this.dataSourceExtrinsic.data,
            checkResponsesIntrinsic: this.checkResponsesIntrinsic,
            checkResponsesExtrinsic: this.checkResponsesExtrinsic,
            displayedColumns1: this.displayedColumns1,
            displayedColumns2: this.displayedColumns2,
            comment: this.commentControl.value ? this.commentControl.value : '',
            horizon: this.listTypeSelected ? this.listTypeSelected.name : 'ST'
          });
          this._forBackendTesting();
          this._finalTimestamp = moment().valueOf();
          this.pageLoadTime = (this._finalTimestamp - this._initialTimestamp) / 1000;
          this._resetQueryProgressBar();
          this._cdRef.markForCheck();
        }
      })
  }

  private _buildRunsArrIntrinsic(runs: IRun[], segregatedAsset: string): IReportParam {
    let run1: IRun | undefined;
    let run2: IRun | undefined;

    const runsInitial = runs;

    let run1Found = false;
    let cobChosen: number | null = +moment(this.dateControl.value).format('YYYYMMDD');
    let run1OfficialNotLast;

    while (!run1Found && cobChosen) {
      let officialRunFoundIndex = 0;
      const officialRunFound = runs.find((run: IRun, index: number) => {
        if (run.official && run.valid && run.cob === cobChosen) {
          officialRunFoundIndex = index;
          return true;
        }
        return false;
      });
      if (officialRunFound) {
        run1 = officialRunFound;
        run1Found = true;
        if (runs.slice(0, officialRunFoundIndex).find((run: IRun) => run.valid || run.valid === null || !run.hasOwnProperty('valid'))) {
          run1OfficialNotLast = true;
        }
      } else {
        const newRunAvailable = runs.filter((run: IRun) => run.cob === cobChosen)
          .find((run: IRun) => run.valid || !run.valid || run.valid === null || !run.hasOwnProperty('valid'));
        if (newRunAvailable) {
          run1 = newRunAvailable;
          run1Found = true;
        } else {
          runs = runs.filter((run: IRun) => run.cob < cobChosen!);
          if (runs && runs.length > 0) {
            cobChosen = runs[0].cob;
          } else{
            cobChosen = null;
          }
        }
      }
    }

    for (let run of runsInitial) {
      if (run1 && run.official && run.valid && run.runId < run1.runId && run.cob < run1.cob) {
        run2 = run;
        break;
      }
    }

    const fullReportParam = { asset: segregatedAsset, run1: run1, run2: run2, run1OfficialNotLast: run1OfficialNotLast };
    this.runsArrIntrinsic.push(fullReportParam);
    return fullReportParam;
  }

  private _buildRunsArrExtrinsic(runs: IRun[], segregatedAsset: string): IReportParam {
    let run1: IRun | undefined;
    let run2: IRun | undefined;

    const runsInitial = runs;

    let run1Found = false;
    let cobChosen: number | null = +moment(this.dateControl.value).format('YYYYMMDD');
    let run1OfficialNotLast;

    while (!run1Found && cobChosen) {
      let officialRunFoundIndex = 0;
      const officialRunFound = runs.find((run: IRun, index: number) => {
        if (run.official && run.valid && run.cob === cobChosen) {
          officialRunFoundIndex = index;
          return true;
        }
        return false;
      });
      if (officialRunFound) {
        run1 = officialRunFound;
        run1Found = true;
        if (runs.slice(0, officialRunFoundIndex).find((run: IRun) => run.valid || run.valid === null || !run.hasOwnProperty('valid'))) {
          run1OfficialNotLast = true;
        }
      } else {
        const newRunAvailable = runs.filter((run: IRun) => run.cob === cobChosen)
          .find((run: IRun) => run.valid || !run.valid || run.valid === null || !run.hasOwnProperty('valid'));
        if (newRunAvailable) {
          run1 = newRunAvailable;
          run1Found = true;
        } else {
          runs = runs.filter((run: IRun) => run.cob < cobChosen!);
          if (runs && runs.length > 0) {
            cobChosen = runs[0].cob;
          } else{
            cobChosen = null;
          }
        }
      }
    }

    for (let run of runsInitial) {
      // && run.valid
      if (run1 && run.official && run.runId < run1.runId && run.cob < run1.cob) {
        run2 = run;
        break;
      }
    }

    const fullReportParam = { asset: segregatedAsset, run1: run1, run2: run2 };
    this.runsArrExtrinsic.push(fullReportParam);
    return fullReportParam;
  }

  private _orderCompleteAssetRows(unorderedRows: IDeltaTableRow[], tableType: number): IDeltaTableRow[] {
    const orderedRows: IDeltaTableRow[] = [];

    let assets: string[] | undefined;
    let runsArr: IReportParam[];
    let stoch = false;
    if (tableType === TABLE_TYPE.INTRINSIC) {
      assets = this.listTypeSelected.name === 'ST' ? this.assetsIntrinsicST : this.assetsIntrinsicLT;
      runsArr = this.runsArrIntrinsic;
    } else {
      assets = this.listTypeSelected.name === 'ST' ? this.assetsExtrinsicST : this.assetsExtrinsicLT;
      runsArr = this.runsArrExtrinsic;
      stoch = true;
    }
    assets?.forEach((assetName: string) => {
      let foundUnorderedFullRow = unorderedRows.find((row: IDeltaTableRow) => row.asset === assetName);
      if (foundUnorderedFullRow) {
        orderedRows.push(foundUnorderedFullRow);
      } else {
        let foundIncompleteTriple = runsArr.find((runsParam: IReportParam) => runsParam.asset === assetName);
        if (foundIncompleteTriple) {
          orderedRows.push({
            asset: foundIncompleteTriple.asset,
            cob1: foundIncompleteTriple.run1?.cob,
            cob2: foundIncompleteTriple.run2?.cob,
            runid1: foundIncompleteTriple.run1?.runId,
            runid2: foundIncompleteTriple.run2?.runId,
            valid1: foundIncompleteTriple.run1?.valid,
            official1: foundIncompleteTriple.run1?.official,
            version1: foundIncompleteTriple.run1?.version,
            version2: foundIncompleteTriple.run2?.version,
            stoch: stoch
          });
        } else {
          orderedRows.push({asset: assetName, stoch: stoch});
        }
      }
    });
    return orderedRows;
  }

  private _addCheckColumnData(orderedRows: IDeltaTableRow[], tableType: number): IDeltaTableRow[] {
    let displayedColumns: string[];
    let checkResponses: ICheckResponse[];
    let checksTotal: number;
    if (tableType === TABLE_TYPE.INTRINSIC) {
      displayedColumns = this.displayedColumns1;
      checkResponses = this.checkResponsesIntrinsic;
      checksTotal = INTRINSIC_CHECKS_TOTAL;
    } else {
      displayedColumns = this.displayedColumns2;
      checkResponses = this.checkResponsesExtrinsic;
      checksTotal = EXTRINSIC_CHECKS_TOTAL;
    }
    if (!displayedColumns.find((column: string) => column === 'check')) {
      displayedColumns.push('check');
    }
    // !! there are stoch runs that are not bound to extrinsic assets (extrinsic asset list seems to be incomplete)
    checkResponses.filter((checkResp: ICheckResponse) => checkResp.check === 'NOK' && checkResp.metadata.check.number !== 1)
      .forEach((checkRespNok: ICheckResponse) => {
        const foundCheckToAssetMatch = orderedRows.find((row: IDeltaTableRow) => row.asset === checkRespNok.metadata.asset);
        if (foundCheckToAssetMatch) {
          foundCheckToAssetMatch.check = 'NOK';
        }
      });
    orderedRows.forEach((row: IDeltaTableRow) => {
      const checksFoundOK = checkResponses.filter((checkResp: ICheckResponse) => checkResp.metadata.asset === row.asset && checkResp.metadata.check.number !== 1).length;
      if(row.runid1 && row.runid2 && !row.check && checksFoundOK === checksTotal - 1) {
        row.check = 'OK';
      }
    });
    return orderedRows;
  }

  isNegativeValue(value: any): boolean {
    let negative = false;
    if (typeof value === 'number') {
      if (value < 0) {
        negative = true;
      }
    }
    return negative;
  }

  getCheckClassBg(row: IDeltaTableRow): string {
    let validClassBg = '';
    if (typeof row.check !== 'undefined') {
      if (row.check === 'OK') {
        validClassBg = 'valid-bg';
      } else {
        validClassBg = 'invalid-bg';
      }
    }
    return validClassBg;
  }

  getValidRunId1ClassBg(row: IDeltaTableRow): string {
    let validClassBg = '';
    if (typeof row['valid1'] !== 'undefined' && row['valid1'] !== null) {
      if (row['valid1']) {
        validClassBg = 'valid-bg';
      } else {
        validClassBg = 'invalid-bg';
      }
    }
    return validClassBg;
  }

  openChangeRunDialog(): void {
    let allRuns: IRun[] = [];
    let dialogRef: MatDialogRef<DialogChangeRunComponent>;

    const bigCob = moment(this.dateControl.value).format('YYYYMMDD');
    // this._runloaderService.getLastRuns$(
    this._runloaderService.getClosestRuns$(
      {asset: this.rowSelected.asset.split(" ")[0],
        databook: this.listTypeSelected.name === 'ST' ? 'Optimisation' : 'OptimisationLT',
        cob: bigCob,
        // closest: 'true',
        stoch: this.rowSelected.stoch.toString(),
        limit: '100'})
      .pipe(
        takeUntil(this._unsubscribeS$),
        tap((runs: IRun[]) => {
          allRuns = runs;
          dialogRef = this._dialog.open(DialogChangeRunComponent, {
            width: '46rem',
            data: {
              rowSelected: this.rowSelected,
              allRuns
            }
          });
        }),
        mergeMap(() => dialogRef.afterClosed()
          .pipe(
            filter((rowAltered: IDeltaTableRow) => !!rowAltered),
            switchMap((rowAltered: IDeltaTableRow) => {
              let checksArr$: Observable<ICheckResponse>[] = [];
              // identify all rows of same asset
              const assetsToUpdate = this.listTypeSelected.name === 'ST' ?
                this.assetsIntrinsicST?.filter((asset: string) => asset.split(" ")[0] === rowAltered.asset.split(" ")[0]) :
                this.assetsIntrinsicLT?.filter((asset: string) => asset.split(" ")[0] === rowAltered.asset.split(" ")[0]);
              assetsToUpdate?.forEach((asset: string) => {
                // remove previous checks for the asset that we're performing checks again
                this.checkResponsesIntrinsic = this.checkResponsesIntrinsic.filter((check: ICheckResponse) => check.metadata.asset !== asset);
                for(let i = 1; i <= INTRINSIC_CHECKS_TOTAL; i++) {
                  const check$ = this._validationService.getCheck$(i, rowAltered.runid1!, rowAltered.runid2!, asset, false)
                    .pipe(
                      tap((check: ICheckResponse) => {
                        // adding deltas to the table, which is contained by check no. 1
                        if (check.metadata.check.number === 1) {
                          const dataSourceRow = this.dataSourceIntrinsic.data.find((row: IDeltaTableRow) => row.asset === asset);
                          if (dataSourceRow) {
                            Object.entries(check.delta).forEach((entry: [string, string | number]) => {
                              const foundColumn = this.displayedColumns1.find((column: string) => column === entry[0]);
                              if (foundColumn) {
                                dataSourceRow[foundColumn] = entry[1];
                              }
                            });
                          }
                          // this._cdRef.markForCheck();
                        }
                        this.checkResponsesIntrinsic.push(check);
                      })
                    );
                  checksArr$.push(check$);
                }
              });
              return merge(...checksArr$).pipe(takeUntil(this._unsubscribeS$));
            })
          ))
        )
      .subscribe({
        next: () => this._cdRef.markForCheck(),
        error: () => this._cdRef.markForCheck(),
        complete: () => {
          this._tsvpeService.updateReportPageSnapshot({
            mainCob: this.dateControl.value?.toString(),
            assetsIntrinsicST: this.assetsIntrinsicST,
            assetsIntrinsicLT: this.assetsIntrinsicLT,
            assetsExtrinsicST: this.assetsExtrinsicST,
            assetsExtrinsicLT: this.assetsExtrinsicLT,
            dataSourceIntrinsic: this.dataSourceIntrinsic.data,
            dataSourceExtrinsic: this.dataSourceExtrinsic.data,
            checkResponsesIntrinsic: this.checkResponsesIntrinsic,
            checkResponsesExtrinsic: this.checkResponsesExtrinsic,
            displayedColumns1: this.displayedColumns1,
            displayedColumns2: this.displayedColumns2,
            comment: this.commentControl.value ? this.commentControl.value : '',
            horizon: this.listTypeSelected ? this.listTypeSelected.name : 'ST'
          });
          this._cdRef.markForCheck();
        }
      });
  }

  isViolation(violations: string[], column: string): boolean {
    return violations.some((dateViolated: string) => dateViolated === column);
  }

  markRunId1(row: IDeltaTableRow): void {
    row['markedRunId1'] = row['markedRunId1'] ? false : true;
  }

  getMarkedRunId1ClassBg(row: IDeltaTableRow): string {
    let markedClassBg = '';
    if (row['markedRunId1']) {
      markedClassBg = 'marked-run-id1-bg';
    }
    return markedClassBg;
  }

  getOfficialRunId1ClassBg(row: IDeltaTableRow): string {
    let officialClassBg = '';
    if (row.official1) {
      officialClassBg = 'official-run';
    }
    return officialClassBg;
  }

  isDependant(groupKeysOfMarkedAssets: string[], treeKeysOfMarkedAssets: string[], asset: string): boolean {

    let groupKeyOfAsset: string | undefined = Object.keys(this.assetDependencies.groups).find(key => this.assetDependencies.groups[key].includes(asset));
    let treeKeyOfAsset: string | undefined = Object.keys(this.assetDependencies.tree).find(key => this.assetDependencies.tree[key].includes(asset));

    let found = false;
    if ((groupKeysOfMarkedAssets.length > 0 && groupKeyOfAsset && groupKeysOfMarkedAssets.includes(groupKeyOfAsset)) ||
      (treeKeysOfMarkedAssets.length > 0 && treeKeyOfAsset && treeKeysOfMarkedAssets.includes(treeKeyOfAsset))) {
      found = true;
    }
    return found;
  }

  validate(validate: boolean): void {
    let dataSource = this.dataSourceIntrinsic.data;
    if (this.runListTypeSelected.id === 2) {
      dataSource = this.dataSourceExtrinsic.data;
    }
    let validateArr$: Observable<IRun>[] = [];
    const isIndividual = dataSource.find((row: IDeltaTableRow) => row['markedRunId1']);
    let markedRunsAssets: string[];
    const groupKeysOfMarkedAssets: string[] = [];
    const treeKeysOfMarkedAssets: string[] = [];
    let treeValuesOfMarkedAssets: string[] = [];
    let allTreeKeys: string[];
    if (isIndividual && !validate) {
      markedRunsAssets = dataSource.filter((row: IDeltaTableRow) => row['markedRunId1']).map((row: IDeltaTableRow) => row.asset);
      markedRunsAssets.forEach((assetMarked: string) => {
        const groupKeyFound: string | undefined = Object.keys(this.assetDependencies.groups).find(key => this.assetDependencies.groups[key].includes(assetMarked));
        const treeKeyFound: string | undefined = Object.keys(this.assetDependencies.tree).find(key => key === assetMarked);
        if (groupKeyFound) {
          groupKeysOfMarkedAssets.push(groupKeyFound);
        }
        if (treeKeyFound) {
          treeKeysOfMarkedAssets.push(treeKeyFound);
          const treeValuesFound: string[] = this.assetDependencies.tree[treeKeyFound];
          treeValuesOfMarkedAssets = treeValuesOfMarkedAssets.concat(treeValuesFound);
        }
      });
      // go down the tree and add leaves that are keys themselves
      const otherTreeKeysFurtherDown: string[] = [];
      treeValuesOfMarkedAssets.forEach((value: string) => {
        const otherKeyFound = Object.keys(this.assetDependencies.tree).find(key => key === value);
        if (otherKeyFound) {
          otherTreeKeysFurtherDown.push(otherKeyFound);
        }
      });
      const allTreeKeysDuplicated = treeKeysOfMarkedAssets.concat(otherTreeKeysFurtherDown);
      const allTreeKeysSet = new Set(allTreeKeysDuplicated);
      allTreeKeys = Array.from(allTreeKeysSet);
    }
    dataSource.forEach((row: IDeltaTableRow) => {
      if (row.runid1) {
        let validate$: Observable<IRun>;
        if ((isIndividual && row['markedRunId1']) ||
          (isIndividual && !validate && (groupKeysOfMarkedAssets.length > 0 || treeKeysOfMarkedAssets.length > 0) && this.isDependant(groupKeysOfMarkedAssets, allTreeKeys, row.asset)) ||
          !isIndividual) {
          validate$ = this._runloaderService.putValid$(row.runid1, {valid: validate.toString(), reason:'Report validation'})
            .pipe(tap((run: IRun) => {
              const assetsToUpdate = this.listTypeSelected.name === 'ST' ?
                this.assetsIntrinsicST?.filter((asset: string) => asset.split(" ")[0] === row.asset.split(" ")[0]) :
                this.assetsIntrinsicLT?.filter((asset: string) => asset.split(" ")[0] === row.asset.split(" ")[0]);
              assetsToUpdate?.forEach((assetToUpdate: string) => {
                const rowToUpdate = dataSource.find((rowToUpdate: IDeltaTableRow) => rowToUpdate.asset === assetToUpdate);
                if (rowToUpdate) {
                  rowToUpdate['valid1'] = run.valid;
                  rowToUpdate['markedRunId1'] = false;
                  rowToUpdate.version1 = run.version;
                }
              })
            }));
          validateArr$.push(validate$);
        }
      }
    });
    merge(...validateArr$).pipe(takeUntil(this._unsubscribeS$))
      .subscribe({
        next: () => {
          this._notifService.success('Validation successful.');
          this._cdRef.markForCheck();
        },
        complete: () => {
          this._tsvpeService.updateReportPageSnapshot({
            mainCob: this.dateControl.value?.toString(),
            assetsIntrinsicST: this.assetsIntrinsicST,
            assetsIntrinsicLT: this.assetsIntrinsicLT,
            assetsExtrinsicST: this.assetsExtrinsicST,
            assetsExtrinsicLT: this.assetsExtrinsicLT,
            dataSourceIntrinsic: this.dataSourceIntrinsic.data,
            dataSourceExtrinsic: this.dataSourceExtrinsic.data,
            checkResponsesIntrinsic: this.checkResponsesIntrinsic,
            checkResponsesExtrinsic: this.checkResponsesExtrinsic,
            displayedColumns1: this.displayedColumns1,
            displayedColumns2: this.displayedColumns2,
            comment: this.commentControl.value ? this.commentControl.value : '',
            horizon: this.listTypeSelected ? this.listTypeSelected.name : 'ST'
          });
        }
      });
  }

  export(): void {
    if (this.runListTypeSelected.id === 1) {
      let toExportArr$: Observable<IRun>[] = [];
      const selected = this.dataSourceIntrinsic.data.find((row: IDeltaTableRow) => row['markedRunId1']);
      let rowsToExport: IDeltaTableRow[];
      if (selected) {
        rowsToExport = this.dataSourceIntrinsic.data.filter((row: IDeltaTableRow) => row['markedRunId1'] && row.runid1);
        if (rowsToExport.find((row: IDeltaTableRow) => !row['valid1'] || !row.hasOwnProperty('valid1'))) {
          this._notifService.warn('Only valid runs can be exported. Please select only valid runs.');
          return;
        }
      } else {
        if (this.dataSourceIntrinsic.data.find((row: IDeltaTableRow) => !row['valid1'] || !row.hasOwnProperty('valid1'))) {
          this._notifService.warn('Only valid runs can be exported. Please validate all runs.');
          return;
        }
        rowsToExport = this.dataSourceIntrinsic.data.filter((row: IDeltaTableRow) => row['valid1'] && row.runid1);
      }
      const exportPayload: IExportPayload = {asset_runs: []};
      const rowsToExportRunId1s = rowsToExport.map((row: IDeltaTableRow) => row.runid1);
      rowsToExport.filter((row: IDeltaTableRow, index: number) => rowsToExportRunId1s.indexOf(row.runid1) === index)
        .forEach((row: IDeltaTableRow) => {
          if (row.cob1 && row.runid1) {
            exportPayload.asset_runs.push({run1: {asset: row.asset, cob: row.cob1, runId: row.runid1},
              run2: {asset: row.asset, cob: row.cob2 ? row.cob2 : null, runId: row.runid2 ? row.runid2 : null}});
          }
          const toExport$ = this._runloaderService.putToExport$(row.runid1!, {to_export: 'true'});
          toExportArr$.push(toExport$);
      });

      const toExports$ = merge(...toExportArr$)
        .pipe(
          takeUntil(this._unsubscribeS$),
          catchError((err: any) => of()),
          tap((exportedRun: IRun) => {
            const assetRunExported = this.dataSourceIntrinsic.data.find((row: IDeltaTableRow) => row.asset === exportedRun.asset);
            if (assetRunExported) {
              assetRunExported.to_export1 = true;
            }
            this._cdRef.markForCheck();
          }),
          finalize(() => {
            this._tsvpeService.updateReportPageSnapshot({
              mainCob: this.dateControl.value?.toString(),
              assetsIntrinsicST: this.assetsIntrinsicST,
              assetsIntrinsicLT: this.assetsIntrinsicLT,
              assetsExtrinsicST: this.assetsExtrinsicST,
              assetsExtrinsicLT: this.assetsExtrinsicLT,
              dataSourceIntrinsic: this.dataSourceIntrinsic.data,
              dataSourceExtrinsic: this.dataSourceExtrinsic.data,
              checkResponsesIntrinsic: this.checkResponsesIntrinsic,
              checkResponsesExtrinsic: this.checkResponsesExtrinsic,
              displayedColumns1: this.displayedColumns1,
              displayedColumns2: this.displayedColumns2,
              comment: this.commentControl.value ? this.commentControl.value : '',
              horizon: this.listTypeSelected ? this.listTypeSelected.name : 'ST'
            });
            this._notifService.success('Export triggered.');
          }))
      concat(toExports$, this._runloaderService.export$('Murex', exportPayload))
        .pipe(takeUntil(this._unsubscribeS$))
        .subscribe({
          next: (exportResp: IRun | IExportResponse) => {
            if (exportResp.hasOwnProperty('hourlyRiskTaskIdentifier')) {
              this._hourlyRiskTaskIdentifier = (exportResp as IExportResponse).hourlyRiskTaskIdentifier;
            }
          },
          error: (error: any) => this._notifService.error(error.message ? error.message.toString() : error.toString()),
          complete: () => {}
        });
    } else {
      let exportExtrinsic: IExportExtrinsic = {};
      this.dataSourceExtrinsic.data.forEach((extrinsicRun: IDeltaTableRow) => {
        const intrinsicAsset = this.dataSourceIntrinsic.data.find((intrinsicRun: IDeltaTableRow) => intrinsicRun.asset === extrinsicRun.asset);
        if (extrinsicRun.runid1 && intrinsicAsset && intrinsicAsset.runid1) {
          exportExtrinsic[extrinsicRun.asset] = {
            extrinsic_runId: extrinsicRun.runid1,
            intrinsic_runId: intrinsicAsset.runid1,
            extrinsic_cob: extrinsicRun.cob1!,
            intrinsic_cob: intrinsicAsset.cob1!
          };
        }
      });
      this._validationService.postExportExtrinsic$(exportExtrinsic)
        .pipe(takeUntil(this._unsubscribeS$))
        .subscribe({complete:() => {this._notifService.success('Export triggered.');}});
    }
  }

  openCheckDialog(rowSelected: IDeltaTableRow, tableType: number): void {
    if(rowSelected.check === 'OK' || rowSelected.check === 'NOK') {
      const checks = tableType === TABLE_TYPE.INTRINSIC ? this.checkResponsesIntrinsic.filter((check: ICheckResponse) => check.metadata.asset === rowSelected.asset) :
        this.checkResponsesExtrinsic.filter((check: ICheckResponse) => check.metadata.asset === rowSelected.asset);
      const dialogRef = this._dialog.open(DialogChecksComponent, {
        width: '26rem',
        data: {
          rowSelected,
          checks
        }
      });
    }
  }

  isDeltaColumn(column: string): boolean {
    return !this.INITIAL_COLUMNS.find((initialColumn: string) => initialColumn === column);
  }

  openActionsMenu(rowSelected: IDeltaTableRow, $event: MouseEvent): void {
    this.rowSelected = rowSelected;
    this.actionsMenuTrigger.closeMenu();
    this.actionsMenuTrigger.openMenu();
    const columnEltsWrapper = document.getElementsByClassName('cdk-overlay-connected-position-bounding-box');
    // default wrapper top-left: 272 - 7
    const topWrapper = (columnEltsWrapper[0] as HTMLDivElement).style.top ? (columnEltsWrapper[0] as HTMLDivElement).style.top.slice(0, -2) : 272;
    const leftWrapper = (columnEltsWrapper[0] as HTMLDivElement).style.left ? (columnEltsWrapper[0] as HTMLDivElement).style.left.slice(0, -2) : 7;
    const columnElts = document.getElementsByClassName('mat-menu-panel');
    (columnElts[0] as HTMLDivElement).style.top = ($event.y - +topWrapper) + 'px';
    (columnElts[0] as HTMLDivElement).style.left = ($event.x - +leftWrapper) + 'px';
  }

  openInfoDialog(): void {
    const dialogRef = this._dialog.open(DialogRunInfoComponent, {
      width: '64rem',
      data: {
        rowSelected: this.rowSelected
      }
    });
    dialogRef.afterClosed()
      .pipe(
        takeUntil(this._unsubscribeS$),
        filter((runIdSelected: number) => !!runIdSelected),
        tap((runIdSelected: number) => {
          this._tsvpeService.changeTab(2);
          this._tsvpeService.updateRunIdState(runIdSelected.toString());
          this._cdRef.markForCheck();
        })
      )
      .subscribe();
  }

  private _openHourlyRiskDialog(status: string | null, options: string[]): void {
    const dialogRef = this._dialog.open(DialogHourlyRiskComponent, {
      width: '64rem',
      data: {
        status,
        options
      }
    });

    dialogRef.afterClosed()
      .pipe(
        takeUntil(this._unsubscribeS$),
        filter((goCalculateEOD: boolean) => !!goCalculateEOD),
        tap(() => {
          this._tsvpeService.updateEodInput2(this._generateAssetRuns());
          this._tsvpeService.changeTab(1);
          this._cdRef.markForCheck();
        })
      )
      .subscribe();
  }

  goEOD(): void {
    const selectedExtrinsic = this.dataSourceExtrinsic.data.find((row: IDeltaTableRow) => row['markedRunId1']);
    if (selectedExtrinsic || this.runListTypeSelected.id === 2) {
      // Extrinsic selected -> go straight to Eod
      this._tsvpeService.updateEodInput2(this._generateAssetRuns());
      this._tsvpeService.changeTab(1);
      this._cdRef.markForCheck();
    } else {
      if (this._hourlyRiskTaskIdentifier) {
        this._runloaderService.getHourlyRiskStatusByTaskId$(this._hourlyRiskTaskIdentifier)
          .pipe(takeUntil(this._unsubscribeS$),
            tap((hourlyRiskResp: IHourlyRiskStatusResponse) => {
              // hourlyRiskResp.status = HOURLY_RISK_STATUS_ENUM.SUCCESS;
              this._hourlyRiskTaskStatus = hourlyRiskResp.status;
              switch (hourlyRiskResp.status) {
                case HOURLY_RISK_STATUS_ENUM.WAITING_GO_SIGNAL:
                  this._notifService.warn('Calculator is doing the run exports, please wait.');
                  break;
                case HOURLY_RISK_STATUS_ENUM.RECEIVED:
                case HOURLY_RISK_STATUS_ENUM.PENDING:
                case HOURLY_RISK_STATUS_ENUM.STARTED:
                  this._notifService.warn('Hourly Risk export ongoing, please wait.');
                  break;
                case HOURLY_RISK_STATUS_ENUM.SUCCESS:
                  // const MOCK = '{"cob":"20231109","horizon":"ST","HourlyRiskTaskIdentifier":"81a82227-ae3b-49aa-9e02-6d7fa2946a73","HourlyRiskStatus":"SUCCESS","assets":[{"asset":"PH1","intrinsic_runId1":236070,"intrinsic_runId2":234981,"extrinsic_runId1":236445,"extrinsic_runId2":233015,"intrinsic_cob1":20231109,"intrinsic_cob2":20231108,"extrinsic_cob1":20231109,"extrinsic_cob2":20231106}]}';
                  // this._tsvpeService.updateEodInput2(JSON.parse(MOCK));
                  this._tsvpeService.updateEodInput2(this._generateAssetRuns());
                  this._tsvpeService.changeTab(1);
                  this._cdRef.markForCheck();
                  break;
                case HOURLY_RISK_STATUS_ENUM.FAILURE:
                  this._notifService.warn('Hourly Risk export failed.');
                  this._openHourlyRiskDialog(this._hourlyRiskTaskStatus,
                    ['CANCEL -> Fix the issue, do a run export again (to trigger a new Hourly Risk export), and do an EOD check export again',
                      'EOD Check no Hourly Risk -> Perform an EOD check without Hourly Risk included: call the EOD calculation endpoint with TaskId and Status']);
                  break;
                case HOURLY_RISK_STATUS_ENUM.REVOKED:
                case null:
                  this._notifService.warn('Hourly Risk export has been revoked.');
                  this._openHourlyRiskDialog(this._hourlyRiskTaskStatus,
                    ['CANCEL -> Do an export again (trigger a new Hourly Risk export) and do an EOD check export again',
                      'EOD Check no Hourly Risk -> Perform an EOD check without Hourly Risk included": call the EOD calculation endpoint with TaskId and Status']);
                  break;
                default:
                  break;
              }
            }))
          .subscribe();
      } else {
        this._notifService.error('Cannot perform EOD check. Please, export to Murex first.');
      }
    }
  }

  private _generateAssetRuns(): IEodInput3 {
    const assetRuns : IEodFullInput2[] = [];
    const selectedIntrinsic = this.dataSourceIntrinsic.data.find((row: IDeltaTableRow) => row['markedRunId1']);
    const selectedExtrinsic = this.dataSourceExtrinsic.data.find((row: IDeltaTableRow) => row['markedRunId1']);
    let rowsIntrinsicToEod: IDeltaTableRow[];
    let rowsExtrinsicToEod: IDeltaTableRow[];
    if (!selectedIntrinsic && !selectedExtrinsic) {
      rowsIntrinsicToEod = this.dataSourceIntrinsic.data.filter((row: IDeltaTableRow) => row.runid1 && row.runid2);
      rowsExtrinsicToEod = this.dataSourceExtrinsic.data.filter((row: IDeltaTableRow) => row.runid1 && row.runid2);
    } else if (selectedIntrinsic && !selectedExtrinsic) {
      rowsIntrinsicToEod = this.dataSourceIntrinsic.data.filter((row: IDeltaTableRow) => row['markedRunId1'] && row.runid1 && row.runid2);
    } else if (!selectedIntrinsic && selectedExtrinsic) {
      // we have to automatically select the corresponding intrinsic asset's RunIds
      rowsExtrinsicToEod = this.dataSourceExtrinsic.data.filter((row: IDeltaTableRow) => row['markedRunId1'] && row.runid1 && row.runid2);
      rowsIntrinsicToEod = this.dataSourceIntrinsic.data.filter((row: IDeltaTableRow) => {
        const intrinsicAssetFound = rowsExtrinsicToEod.find((rowExtr: IDeltaTableRow) => rowExtr.asset === row.asset);
        if (intrinsicAssetFound) {
          row['markedRunId1'] = true;
        }
        return intrinsicAssetFound && row.runid1 && row.runid2;
      });
    } else {
      rowsExtrinsicToEod = this.dataSourceExtrinsic.data.filter((row: IDeltaTableRow) => row['markedRunId1'] && row.runid1 && row.runid2);
      rowsIntrinsicToEod = this.dataSourceIntrinsic.data.filter((row: IDeltaTableRow) => {
        const intrinsicAssetFound =  rowsExtrinsicToEod.find((rowExtr: IDeltaTableRow) => rowExtr.asset === row.asset);
        if (intrinsicAssetFound) {
          row['markedRunId1'] = true;
        }
        return (row['markedRunId1'] || intrinsicAssetFound) && row.runid1 && row.runid2;
      });
    }
    let cobs: number[] = [];
    const assetsIntrinsic = this.listTypeSelected.name === 'ST' ? this.assetsIntrinsicST : this.assetsIntrinsicLT;
    assetsIntrinsic?.forEach((asset: string) => {
      const intrinsicFound = rowsIntrinsicToEod?.find((intrinsic: IDeltaTableRow) => intrinsic.asset === asset);
      const extrinsicFound = rowsExtrinsicToEod?.find((extrinsic: IDeltaTableRow) => extrinsic.asset === asset);
      if (intrinsicFound || extrinsicFound) {
        assetRuns.push({
          asset: asset,
          intrinsic_runId1: intrinsicFound?.runid1,
          intrinsic_runId2: intrinsicFound?.runid2,
          extrinsic_runId1: extrinsicFound?.runid1,
          extrinsic_runId2: extrinsicFound?.runid2,
          intrinsic_cob1: intrinsicFound?.cob1,
          intrinsic_cob2: intrinsicFound?.cob2,
          extrinsic_cob1: extrinsicFound?.cob1,
          extrinsic_cob2: extrinsicFound?.cob2
        });
        intrinsicFound?.cob1 ? cobs.push(intrinsicFound.cob1) : null;
        intrinsicFound?.cob2 ? cobs.push(intrinsicFound.cob2) : null;
        extrinsicFound?.cob1 ? cobs.push(extrinsicFound.cob1) : null;
        extrinsicFound?.cob2 ? cobs.push(extrinsicFound.cob2) : null;
      }
    });
    const cob = Math. max(...cobs);
    return {
      HourlyRiskStatus: this._hourlyRiskTaskStatus ? this._hourlyRiskTaskStatus : undefined,
      HourlyRiskTaskIdentifier: this._hourlyRiskTaskIdentifier ? this._hourlyRiskTaskIdentifier : undefined,
      cob: cob.toString(),
      horizon: this.listTypeSelected.name,
      assets: assetRuns
    };
  }

  private _forBackendTesting(): void {
    console.log(this._generateAssetRuns());
  }

  seeLogs(): void {
    const dialogRef = this._dialog.open(DialogLogsComponent, {
      width: '60rem',
      data: this._notifService.httpErrorList
    });
  }

  rollback(): void {
    const markedIntrinsic = this.dataSourceIntrinsic.data.filter((row: IDeltaTableRow) => row['markedRunId1']);
    const markedExtrinsic = this.dataSourceExtrinsic.data.filter((row: IDeltaTableRow) => row['markedRunId1']);

    let validateArr$: Observable<IRun>[] = [];
    let validateArrIntrinsic$: Observable<IRun>[] = [];
    if (markedIntrinsic && markedIntrinsic.length > 0) {
      markedIntrinsic.forEach((rowIntrinsic: IDeltaTableRow) => {
        if (rowIntrinsic['markedRunId1'] && rowIntrinsic.runid1) {
          const validate$ = this._runloaderService.putValid$(rowIntrinsic.runid1, {valid: 'false', reason:'Rollback'})
            .pipe(tap((run: IRun) => {
              const assetsToUpdate = this.listTypeSelected.name === 'ST' ?
                this.assetsIntrinsicST?.filter((asset: string) => asset.split(" ")[0] === rowIntrinsic.asset.split(" ")[0]):
                this.assetsIntrinsicLT?.filter((asset: string) => asset.split(" ")[0] === rowIntrinsic.asset.split(" ")[0]);
              assetsToUpdate?.forEach((assetToUpdate: string) => {
                const rowToUpdate = this.dataSourceIntrinsic.data.find((rowToUpdate: IDeltaTableRow) => rowToUpdate.asset === assetToUpdate);
                if (rowToUpdate) {
                  rowToUpdate['valid1'] = run.valid;
                  rowToUpdate.version1 = run.version;
                }
              })
            }));
          validateArrIntrinsic$.push(validate$);
        }
      });
      validateArr$ = validateArr$.concat(validateArrIntrinsic$);
    }
    let validateArrExtrinsic$: Observable<IRun>[] = [];
    if (markedExtrinsic && markedExtrinsic.length > 0) {
      markedExtrinsic.forEach((rowExtrinsic: IDeltaTableRow) => {
        if (rowExtrinsic['markedRunId1'] && rowExtrinsic.runid1) {
          const validate$ = this._runloaderService.putValid$(rowExtrinsic.runid1, {valid: 'false', reason:'Rollback'})
            .pipe(tap((run: IRun) => {
              const assetsToUpdate = this.listTypeSelected.name === 'ST' ?
                this.assetsExtrinsicST?.filter((asset: string) => asset.split(" ")[0] === rowExtrinsic.asset.split(" ")[0]) :
                this.assetsExtrinsicST?.filter((asset: string) => asset.split(" ")[0] === rowExtrinsic.asset.split(" ")[0]);
              assetsToUpdate?.forEach((assetToUpdate: string) => {
                const rowToUpdate = this.dataSourceExtrinsic.data.find((rowToUpdate: IDeltaTableRow) => rowToUpdate.asset === assetToUpdate);
                if (rowToUpdate) {
                  rowToUpdate['valid1'] = run.valid;
                  rowToUpdate.version1 = run.version;
                }
              })
            }));
          validateArrExtrinsic$.push(validate$);
        }
      });
      validateArr$ = validateArr$.concat(validateArrExtrinsic$);
    }

    merge(...validateArr$)
      .pipe(takeUntil(this._unsubscribeS$))
      .subscribe({
        complete: () => {
          markedIntrinsic.forEach((rowIntrinsic: IDeltaTableRow) => {
            const assetRunsIntrinsicSameCob = this._allRuns.filter((run:IRun) => run.asset === rowIntrinsic.asset && !run.stoch && +run.cob === +rowIntrinsic.cob2! && run.runId !== rowIntrinsic.runid1);
            const assetRunsIntrinsicSameCobLastVersionsSet = assetRunsIntrinsicSameCob.filter((run:IRun, index: number) => assetRunsIntrinsicSameCob[index -1] ? run.runId !== assetRunsIntrinsicSameCob[index -1].runId : true);
            const indexRunId2 = assetRunsIntrinsicSameCobLastVersionsSet.findIndex((run: IRun) => run.runId === rowIntrinsic.runid2);
            const officialNotLast2 = assetRunsIntrinsicSameCobLastVersionsSet.slice(0, indexRunId2).find((run: IRun) => run.valid || run.valid === null || !run.hasOwnProperty('valid')) ? true : false;

            const runId2 = rowIntrinsic.runid2;
            const cob2 = rowIntrinsic.cob2;
            const version2 = rowIntrinsic.version2;
            // const officialNotLast2 = rowIntrinsic.run2OfficialNotLast;
            rowIntrinsic.runid2 = rowIntrinsic.runid1;
            rowIntrinsic.cob2 = rowIntrinsic.cob1;
            rowIntrinsic.version2 = rowIntrinsic.version1;
            //rowIntrinsic.run2OfficialNotLast = rowIntrinsic.run1OfficialNotLast;
            rowIntrinsic.runid1 = runId2;
            rowIntrinsic.cob1 = cob2;
            rowIntrinsic.version1 = version2;
            rowIntrinsic.official1 = true;
            rowIntrinsic.valid1 = true;
            rowIntrinsic.run1OfficialNotLast = officialNotLast2;
          });
          markedExtrinsic.forEach((rowExtrinsic: IDeltaTableRow) => {
            const assetRunsExtrinsicSameCob = this._allRuns.filter((run:IRun) => run.asset === rowExtrinsic.asset && run.stoch && +run.cob === +rowExtrinsic.cob2! && run.runId !== rowExtrinsic.runid1);
            const assetRunsExtrinsicSameCobLastVersionsSet = assetRunsExtrinsicSameCob.filter((run:IRun, index: number) => assetRunsExtrinsicSameCob[index -1] ? run.runId !== assetRunsExtrinsicSameCob[index -1].runId : true);
            const indexRunId2 = assetRunsExtrinsicSameCobLastVersionsSet.findIndex((run: IRun) => run.runId === rowExtrinsic.runid2);
            const officialNotLast2 = assetRunsExtrinsicSameCobLastVersionsSet.slice(0, indexRunId2).find((run: IRun) => run.valid || run.valid === null || !run.hasOwnProperty('valid')) ? true : false;

            const runId2 = rowExtrinsic.runid2;
            const cob2 = rowExtrinsic.cob2;
            const version2 = rowExtrinsic.version2;
            // const officialNotLast2 = rowExtrinsic.run2OfficialNotLast;
            rowExtrinsic.runid2 = rowExtrinsic.runid1;
            rowExtrinsic.cob2 = rowExtrinsic.cob1;
            rowExtrinsic.version2 = rowExtrinsic.version1;
            // rowExtrinsic.run2OfficialNotLast = rowExtrinsic.run1OfficialNotLast;
            rowExtrinsic.runid1 = runId2;
            rowExtrinsic.cob1 = cob2;
            rowExtrinsic.version1 = version2;
            rowExtrinsic.official1 = true;
            rowExtrinsic.valid1 = true;
            rowExtrinsic.run1OfficialNotLast = officialNotLast2;
          });
          this._updateDeltaChecks(markedIntrinsic, markedExtrinsic);
        }
      });
  }

  copyLR1OnLR2(): void {
    const markedIntrinsic = this.dataSourceIntrinsic.data.filter((row: IDeltaTableRow) => row['markedRunId1']);
    const markedExtrinsic = this.dataSourceExtrinsic.data.filter((row: IDeltaTableRow) => row['markedRunId1']);
    markedIntrinsic.forEach((rowIntrinsic: IDeltaTableRow) => {
      rowIntrinsic.runid2 = rowIntrinsic.runid1;
      rowIntrinsic.version2 = rowIntrinsic.version1;
      rowIntrinsic.cob2 = rowIntrinsic.cob1;
    });
    markedExtrinsic.forEach((rowExtrinsic: IDeltaTableRow) => {
      rowExtrinsic.runid2 = rowExtrinsic.runid1;
      rowExtrinsic.version2 = rowExtrinsic.version1;
      rowExtrinsic.cob2 = rowExtrinsic.cob1;
    });
    this._updateDeltaChecks(markedIntrinsic, markedExtrinsic);
  }

  private _updateDeltaChecks(tableRowsIntrinsic: IDeltaTableRow[], tableRowsExtrinsic: IDeltaTableRow[]): void {
    let checksArr$: Observable<ICheckResponse>[] = [];
    if (tableRowsIntrinsic && tableRowsIntrinsic.length > 0) {
      let checksArrIntrinsic$: Observable<ICheckResponse>[] = [];
      tableRowsIntrinsic.forEach((tableRow: IDeltaTableRow) => {
        // remove previous checks for the asset that we're performing checks again
        this.checkResponsesIntrinsic = this.checkResponsesIntrinsic.filter((check: ICheckResponse) => check.metadata.asset !== tableRow.asset);
        for(let i = 1; i <= INTRINSIC_CHECKS_TOTAL; i++) {
          const check$ = this._validationService.getCheck$(i, tableRow.runid1!, tableRow.runid2!, tableRow.asset, false)
            .pipe(
              tap((check: ICheckResponse) => {
                // adding deltas to the table, which is contained by check no. 1
                if (check.metadata.check.number === 1) {
                  const dataSourceRow = this.dataSourceIntrinsic.data.find((row: IDeltaTableRow) => row.asset === tableRow.asset);
                  if (dataSourceRow) {
                    Object.entries(check.delta).forEach((entry: [string, string | number]) => {
                      const foundColumn = this.displayedColumns1.find((column: string) => column === entry[0]);
                      if (foundColumn) {
                        dataSourceRow[foundColumn] = entry[1];
                      }
                    });
                  }
                }
                this.checkResponsesIntrinsic.push(check);
              })
            );
          checksArrIntrinsic$.push(check$);
        }
      });
      checksArr$ = checksArr$.concat(checksArrIntrinsic$);
    }
    if (tableRowsExtrinsic && tableRowsExtrinsic.length > 0) {
      let checksArrExtrinsic$: Observable<ICheckResponse>[] = [];
      tableRowsExtrinsic.forEach((tableRow: IDeltaTableRow) => {
        // remove previous checks for the asset that we're performing checks again
        this.checkResponsesExtrinsic = this.checkResponsesExtrinsic.filter((check: ICheckResponse) => check.metadata.asset !== tableRow.asset);
        for(let i = 1; i <= EXTRINSIC_CHECKS_TOTAL; i++) {
          const check$ = this._validationService.getCheck$(i, tableRow.runid1!, tableRow.runid2!, tableRow.asset, true)
            .pipe(
              tap((check: ICheckResponse) => {
                // adding deltas to the table, which is contained by check no. 1
                if (check.metadata.check.number === 1) {
                  const dataSourceRow = this.dataSourceExtrinsic.data.find((row: IDeltaTableRow) => row.asset === tableRow.asset);
                  if (dataSourceRow) {
                    Object.entries(check.delta).forEach((entry: [string, string | number]) => {
                      const foundColumn = this.displayedColumns2.find((column: string) => column === entry[0]);
                      if (foundColumn) {
                        dataSourceRow[foundColumn] = entry[1];
                      }
                    });
                  }
                }
                this.checkResponsesExtrinsic.push(check);
              })
            );
          checksArrExtrinsic$.push(check$);
        }
      });
      checksArr$ = checksArr$.concat(checksArrExtrinsic$);
    }
    merge(...checksArr$).pipe(takeUntil(this._unsubscribeS$))
      .subscribe({
        next: () => this._cdRef.markForCheck(),
        error: () => this._cdRef.markForCheck(),
        complete: () => {
          this._tsvpeService.updateReportPageSnapshot({
            mainCob: this.dateControl.value?.toString(),
            assetsIntrinsicST: this.assetsIntrinsicST,
            assetsIntrinsicLT: this.assetsIntrinsicLT,
            assetsExtrinsicST: this.assetsExtrinsicST,
            assetsExtrinsicLT: this.assetsExtrinsicLT,
            dataSourceIntrinsic: this.dataSourceIntrinsic.data,
            dataSourceExtrinsic: this.dataSourceExtrinsic.data,
            checkResponsesIntrinsic: this.checkResponsesIntrinsic,
            checkResponsesExtrinsic: this.checkResponsesExtrinsic,
            displayedColumns1: this.displayedColumns1,
            displayedColumns2: this.displayedColumns2,
            comment: this.commentControl.value ? this.commentControl.value : '',
            horizon: this.listTypeSelected ? this.listTypeSelected.name : 'ST'
          });
          this._cdRef.markForCheck();
        }
      });
  }

  private _updateQueryProgressBar(): void {
    this.finishedQueries++;
    this.queryProgress = (this.finishedQueries / this.totalQueries) * 100;
    this._cdRef.markForCheck();
  }

  private _resetQueryProgressBar(): void {
    this.finishedQueries = 0;
    this.totalQueries = 0;
    this.queryProgress = 0;
    this._cdRef.markForCheck();
  }

  selectAll(): void {
    if (this.runId1Selected) {
      if (this.runListTypeSelected.id === 1) {
        this.dataSourceIntrinsic.data.forEach((rowIntrinsic: IDeltaTableRow) => rowIntrinsic['markedRunId1'] = false);
      } else {
        this.dataSourceExtrinsic.data.forEach((rowExtrinsic: IDeltaTableRow) => rowExtrinsic['markedRunId1'] = false);
      }
    } else {
      if (this.runListTypeSelected.id === 1) {
        this.dataSourceIntrinsic.data.forEach((rowIntrinsic: IDeltaTableRow) => rowIntrinsic['markedRunId1'] = true);
      } else {
        this.dataSourceExtrinsic.data.forEach((rowExtrinsic: IDeltaTableRow) => rowExtrinsic['markedRunId1'] = true);
      }
    }
  }

  ngAfterViewInit(): void {
    this._startNotificationFlow();
    this._initRunStatusWSConn();
  }

  ngOnDestroy(): void {
    this._notifService.stopRecordingLogs();
    this._unsubscribeS$.next(null);
    this._unsubscribeS$.complete();
    this._loadingBS$.complete();
    // this._murexExpService.stopStompConn();
  }
}
