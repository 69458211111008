<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  Change runs for {{data.rowSelected.asset}}
</h1>

<div class="legend">
    <span>
      Drag and drop title to move this dialog.
    </span>
</div>

<div mat-dialog-content>
  <!--<div class="pair-cobs">
    <mat-form-field>
      <mat-label>Cob 1</mat-label>
      <mat-select [formControl]="cob1Control" name="cob1" [compareWith]="compareCobs">
        <mat-option *ngFor="let cobRun of data.allRuns" [value]="cobRun">
          {{cobRun.cob}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    &nbsp;&nbsp;
    <mat-form-field>
      <mat-label>Cob 2</mat-label>
      <mat-select [formControl]="cob2Control" name="cob2" [compareWith]="compareCobs">
        <mat-option *ngFor="let cobRun of data.allRuns" [value]="cobRun"
          [disabled]="(+cobRun.cob > +this.cob1Control.value?.cob!)"
          (click)="checkCob2Validity(cobRun)">
          {{cobRun.cob}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>-->
  <div class="pair-runs">
    <mat-form-field>
      <mat-label>Run 1</mat-label>
      <mat-select [formControl]="run1Control" name="run1" disableOptionCentering panelClass="underneath">
        <mat-option *ngFor="let run of runs1" [value]="run">
          cob: {{run.cob}} | run: {{run.runId}} | v.{{run.version}} | valid: {{(!run.hasOwnProperty('valid') || run.valid === null) ? 'NOTSET' : run.valid}} | official: {{run.official}} | {{run.timestamp}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    &nbsp;&nbsp;
    <mat-form-field>
      <mat-label>Run 2</mat-label>
      <mat-select [formControl]="run2Control" name="run2" disableOptionCentering panelClass="underneath">
        <mat-option *ngFor="let run of runs2" [value]="run"
                    [disabled]="run1Control.value && ((+run.cob > +run1Control.value.cob!) || (+run.runId === +run1Control.value.runId!))"
                    (click)="checkCob2Validity(run)">
          cob: {{run.cob}} | run: {{run.runId}} | v.{{run.version}} | valid: {{(!run.hasOwnProperty('valid') || run.valid === null) ? 'NOTSET' : run.valid}} | official: {{run.official}} | {{run.timestamp}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>

<div mat-dialog-actions>
  <button mat-button class="alpq-btn-secondary" (click)="onNoClick()">
    Cancel
  </button>
  <button mat-button class="alpq-btn-primary" [disabled]="!run1Control.value && !run2Control.value" (click)="onSaveClick()">
    Save
  </button>
</div>
