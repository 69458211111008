import {Inject, Injectable} from '@angular/core';
import {HttpErrorResponse} from "@angular/common/http";
import {MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBar, MatSnackBarRef} from "@angular/material/snack-bar";

import {LibNotificationComponent, NotificationMessageTypeEnum} from "./lib-notification.component";


@Injectable({
  providedIn: 'root'
})
export class LibNotificationService {
  // @ts-ignore
  snackBarRef: MatSnackBarRef<LibNotificationComponent>;
  private _notifList: string[] = [];
  private _httpErrorList: HttpErrorResponse[] = [];
  private _notifRecorderSwitch = false;

  get notifList(): string[] {
    return this._notifList;
  }

  get httpErrorList(): HttpErrorResponse[] {
    return this._httpErrorList;
  }

  get notifRecorderSwitch(): boolean {
    return this._notifRecorderSwitch;
  }

  constructor(private readonly snackBar: MatSnackBar,
              @Inject(MAT_SNACK_BAR_DEFAULT_OPTIONS) public defaultOptions: any) { }

  private _openNotification(msg: string, type?: NotificationMessageTypeEnum, action?: NotificationAction) {
    this.snackBarRef = this.snackBar.openFromComponent(LibNotificationComponent, {
      duration: action ? 60000 : this.defaultOptions.duration,
      data: {
        message: {
          text: msg,
          type: type,
        },
        action: action
      },
      // @ts-ignore
      panelClass: [type]
    });
  }

  info(msg: string) {
    this._openNotification(msg, NotificationMessageTypeEnum.INFO);
  }

  success(msg: string, action?: NotificationAction) {
    this._openNotification(msg, NotificationMessageTypeEnum.SUCCESS, action);
  }

  error(msg: string) {
    this._openNotification(msg, NotificationMessageTypeEnum.ERROR);
  }

  warn(msg: string){
    this._openNotification(msg, NotificationMessageTypeEnum.WARN);
  }

  // ----------------------------------------------------------------------------

  addNotifToList(newNotif: string): void {
    if (this.notifRecorderSwitch) {
      this._notifList.push(newNotif);
    }
  }

  addHttpErrorToList(newHttpError: HttpErrorResponse): void {
    if (this.notifRecorderSwitch) {
      this._httpErrorList.push(newHttpError);
    }
  }

  updateNotifList(newNotifList: string[]): void {
    this._notifList = newNotifList;
  }

  clearNotifList(): void {
    this._notifList = [];
  }

  clearHttpErrorList(): void {
    this._httpErrorList = [];
  }

  startRecordingLogs(): void {
    this._notifRecorderSwitch = true;
  }

  stopRecordingLogs(): void {
    this._notifRecorderSwitch = false;
  }
}
