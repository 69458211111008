import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-lib-storage-client',
  template: `
    <p>
      lib-storage-client works!
    </p>
  `,
  styles: [
  ]
})
export class LibStorageClientComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
