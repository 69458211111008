import {Inject, Injectable} from '@angular/core';
import {WebSocketSubject} from "rxjs/webSocket";

import {Client, IMessage, Message} from '@stomp/stompjs';
import * as StompJs from '@stomp/stompjs';

import {LibApiService} from "../lib-api.service";
import {BehaviorSubject, Observable} from "rxjs";


export interface IMurexExpMessage {
  runId: number;
}

export const NOTIFICATION_SUBSCRIPTION_PROTOCOL = 'graphql-transport-ws';

@Injectable({
  providedIn: 'root'
})
export class MurexExpService {
  private _messageStreamMurexExpBS$ = new BehaviorSubject<IMurexExpMessage | null>(null);

  get messageStreamMurexExpBS$(): Observable<IMurexExpMessage | null> {
    return this._messageStreamMurexExpBS$.asObservable();
  }

  private _publishToMessageStreamMurexExpBS(message: IMurexExpMessage): void {
    this._messageStreamMurexExpBS$.next(message);
  }

  public client = new StompJs.Client({
    brokerURL: 'ws://alpschapp1vp.argon.corp.ch:15674/ws',
    connectHeaders: {
      login: 'copt_user',
      passcode: '1234',
    },
    onConnect: () => {
      // console.log('onConnect');

      this.client.subscribe('/topic/exported.murex', (message: IMessage) => {
        // console.log(message);
        // console.log(message.body);
        message.body ? this._publishToMessageStreamMurexExpBS(JSON.parse(message.body) as IMurexExpMessage) : null;
      })
    },
    onStompError: (frame: any) => {
      console.log('Broker reported error: ' + frame.headers['message']);
      console.log('Additional details: ' + frame.body);
    },
    debug: function (str: any) {
      // console.log(new Date(), str);
    },
    reconnectDelay: 5000,
    heartbeatIncoming: 10000,
    heartbeatOutgoing: 10000,
  });

  constructor(private _apiService: LibApiService,
              @Inject('env') private readonly _env: any) { }

  initNotificationSubscription$(): WebSocketSubject<any> {
    let env = '';
    switch (this._env.env) {
      case 'local': {
        env = 'dev.';
        break;
      }
      case 'dev': {
        env = 'dev.';
        break;
      }
      case 'test': {
        env = 'test.';
        break;
      }
      case 'int': {
        env = 'int.';
        break;
      }
      case 'prod': {
        env = '';
        break;
      }
      default: break;
    }
    return this._apiService.initWebSocketConnection$(
      'wss://dataservices-api.' + env + 'metis.aws.alpiq.services/subscriptions',
      NOTIFICATION_SUBSCRIPTION_PROTOCOL
    );
  }

  initWSRunStatus$(): WebSocketSubject<any> {
    let env = '';
    switch (this._env.env) {
      case 'local': {
        env = 'dev.';
        break;
      }
      case 'dev': {
        env = 'dev.';
        break;
      }
      case 'test': {
        env = 'test.';
        break;
      }
      case 'int': {
        env = 'int.';
        break;
      }
      case 'prod': {
        env = '';
        break;
      }
      default: break;
    }
    return this._apiService.initWebSocketConnection$(
      'wss://runloader.' + env + 'tsvp.alpiq.services/wsapp'
    );
  }

  initStompConn(): void {
    this.client.activate();
  }

  stopStompConn(): void {
    this.client.deactivate();
  }

}
