import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from "@angular/router";
import {AbstractControl, FormControl, FormGroup, Validators} from "@angular/forms";
import {Subject} from "rxjs";
import {takeUntil, tap} from "rxjs/operators";

import {LibStorageClientService} from "@alpq/lib-storage-client";
import {LibLanguageService} from "@alpq/lib-language";

import {SigninService} from "./signin.service";
import {LibAuthenticationService} from "./../lib-authentication.service";


@Component({
  selector: 'lib-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SigninComponent implements OnInit, OnDestroy {
  private _unsubS$: Subject<any> = new Subject<any>();

  usernameControl = new FormControl(null, [Validators.required, Validators.minLength(7)]);
  passwordControl = new FormControl(null, [Validators.required, Validators.minLength(7)]);

  signInFormGroup = new FormGroup({
    username: this.usernameControl,
    password: this.passwordControl,
  });

  get username(): AbstractControl { return <AbstractControl>this.signInFormGroup.get('username'); }
  get password(): AbstractControl { return <AbstractControl>this.signInFormGroup.get('password'); }

  private _getError(selector: AbstractControl, msg: string) {
    return selector.hasError('required') ? msg :
      selector.hasError('minlength') ? 'Too short' :
        selector.hasError('maxlength') ? 'Too long' :
          selector.hasError('pattern') ? 'Wrong format' : '';
  }

  getUsernameErrorMessage(): string {
    return this._getError(this.username, 'Please fill the username');
  }

  getPasswordErrorMessage(): string {
    return this._getError(this.password, 'Please fill the password');
  }

  private _appTitle: string = this._env.appTitle;

  get appTitle(): string {
    return this._appTitle;
  }

  set appTitle(value: string) {
    this._appTitle = value;
  }

  get isInternal(): boolean {
    return this._env.internal;
  }

  constructor(private _signinService: SigninService,
              private _authenticationService: LibAuthenticationService,
              private _storageClientService: LibStorageClientService,
              private _router: Router,
              private _activatedRoute: ActivatedRoute,
              public languageService: LibLanguageService,
              private _cdRef : ChangeDetectorRef,
              @Inject('env') private readonly _env: any) { }

  ngOnInit(): void {
    this.languageService.language$().pipe(takeUntil(this._unsubS$),tap(() => this._cdRef.markForCheck())).subscribe();
    this._watchRouteParams();
  }

  ngOnDestroy(): void {
    this._unsubS$.next(null);
    this._unsubS$.complete();
  }

  private _watchRouteParams() {
    this._activatedRoute.queryParams
      .pipe(
        takeUntil(this._unsubS$),
        tap((params: Params) => {
          if(!this._authenticationService.isAuthenticated) {
            if(this._env.internal) {
              if(Object.keys(params).length === 0) {
                window.location.href = this._env.authUrl + this._env.apiBaseUrl;
              } else {
                this._storageClientService.token = params['alpiqio_auth'];
                const expDate = JSON.parse(window.atob(params['alpiqio_auth']?.split('.')[1]))?.exp;
                this._storageClientService.exp = expDate;
                const userId = JSON.parse(window.atob(params['alpiqio_auth']?.split('.')[1]))?.userid;
                this._storageClientService.userId = userId;
                this._router.navigate([this._env.homePage]);
              }
            }
          } else {
            this._router.navigate([this._env.homePage]);
          }
        })
      )
      .subscribe();
  }

  signIn() {
    this._signinService.signIn$({username: this.username.value, password: this.password.value})
      .subscribe();
  }

}
