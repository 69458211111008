import {ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit} from "@angular/core";
import {Subject} from "rxjs";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

import {ICheckResponse, VALIDATION_URL, HOST_PUBLIC_PLATFORM, PROJECT} from "@alpq/lib-api";

import {IDeltaTableRow} from "./../../lib-tsvpe.service";

@Component({
  selector: 'lib-dialog-checks',
  templateUrl: './dialog-checks.component.html',
  styleUrls: ['./dialog-checks.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogChecksComponent implements OnInit, OnDestroy {
  private readonly _unsubscribe: Subject<any> = new Subject<any>();

  displayedColumns: string[] = ['no', 'status', 'url'];

  constructor(public dialogRef: MatDialogRef<DialogChecksComponent>,
              @Inject(MAT_DIALOG_DATA) public data: {rowSelected: IDeltaTableRow; checks: ICheckResponse[]},
              @Inject('env') private readonly _env: any) { }


  ngOnInit(): void {
    this._enrichChecks();
  }

  private _enrichChecks(): void {
    this.data.checks.sort((a: ICheckResponse, b: ICheckResponse) => a.metadata.check.number - b.metadata.check.number);
    this.data.checks.forEach((check: ICheckResponse) => {
      check.url = 'https://' + VALIDATION_URL +
        (this._env.production ? '' : this._env.env === 'local' ? '.dev' : '.' + this._env.env) +
        PROJECT + HOST_PUBLIC_PLATFORM +
        '/reports/checks' + `/${check.metadata.check.number}` + `/${check.metadata.run1.runid}` + `/${check.metadata.run2.runid}`
        + `/${check.metadata.asset}` + `?stoch=${this.data.rowSelected.stoch}`;
    });
  }

  ngOnDestroy(): void {
    this._unsubscribe.next(null);
    this._unsubscribe.complete();
  }
}
