import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl} from "@angular/forms";
import {MatTableDataSource} from "@angular/material/table";

import {BehaviorSubject, concat, EMPTY, filter, merge, Observable, Subject, switchMap, takeUntil, tap} from "rxjs";

import moment from 'moment';

import {IAssetResponse, IRun, RunloaderService, ValidationService} from "@alpq/lib-api";

import {ICockpitPageSnapshot, ICockpitTableRow, LibTsvpeService} from "./../lib-tsvpe.service";


export interface IListType {
  id: number;
  name: string;
}


@Component({
  selector: 'lib-cockpit',
  templateUrl: './cockpit.component.html',
  styleUrls: ['./cockpit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CockpitComponent implements OnInit, OnDestroy {
  private readonly _unsubscribeS$: Subject<any> = new Subject<any>();
  private readonly _loadingBS$ = new BehaviorSubject<boolean>(false);
  public loading$ = this._loadingBS$.asObservable();

  private _assets: {
    intrinsic?: string[] | undefined,
    extrinsic?: string[] | undefined,
    intrinsic_ST: string[] | undefined,
    intrinsic_LT: string[] | undefined,
    extrinsic_ST: string[] | undefined,
    extrinsic_LT: string[] | undefined};
  private _assetsIntrinsic: string[] | undefined;
  private _assetsExtrinsic: string[] | undefined;

  private _displayedColumns: string[] = ['runId', 'asset', 'timestamp', 'valid', 'reason', 'mxImport', 'eodReport', 'hourlyRiskReport', 'comment'];

  get displayedColumns(): string[] {
    return this._displayedColumns;
  }

  set displayedColumns(value: string[]) {
    this._displayedColumns = value;
  }

  tableRows: ICockpitTableRow[] = [];
  dataSource = new MatTableDataSource<ICockpitTableRow>();

  today = moment();
  dateControl = new FormControl(moment().startOf('day').toDate(), []);

  readonly LIST_TYPES: IListType[] = [
    { id: 1, name: 'ST'},
    { id: 2, name: 'LT'}
  ];
  listTypeSelected: IListType = this.LIST_TYPES[0];
  readonly RUN_LIST_TYPES: IListType[] = [
    { id: 1, name: 'Intrinsic'},
    { id: 2, name: 'Extrinsic'}
  ];
  runListTypeSelected: IListType = this.RUN_LIST_TYPES[0];

  constructor(private _validationService: ValidationService,
              private _runloaderService: RunloaderService,
              private _tsvpeService: LibTsvpeService,
              private _cdRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this._tsvpeService.cockpitPageSnapshotBS$
      .pipe(/*takeUntil(this._unsubscribeS$),*/
        tap((pageSnapshot: ICockpitPageSnapshot) => {
          if (pageSnapshot && pageSnapshot.mainCob &&
            pageSnapshot.horizon && pageSnapshot.assets && pageSnapshot.dataSource) {
            this.dateControl.patchValue(new Date(pageSnapshot.mainCob));
            this.listTypeSelected = pageSnapshot.horizon ? this.LIST_TYPES.find((listType: IListType) => listType.name === pageSnapshot.horizon)! : this.LIST_TYPES[0];
            this.runListTypeSelected = pageSnapshot.runType ? this.RUN_LIST_TYPES.find((runType: IListType) => runType.name === pageSnapshot.runType)! : this.RUN_LIST_TYPES[0];
            this._assets = pageSnapshot.assets;
            this.dataSource.data = pageSnapshot.dataSource;
          }
        }))
      .subscribe();
  }

  load(): void {
    this._loadingBS$.next(true);
    this._validationService.getAssets$()
      .pipe(/*takeUntil(this._unsubscribeS$),*/
        tap((assetResponse: IAssetResponse) => {
          this._assets = assetResponse;
          this._assetsIntrinsic = this.listTypeSelected.name === 'ST' ? assetResponse.intrinsic_ST : assetResponse.intrinsic_LT;
          this._assetsExtrinsic = this.listTypeSelected.name === 'ST' ? assetResponse.extrinsic_ST: assetResponse.extrinsic_LT;
        }),
        switchMap(() => {
          const assets = this.runListTypeSelected.id === 1 ? this._assetsIntrinsic : this._assetsExtrinsic;
          if (this.dateControl.value && this._assets && assets && assets.length > 0) {
            return this._getAssetInfo(this.dateControl.value);
          } else {
            return EMPTY;
          }
        }))
      .subscribe({
        next: () => {}, error: () => {},
        complete: () => {
          this._loadingBS$.next(false);
          this.dataSource.data = this.tableRows;
          this._cdRef.markForCheck();
          this._tsvpeService.updateCockpitPageSnapshot({
            mainCob: this.dateControl.value?.toString(),
            horizon: this.listTypeSelected ? this.listTypeSelected.name : 'ST',
            runType: this.runListTypeSelected ? this.runListTypeSelected.name : 'Intrinsic',
            assets: this._assets,
            dataSource: this.dataSource.data
          });
        }
      });
  }

  private _getAssetInfo(date: Date): Observable<IRun[]> {
    this.tableRows = [];
    this.dataSource.data = [];
    const lastRunsArr$: Observable<IRun[]>[] = [];
    const bigCob = moment(date).format('YYYYMMDD');
    const assets = this.runListTypeSelected.id === 1 ? this._assetsIntrinsic : this._assetsExtrinsic;
    assets?.forEach((asset: string) => {
      // const lastRun$ = this._runloaderService.getLastRuns$({
      const lastRun$ = this._runloaderService.getClosestRuns$({
        asset: asset.split(" ")[0],
        databook: this.listTypeSelected.name === 'ST' ? 'Optimisation' : 'OptimisationLT',
        cob: bigCob,
        stoch: this.runListTypeSelected.id === 1 ? 'false' : 'true',
        // limit: '10'
      }).pipe(tap((lastRuns: IRun[]) => {
        const lastRun = lastRuns[0];
        let mxImportRunVersion;
        let eodReportRunVersion;
        let hourlyRiskReportRunVersion;
        let tableRow: ICockpitTableRow;
        if (lastRun) {
          const lastRunId = lastRun.runId;
          const allRunVersions = lastRuns.filter((run: IRun) => run.runId === lastRunId);
          mxImportRunVersion = allRunVersions.find((run: IRun) => run.export && run.export.system === 'Murex');
          eodReportRunVersion = allRunVersions.find((run: IRun) => run.export && run.export.system === 'EODreport ');
          hourlyRiskReportRunVersion = allRunVersions.find((run: IRun) => run.export && run.export.system === 'HRreport');

          tableRow = {
            asset: asset,
            runId: lastRun.runId,
            timestamp: lastRun.timestamp ? moment(lastRun.timestamp).format('DD.MM.YYYY HH:mm') : '',
            valid: lastRun.valid === null ? '' : lastRun.valid ? 'Yes' : 'No',
            reason: lastRun.reason ? lastRun.reason : '',
            mxImport: mxImportRunVersion && mxImportRunVersion.export!.sent ? moment(mxImportRunVersion.export!.sent).format('DD.MM.YYYY HH:mm') : '',
            eodReport: eodReportRunVersion && eodReportRunVersion.export!.sent ? moment(eodReportRunVersion.export!.sent).format('DD.MM.YYYY HH:mm') : '',
            hourlyRiskReport: hourlyRiskReportRunVersion && hourlyRiskReportRunVersion.export!.sent ? moment(hourlyRiskReportRunVersion.export!.sent).format('DD.MM.YYYY HH:mm') : '',
            comment: lastRun.comment ? lastRun.comment : ''
          };
        } else {
          tableRow = { asset: asset};
        }

        this.tableRows.push(tableRow);
      }));
      lastRunsArr$.push(lastRun$);
    });
    return concat(...lastRunsArr$).pipe(/*takeUntil(this._unsubscribeS$)*/);
  }

  onRowClick(tableRow: ICockpitTableRow) : void {
    if (tableRow.runId) {
      this._tsvpeService.updateRunIdState(tableRow.runId.toString());
      this._tsvpeService.changeTab(2);
    }
  }

  getNiceColumnHeader(columnId: string): string {
    return columnId.replace(/([a-z])([A-Z])/g, '$1 $2');
  }

  ngOnDestroy(): void {
    this._unsubscribeS$.next(null);
    this._unsubscribeS$.complete();
    this._loadingBS$.complete();
  }

  /*private _watchDate(): void {
      this.dateControl.valueChanges
        .pipe(takeUntil(this._unsubscribeS$),
          switchMap((newDate: Date | null) => {
            if (newDate && this._assets && this._assets.length > 0) {
              return this._getAssetInfo(newDate);
            } else {
              return EMPTY;
            }
          }))
        .subscribe({
          next: () => {
            this.dataSource.data = this.tableRows;
          }, error: () => {},
          complete: () => {}
        });
    }*/
  /*onListTypeChange(): void {
    this._getAssetInfo(this.dateControl.value!)
      .pipe(takeUntil(this._unsubscribeS$))
      .subscribe({
        next: () => {}, error: () => {},
        complete: () => {
          this.dataSource.data = this.tableRows;
        }
      });
  }*/
}
