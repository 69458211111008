import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from "rxjs/operators";

import {LibStorageClientService} from "@alpq/lib-storage-client";



@Injectable({
  providedIn: 'root'
})
export class ApiErrorInterceptor implements HttpInterceptor {

  constructor(private readonly _router: Router,
              private _storageClientService: LibStorageClientService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(tap((event: HttpEvent<any>) => { }, (err: any) => {

      if (err instanceof HttpErrorResponse) {
        // console.log(err);
        switch (err.status) {
          case 401:
            // "Unauthorized" => "unauthenticated" -> log out
            this._storageClientService.clearStorage();
            this._router.navigate(['signin']);
            break;
          case 403:
            // "Forbidden" but authenticated -> normal error message
            break;
          case 404:
            // not found
            break;
          case 500:
            break;
          case 200:
            break;
          default:
            break;
        }
        //TODO - log the error
      } else {
        // a client-side or network error occurred =>
        // => this should be managed by a global-error-handler
      }
    }));

  }
}
