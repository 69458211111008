import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, Validators} from "@angular/forms";
import {
  concat,
  EMPTY,
  merge,
  Observable,
  Subject,
  switchMap,
  takeUntil,
  tap
} from "rxjs";

import {IExportPayload, IRun, ISystem, RunloaderService} from "@alpq/lib-api";
import {LibNotificationService} from "@alpq/lib-notification";

import {LibTsvpeService} from "./../lib-tsvpe.service";


@Component({
  selector: 'lib-run',
  templateUrl: './run.component.html',
  styleUrls: ['./run.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RunComponent implements OnInit, OnDestroy {
  private readonly _unsubscribeS$: Subject<any> = new Subject<any>();

  runIdControl = new FormControl<number | null | undefined>(null, [Validators.pattern("^[0-9]*$")]);

  commentControl = new FormControl<string | null | undefined>(null);
  tagsControl = new FormControl<string | null | undefined>(null);
  toExportControl = new FormControl<boolean | null | undefined>(null);
  validControl = new FormControl<boolean | null | undefined>(null);
  reasonControl = new FormControl<string | null | undefined>(null);
  exportSystemControl = new FormControl<ISystem | null | undefined>(null);

  private _systems: ISystem[];

  get systems(): ISystem[] {
    return this._systems;
  }

  set systems(value: ISystem[]) {
    this._systems = value;
  }

  private _runs: IRun[];
  private _runCurr: IRun;
  private _runIndex: number;

  get runs(): IRun[] {
    return this._runs;
  }

  set runs(value: IRun[]) {
    this._runs = value;
  }

  get runCurr(): IRun {
    return this._runCurr;
  }

  set runCurr(value: IRun) {
    this._runCurr = value;
  }

  get runIndex(): number {
    return this._runIndex;
  }

  set runIndex(value: number) {
    this._runIndex = value;
  }

  private _changedExport = false;
  private _changedToExport = false;
  private _changedValid = false;
  private _changedAnnotate = false;

  get changedExport(): boolean {
    return this._changedExport;
  }

  set changedExport(value: boolean) {
    this._changedExport = value;
  }

  get changedToExport(): boolean {
    return this._changedToExport;
  }

  set changedToExport(value: boolean) {
    this._changedToExport = value;
  }

  get changedValid(): boolean {
    return this._changedValid;
  }

  set changedValid(value: boolean) {
    this._changedValid = value;
  }

  get changedAnnotate(): boolean {
    return this._changedAnnotate;
  }

  set changedAnnotate(value: boolean) {
    this._changedAnnotate = value;
  }

  readonly BOOLS = [true, false];

  constructor(private _runloaderService: RunloaderService,
              private _cdRef: ChangeDetectorRef,
              private _notifService: LibNotificationService,
              private _tsvpeService: LibTsvpeService) { }

  ngOnInit(): void {
    this._getSystems$()
      .pipe(
        takeUntil(this._unsubscribeS$),
        tap((systems: ISystem[]) => this.systems = systems)
      ).subscribe();
    if (this._tsvpeService.getRunIdState()) {
      this.runIdControl.patchValue(+this._tsvpeService.getRunIdState());
      this.selectRunId();
    }
    this._watchExport$()
      .pipe(takeUntil(this._unsubscribeS$),
        tap(() => this.changedExport = true))
      .subscribe();
    this._watchToExport$()
      .pipe(takeUntil(this._unsubscribeS$),
        tap(() => this.changedToExport = true))
      .subscribe();
    this._watchValid$()
      .pipe(takeUntil(this._unsubscribeS$),
        tap(() => {
          this.changedValid = true;
          if (!this.changedExport) this.exportSystemControl.reset(null, {emitEvent: false});
        }))
      .subscribe();
    this._watchAnnotate$()
      .pipe(takeUntil(this._unsubscribeS$),
        tap(() => {
          this.changedAnnotate = true;
          if (!this.changedExport) this.exportSystemControl.reset(null, {emitEvent: false});
        }))
      .subscribe();
  }

  private _getSystems$(): Observable<ISystem[]> {
    return this._runloaderService.getSystems$();
  }

  private _watchExport$(): Observable<ISystem | null | undefined> {
    return this.exportSystemControl.valueChanges;
  }

  private _watchToExport$(): Observable<boolean | null | undefined> {
    return this.toExportControl.valueChanges;
  }

  private _watchValid$(): Observable<any> {
    return merge(this.validControl.valueChanges, this.reasonControl.valueChanges);
  }

  private _watchAnnotate$(): Observable<any> {
    return merge(this.commentControl.valueChanges, this.tagsControl.valueChanges);
  }

  selectRunId(): void {
    if (!this.runIdControl.value) return;
    this._tsvpeService.updateRunIdState(this.runIdControl.value.toString());
    this._runloaderService.getRun$(this.runIdControl.value)
      .pipe(
        takeUntil(this._unsubscribeS$),
        tap((runs: IRun[]) => {
          this.runs = runs;
          this.runIndex = 0;
          this.runCurr = runs[this.runIndex];
          this._initInputs();
        })
      )
      .subscribe(() => this._cdRef.markForCheck());
  }

  private _initInputs(): void {
    this.commentControl.patchValue(this.runCurr.comment, {emitEvent: false});
    this.tagsControl.patchValue(this.runCurr.tags ? JSON.stringify(this.runCurr.tags) : null, {emitEvent: false});
    this.validControl.patchValue(this.runCurr.valid, {emitEvent: false});
    this.toExportControl.patchValue(this.runCurr.to_export, {emitEvent: false});
    this.reasonControl.patchValue(this.runCurr.reason, {emitEvent: false});
    this.exportSystemControl.patchValue(this.runCurr.export?.system ? this.systems?.find((system: ISystem) => system.name === this.runCurr.export?.system) : null, {emitEvent: false});
    this.changedValid = false; this.changedToExport = false; this.changedExport = false; this.changedAnnotate = false;
  }

  goLeft(): void {
    this.runIndex++;
    this.runCurr = this.runs[this.runIndex];
  }

  goRight(): void {
    this.runIndex--;
    this.runCurr = this.runs[this.runIndex];
  }

  selectR(): void {

  }

  save(): void {
    if(this.changedAnnotate) {
      try {
        JSON.parse(this.tagsControl.value!);
      } catch (e: any) {
        this._notifService.error('Cannot save. Tags must be a JSON object. ' + e.toString());
        return;
      }
    }
    if(this.changedValid) {
      if (!this.reasonControl.value) {
        this.reasonControl.setErrors({required: true});
        this._notifService.warn('Reason must be filled.');
        return;
      }
    }
    let toPush$: Observable<any> = merge(
      this.changedValid ? this._runloaderService.putValid$(this.runCurr.runId, {valid: this.validControl.value!.toString(), reason: this.reasonControl.value!}) : EMPTY,
      this.changedToExport ? this._runloaderService.putToExport$(this.runCurr.runId, {to_export: this.toExportControl.value!.toString()}) : EMPTY,
      this.changedExport ? this._runloaderService.postAddExport$(this.runCurr.runId, {system: (this.exportSystemControl.value as ISystem).name}) : EMPTY,
      this.changedAnnotate ? this._runloaderService.putAnnotate$(this.runCurr.runId, {comment: this.commentControl.value!, tags: JSON.parse(this.tagsControl.value!)}) : EMPTY);
    // first, verify if there is a new version created in meantime
    this._runloaderService.getRun$(this.runIdControl.value!)
      .pipe(
        takeUntil(this._unsubscribeS$),
        switchMap((runs: IRun[]) => {
          if (runs[0].version !== this.runs[0].version) {
            this._notifService.warn('There is a newer version created. Please refresh results.');
            return EMPTY;
          } else {
            const exportPayload: IExportPayload = {
              asset_runs: [
                {
                  run1: {asset: this.runCurr.asset, cob: this.runCurr.cob, runId: this.runCurr.runId},
                  run2: {asset: null, cob: null, runId: null}
                }]
            };
            return this.toExportControl.value ? concat(toPush$, this._runloaderService.export$('Murex', exportPayload)) : toPush$;
          }
        })
      )
      .subscribe((run: IRun) => {
        run ? this._notifService.success('Run successfully changed. Version ' + run.version + ' was created.') :
          this._notifService.success('Run successfully changed. A new version was created.');
        this.selectRunId();
      });
  }

  compareExportSystems(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.name === c2.name : c1 === c2;
  }

  selectExportSystem(): void {
    this._changedExport = true;
  }

  ngOnDestroy(): void {
    this._unsubscribeS$.next(null);
    this._unsubscribeS$.complete();
  }

}
