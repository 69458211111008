import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from "@angular/router";

import {LibAuthenticationService} from "./lib-authentication.service";


@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuardService implements CanActivate {
  constructor(
    public router: Router,
    public authenticationService: LibAuthenticationService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.authenticationService.isAuthenticated) {
      return true;
    }
    else {
      this.router.navigate(['signin']);
      return false;
    }
  }
}
