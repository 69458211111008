import {ModuleWithProviders, NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";

import {LibAuthenticationComponent} from "./lib-authentication.component";
import {SigninComponent} from "./signin/signin.component";
import {SignupComponent} from "./signup/signup.component";


export const authenticationRoutes: Routes = [
  {
    path: '', component: LibAuthenticationComponent, children: [
      {
        path: 'signin', component: SigninComponent, pathMatch: 'full'
      },
      {
        path: 'signup', component: SignupComponent, pathMatch: 'full'
      },
      {
        path: '', redirectTo: "signin", pathMatch: 'full'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(authenticationRoutes)],
  exports: [RouterModule]
})
export class AuthenticationRoutingModule {
  public static forRoot(environment: any): ModuleWithProviders<any> {

    return {
      ngModule: AuthenticationRoutingModule,
      providers: [
        {
          provide: 'env', // you can also use InjectionToken
          useValue: environment
        }
      ]
    };
  }
}
