import { NgModule } from '@angular/core';
import { LibStorageClientComponent } from './lib-storage-client.component';



@NgModule({
  declarations: [
    LibStorageClientComponent
  ],
  imports: [
  ],
  exports: [
    LibStorageClientComponent
  ]
})
export class LibStorageClientModule { }
