import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {tap} from "rxjs";

import {RunloaderService} from "@alpq/lib-api";

import {LibTsvpeService} from "./lib-tsvpe.service";


export interface ITab {
  index: number;
  name: string;
  selector: string;
}

@Component({
  selector: 'lib-tsvpe',
  templateUrl: './lib-tsvpe.component.html',
  styleUrls: ['./lib-tsvpe.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LibTsvpeComponent implements OnInit {
  tabs: ITab[] = [
    {
      index: 0,
      name: 'Reports',
      selector: 'lib-report'
    },
    {
      index: 1,
      name: 'EOD Check',
      selector: 'lib-eod'
    },
    {
      index: 2,
      name: 'Run',
      selector: 'lib-run'
    },
    {
      index: 3,
      name: 'Cockpit',
      selector: 'lib-cockpit'
    },
    {
      index: 4,
      name: 'Monthly Switch',
      selector: 'lib-monthly-switch'
    }
  ];
  private _selectedTab: ITab = this.tabs[0];

  get selectedTab(): ITab {
    return this._selectedTab;
  }

  set selectedTab(value: ITab) {
    this._selectedTab = value;
  }

  eodDisabled = true;

  constructor(private _runloaderService: RunloaderService,
              private _tsvpeService: LibTsvpeService,
              private _cdRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this._tsvpeService.tabChangeS$
      .pipe(
        tap((newTabIndex: number) => this.setTab(newTabIndex))
      )
      .subscribe();
    this._tsvpeService.getEodTabEnable$()
      .pipe(
        tap((enable: boolean) => this.eodDisabled = !enable)
      )
      .subscribe();
  }

  setTab(index: number): void {
    if (index <= this.tabs.length - 1 && index > 0) {
      this.selectedTab = {...this.tabs[index]};
    }
    // if ever loaded eod tab, then enable it
    if (index === 1) {
      this.eodDisabled = false;
    }
  }
}
