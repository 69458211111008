import {Inject, Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';


const ENV_LOCAL = 'local';
const ENV_DEV = '.dev';
const ENV_TEST = '.test';
const ENV_INT = '.int';
const ENV_PROD = '';
export const PROJECT = '.tsvp';
export const HOST_PUBLIC_PLATFORM = '.alpiq.services';

@Injectable({
  providedIn: 'root'
})
export class ApiEnvInterceptor implements HttpInterceptor {

  constructor(private readonly router: Router,
              @Inject('env') private readonly _env: any) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let apiUrl: string = '';

    let env = ENV_LOCAL;

    switch (this._env.env) {
      case 'dev': {
        env = ENV_DEV;
        break;
      }
      case 'test': {
        env = ENV_TEST;
        break;
      }
      case 'int': {
        env = ENV_INT;
        break;
      }
      case 'prod': {
        env = ENV_PROD;
        break;
      }
      default: break;
    }

    if (env !== ENV_LOCAL) {
      if (request.url.startsWith('runloader')) {
        apiUrl = 'https://runloader' + env + PROJECT + HOST_PUBLIC_PLATFORM
          + request.url.replace('runloader', '');
      } else if (request.url.startsWith('validation')) {
        apiUrl = 'https://validation' + env + PROJECT + HOST_PUBLIC_PLATFORM
          + request.url.replace('validation', '');
      } else if (request.url.startsWith('reporting')) {
        apiUrl = 'https://reporting' + env + PROJECT + HOST_PUBLIC_PLATFORM
          + request.url.replace('reporting', '');
      } else if (request.url.startsWith('user/login')) {
        apiUrl = this._env.authUrl;
      } else if (request.url.startsWith('user/roles')) {
        apiUrl = this._env.rolesUrl;
      }
    }

    const httpReq = request.clone({
      url: apiUrl
    });

    return next.handle(httpReq).pipe(tap((event: HttpEvent<any>) => {}, (err: any) => {}));
  }
}
