import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from "@angular/material/snack-bar";
import {NavigationExtras, Router, UrlTree} from "@angular/router";
import {timeout} from "rxjs/operators";
import {Observable} from "rxjs";


export enum NotificationMessageTypeEnum {
  SUCCESS = 'success',
  ERROR = 'error',
  WARN = 'warn',
  INFO = 'info'
}
export enum NotificationActionTypeEnum {
  FUNCTION,
  OBSERVABLE,
  NAVIGATION,
  CLOSE
}

export interface NotificationData {
  message: NotificationMessage;
  action?: NotificationAction;
  observer?: NotificationObserver;
}

export interface NotificationMessage {
  text: string;
  type?: NotificationMessageTypeEnum;
}

export interface NotificationAction {
  name: string;
  type: NotificationActionTypeEnum;
  function?: {
    fn: (...args: any[]) => void;
    args: any[];
  };
  observable?: Observable<any>;
  navigation?: {
    url: string | UrlTree;
    extras?: NavigationExtras;
  };
}

export interface NotificationObserver {
  observable: Observable<any>;
  successMsg: string;
  emptyMsg?: string;
  empty?: string|number;
  condition?: (string|number)[];
  limit?: (string|number)[];
  exception: (string|number)[];
  exceptionMsg: string;
}


@Component({
  selector: 'lib-notification',
  templateUrl: './lib-notification.component.html',
  styleUrls: ['./lib-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LibNotificationComponent implements OnInit {

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: NotificationData,
              private snackBarRef: MatSnackBarRef<LibNotificationComponent>,
              private router: Router) { }

  ngOnInit(): void {
  }

  action(): void {
    this.dismiss();
    switch (this.data.action?.type) {
      case NotificationActionTypeEnum.FUNCTION:
        this.data.action.function?.fn(...this.data.action.function.args);
        break;
      case NotificationActionTypeEnum.OBSERVABLE:
        this.data.action.observable?.pipe(timeout(30000)).subscribe();
        break;
      case NotificationActionTypeEnum.NAVIGATION:
        const extras : NavigationExtras = {
          ...this.data.action.navigation?.extras
        };
        if (!extras.queryParamsHandling) {
          extras.queryParamsHandling = "merge";
        }
        // @ts-ignore
        this.router.navigateByUrl(this.data.action.navigation.url, extras);
        break;
      case NotificationActionTypeEnum.CLOSE:
        this.snackBarRef.dismiss();
        break;
      default:
        break;
    }
  }

  dismiss(): void {
    this.snackBarRef.dismiss();
  }
}
