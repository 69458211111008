import {ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {Subject, takeUntil, tap} from "rxjs";

import {LibStorageClientService} from "@alpq/lib-storage-client";

import packageJson from './../../../../package.json';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, OnDestroy {
  private readonly _unsubscribeS$: Subject<any> = new Subject<any>();
  private _userName: string = '';


  get userName(): string {
    return this._userName;
  }

  set userName(value: string) {
    this._userName = value;
  }

  public appVersion: string = packageJson.version;


  constructor(private _storageClientService: LibStorageClientService,
              private _router: Router,
              @Inject('env') private readonly _env: any) {}

  ngOnInit(): void {
    this._handleUser();
    this._storageClientService.connectRefreshStorage()
      .pipe(takeUntil(this._unsubscribeS$), tap(() => this._handleUser()))
      .subscribe();
  }

  private _handleUser(): void {
    let name = '';
    if (this._storageClientService.userId) {
      const subs = this._storageClientService.userId.split('@')[0].split('.', 2);
      name = subs[0] + ' ' + subs[1];
    }
    this.userName = name;
  }

  goHome(): void {
    this._router.navigateByUrl(this._env.homePage);
  }

  ngOnDestroy(): void {
    this._unsubscribeS$.next(null);
    this._unsubscribeS$.complete();
  }
}
