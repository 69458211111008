import {ModuleWithProviders, NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {MatSnackBarModule} from "@angular/material/snack-bar";


import { LibApiComponent } from './lib-api.component';
import {ApiEnvInterceptor} from "./interceptors/api.env.interceptor";
import {ApiErrorInterceptor} from "./interceptors/api.error.interceptor";



@NgModule({
  declarations: [
    LibApiComponent
  ],
  imports: [
    HttpClientModule,
    MatSnackBarModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ApiEnvInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ApiErrorInterceptor, multi: true }
  ],
  exports: [
    LibApiComponent
  ]
})
export class LibApiModule {
  public static forRoot(environment: any): ModuleWithProviders<any> {

    return {
      ngModule: LibApiModule,
      providers: [
        {
          provide: 'env', // you can also use InjectionToken
          useValue: environment
        }
      ]
    };
  }
}
