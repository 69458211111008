import {ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormControl} from "@angular/forms";
import {filter, Subject, takeUntil, tap} from "rxjs";

import {IRun} from "@alpq/lib-api";
import {LibNotificationService} from "@alpq/lib-notification";

import {IDeltaTableRow} from "./../../lib-tsvpe.service";


@Component({
  selector: 'lib-dialog-change-run',
  templateUrl: './dialog-change-run.component.html',
  styleUrls: ['./dialog-change-run.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class DialogChangeRunComponent implements OnInit, OnDestroy  {
  private readonly _unsubscribe: Subject<any> = new Subject<any>();

  /*cob1Control = new FormControl<IRun | null | undefined>(null);
  cob2Control = new FormControl<IRun | null | undefined>(null);*/
  run1Control = new FormControl<IRun | null | undefined>(null);
  run2Control = new FormControl<IRun | null | undefined>(null);

  runs1: IRun[] = [];
  runs2: IRun[] = [];
  run1Index = 0;
  run2Index = 0;

  constructor(public dialogRef: MatDialogRef<DialogChangeRunComponent>,
              @Inject(MAT_DIALOG_DATA) public data: {rowSelected: IDeltaTableRow; allRuns: IRun[]},
              private _notificationService: LibNotificationService) { }

  ngOnInit(): void {
    this._initRuns();
  }

  private _initRuns(): void {
    this.runs1 = this.data.allRuns.filter((run: IRun) => run.runId !== this.data.rowSelected.runid1 && run.stoch === this.data.rowSelected.stoch)
      .filter((run: IRun, index: number, self: IRun[]) => index === self.findIndex((nextRun: IRun) => nextRun.runId === run.runId));
    this.runs2 = this.data.allRuns.filter((run: IRun) => run.official && run.valid && run.stoch === this.data.rowSelected.stoch)
      .filter((run: IRun, index: number, self: IRun[]) => index === self.findIndex((nextRun: IRun) => nextRun.runId === run.runId));
    this.run1Control.valueChanges
      .pipe(
        // @ts-ignore
        takeUntil(this._unsubscribe),
        tap((run: IRun) => {
          if(+run.cob < +this.run2Control.value?.cob!) {
            const lastOfficialRun = this.runs2.find((run2: IRun) => +run2.cob <= +run.cob!);
            if (lastOfficialRun) {
              this.run2Control.patchValue(lastOfficialRun);
            } else {
              this.run2Control.reset();
              this.run2Control.disable();
              this._notificationService.warn('There is no official run before selected run 1.');
            }
          }
        })
      )
      .subscribe();
    // this.run1Control.patchValue(this.data.allRuns?.find((run: IRun) => run.cob === this.data.rowSelected.cob1 && run.runId === this.data.rowSelected.runid1 && run.version === this.data.rowSelected.version1));
    // this.run2Control.patchValue(this.data.allRuns?.find((run: IRun) => run.cob === this.data.rowSelected.cob2 && run.runId === this.data.rowSelected.runid2 && run.version === this.data.rowSelected.version2));
  }

  private _initCobs(): void {
    /*this.cob1Control.valueChanges
      // @ts-ignore
      .pipe(takeUntil(this._unsubscribe),
        filter((cob1Run: IRun) => !!cob1Run),
        tap((cobRun: IRun) => {
          this.runs1 = this.data.allRuns.filter((run: IRun) => run.cob === cobRun.cob);
          if (this.runs1.length === 1) {
            this.run1Control.patchValue(this.runs1[0]);
          } else {
            const runs1PreviouslySelected = this.run1Index === 0 ? this.runs1.filter((run: IRun) => run.runId === this.data.rowSelected.runid1 && run.version === this.data.rowSelected.version1) : this.runs1;
            const lastOfficialOrValidOrUndefinedRun1 = runs1PreviouslySelected.find((run: IRun) => run.official || run.valid || run.valid === null || !run.hasOwnProperty('valid'));
            if (lastOfficialOrValidOrUndefinedRun1) {
              this.run1Control.patchValue(lastOfficialOrValidOrUndefinedRun1);
            } else {
              this.run1Control.patchValue(runs1PreviouslySelected[0]);
            }
          }
          if (+cobRun.cob < +this.cob2Control.value?.cob!) {
            const cobs2BeforeCob1 = this.data.allRuns.filter((run: IRun) => +run.cob <= +cobRun.cob);
            const lastOfficialCob2 = cobs2BeforeCob1.find((run: IRun) => run.official);
            if (lastOfficialCob2) {
              this.cob2Control.patchValue(lastOfficialCob2);
            } else {
              const lastValidRun2 = cobs2BeforeCob1.find((run: IRun) => run.valid);
              if (lastValidRun2) {
                this.cob2Control.patchValue(lastValidRun2);
              } else {
                this.cob2Control.patchValue(cobs2BeforeCob1[0]);
              }
            }
          }
          this.run1Index++;
        }))
      .subscribe();
    this.cob2Control.valueChanges
      // @ts-ignore
      .pipe(takeUntil(this._unsubscribe),
        filter((cob2Run: IRun) => !!cob2Run),
        tap((cobRun: IRun) => {
          this.runs2 = this.data.allRuns.filter((run: IRun) => run.cob === cobRun.cob);
          if (this.runs2.length === 1) {
            this.run2Control.patchValue(this.runs2[0]);
          } else {
            const runs2PreviouslySelected = this.run2Index === 0 ? this.runs2.filter((run: IRun) => run.runId === this.data.rowSelected.runid2 && run.version === this.data.rowSelected.version2) : this.runs2;
            const lastOfficialRun2 = runs2PreviouslySelected.find((run: IRun) => run.official);
            if (lastOfficialRun2) {
              this.run2Control.patchValue(lastOfficialRun2);
            } else {
              const lastValidRun2 = runs2PreviouslySelected.find((run: IRun) => run.valid);
              if (lastValidRun2) {
                this.run2Control.patchValue(lastValidRun2);
              } else {
                this.run2Control.patchValue(runs2PreviouslySelected[0]);
              }
            }
          }
          this.run2Index++;
        }))
      .subscribe();
    this.cob1Control.patchValue(this.data.allRuns?.find((run: IRun) => run.cob === this.data.rowSelected.cob1));
    this.cob2Control.patchValue(this.data.allRuns?.find((run: IRun) => run.cob === this.data.rowSelected.cob2));*/
  }

  // not enough! version also should be included?
  compareCobs(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.cob === c2.cob : c1 === c2;
  }

  // not enough! version also should be included?
  compareRuns(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.runId === c2.runId : c1 === c2;
  }

  onNoClick(): void {
    this.dialogRef.close(null);
  }

  onSaveClick(): void {
    const editedEmptyDeltasRow: IDeltaTableRow = this.data.rowSelected;
    editedEmptyDeltasRow.runid1 = this.run1Control.value ? this.run1Control.value.runId : this.data.rowSelected.runid1;
    editedEmptyDeltasRow.runid2 = this.run2Control.value ? this.run2Control.value.runId : this.data.rowSelected.runid2;
    editedEmptyDeltasRow.cob1 = this.run1Control.value ? this.run1Control.value.cob : this.data.rowSelected.cob1;
    editedEmptyDeltasRow.cob2 = this.run2Control.value ? this.run2Control.value.cob : this.data.rowSelected.cob2;
    editedEmptyDeltasRow.version1 = this.run1Control.value ? this.run1Control.value?.version : this.data.rowSelected.version1;
    editedEmptyDeltasRow.version2 = this.run2Control.value ? this.run2Control.value?.version : this.data.rowSelected.version2;
    editedEmptyDeltasRow.valid1 = this.run1Control.value ? this.run1Control.value.valid : this.data.rowSelected['valid1'];
    editedEmptyDeltasRow.official1 = this.run1Control.value ? this.run1Control.value?.official : this.data.rowSelected.official1;
    editedEmptyDeltasRow.to_export1 = this.run1Control.value ? this.run1Control.value?.to_export : this.data.rowSelected.to_export1;
    editedEmptyDeltasRow.run1OfficialNotLast = this.run1Control.value ? (this.run1Control.value?.official && this.runs1[0].runId !== this.run1Control.value?.runId ? true : false) : this.data.rowSelected.run1OfficialNotLast;
    editedEmptyDeltasRow.run2OfficialNotLast = this.run2Control.value ? (editedEmptyDeltasRow.runid1 !== editedEmptyDeltasRow.runid2 ? true : false) : this.data.rowSelected.run2OfficialNotLast;
    this.dialogRef.close(editedEmptyDeltasRow);
  }

  ngOnDestroy(): void {
    this._unsubscribe.next(null);
    this._unsubscribe.complete();
  }

  checkCob2Validity(cob2Selected: IRun): void {
    if (+cob2Selected.cob > +this.run1Control.value?.cob!) {
      this._notificationService.warn('Cob2 has to be before Cob1.');
    } else if (+cob2Selected.runId === +this.run1Control.value?.runId!) {
      this._notificationService.warn('RunId2 has to be different than RunId1.');
    }
  }

}
