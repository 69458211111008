import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, Subject} from "rxjs";

import {ICheckResponse, IEodData, IEodFullInput, IEodFullInput2, IEodInput3, ValidationService} from "@alpq/lib-api";


export interface ICheckMeta {
  no: number;
  check: string;
  name: string;
  url: string;
}

export interface IViolation {
  red?: string[];
  yellow?: string[];
}

export interface IDeltaTableRow {
  [key: string]: number | string | boolean | undefined | ICheckMeta[] | IViolation;
  asset: string;
  cob1?: number;
  cob2?: number;
  runid1?: number;
  runid2?: number;
  check?: 'OK' | 'NOK';
  version1?: number;
  version2?: number;
  valid1?: boolean;
  official1?: boolean;
  run1OfficialNotLast?: boolean;
  run2OfficialNotLast?: boolean;
  to_export1?: boolean;
  checks?: ICheckMeta[];
  violation?: IViolation;
  stoch: boolean;
}

export interface IReportPageSnapshot {
  mainCob?: string;
  assetsIntrinsicST?: string[];
  assetsIntrinsicLT?: string[];
  assetsExtrinsicST?: string[];
  assetsExtrinsicLT?: string[];
  dataSourceIntrinsic?: IDeltaTableRow[];
  dataSourceExtrinsic?: IDeltaTableRow[];
  checkResponsesIntrinsic?: ICheckResponse[];
  checkResponsesExtrinsic?: ICheckResponse[];
  displayedColumns1?: string[];
  displayedColumns2?: string[];
  comment?: string;
  horizon?: string;
}

export interface ICockpitTableRow {
  asset: string;
  runId?: number;
  timestamp?: string;
  valid?: string;
  reason?: string;
  mxImport?: string;
  eodReport?: string;
  hourlyRiskReport?: string;
  comment?: string;
}

export interface IEodPageSnapshot {
  reportType?: string;
  assetRunsCobs?: IEodFullInput2[];
  eodData?: IEodData;
  cob?: string;
}

export interface ICockpitPageSnapshot {
  mainCob?: string;
  horizon?: string;
  runType?: string;
  assets?: {
    intrinsic?: string[] | undefined,
    extrinsic?: string[] | undefined,
    intrinsic_ST: string[] | undefined,
    intrinsic_LT: string[] | undefined,
    extrinsic_ST: string[] | undefined,
    extrinsic_LT: string[] | undefined};
  dataSource?: ICockpitTableRow[];
}

@Injectable({
  providedIn: 'root'
})
export class LibTsvpeService {

  private _tabChangeS$: Subject<number> = new Subject<number>();
  private _runPageRunId$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private _reportPageSnapshotBS$: BehaviorSubject<IReportPageSnapshot> = new BehaviorSubject<IReportPageSnapshot>({});
  private _eodPageSnapshotBS$: BehaviorSubject<IEodPageSnapshot> = new BehaviorSubject<IEodPageSnapshot>({});
  private _eodInputBS$: BehaviorSubject<IEodFullInput[]> = new BehaviorSubject<IEodFullInput[]>([]);
  private _eodInput2BS$: BehaviorSubject<IEodInput3 | null> = new BehaviorSubject<IEodInput3 | null>(null);
  private _cockpitPageSnapshotBS$: BehaviorSubject<ICockpitPageSnapshot> = new BehaviorSubject<ICockpitPageSnapshot>({});
  private _eodTabEnable$: Subject<boolean> = new Subject<boolean>();


  get tabChangeS$(): Observable<number> {
    return this._tabChangeS$.asObservable();
  }

  get eodInputBS$(): Observable<IEodFullInput[]> {
    return this._eodInputBS$.asObservable();
  }

  get eodInput2BS$(): Observable<IEodInput3 | null> {
    return this._eodInput2BS$.asObservable();
  }

  get reportPageSnapshotBS$(): Observable<IReportPageSnapshot> {
    return this._reportPageSnapshotBS$.asObservable();
  }

  get eodPageSnapshotBS$(): Observable<IEodPageSnapshot> {
    return this._eodPageSnapshotBS$.asObservable();
  }

  get cockpitPageSnapshotBS$(): Observable<ICockpitPageSnapshot> {
    return this._cockpitPageSnapshotBS$.asObservable();
  }

  constructor(private _validationService: ValidationService) { }

  getRunIdState(): string {
    return this._runPageRunId$.getValue();
  }

  getRunPageRunId$(): Observable<string> {
    return this._runPageRunId$.asObservable();
  }

  updateRunIdState(runId: string) {
    this._runPageRunId$.next(runId);
  }

  changeTab(index: number): void {
    this._tabChangeS$.next(index);
  }

  updateEodInput(assetRuns: IEodFullInput[]): void {
    this._eodInputBS$.next(assetRuns);
  }

  updateEodInput2(eodInput: IEodInput3): void {
    this._eodInput2BS$.next(eodInput);
  }

  updateReportPageSnapshot(reportPageSnapshot: IReportPageSnapshot): void {
    this._reportPageSnapshotBS$.next(reportPageSnapshot);
  }

  getReportPageSnapshot(): IReportPageSnapshot {
    return this._reportPageSnapshotBS$.getValue();
  }

  updateEodPageSnapshot(eodPageSnapshot: IEodPageSnapshot): void {
    this._eodPageSnapshotBS$.next(eodPageSnapshot);
  }

  getCockpitPageSnapshot(): ICockpitPageSnapshot {
    return this._cockpitPageSnapshotBS$.getValue();
  }

  updateCockpitPageSnapshot(cockpitPageSnapshot: ICockpitPageSnapshot): void {
    this._cockpitPageSnapshotBS$.next(cockpitPageSnapshot);
  }

  updateEodTabEnable(enabled: boolean): void {
    this._eodTabEnable$.next(enabled);
  }

  getEodTabEnable$(): Observable<boolean> {
    return this._eodTabEnable$.asObservable();
  }
}
