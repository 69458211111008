<mat-card class="alpq-signin-container" *ngIf="!isInternal">
  <mat-card-header>
    <mat-card-title>{{appTitle}}</mat-card-title>
  </mat-card-header>
  <!--<mat-divider></mat-divider>-->
  <mat-card-content>
    <form [formGroup]="signInFormGroup" (ngSubmit)="signIn()">
      <mat-form-field appearance="outline">
        <mat-label i18n="Username|username@@usernameLabelSignIn">
          {{languageService.messages['signin.username.label'] | titlecase}}
        </mat-label><!--Username-->
        <input matInput #inputUsername [formControl]="usernameControl" name="username"
               placeholder="john.doe@email.com" maxlength="60" required>
        <!--<mat-hint align="end">{{inputUsername.value?.length || 0}}/60</mat-hint>-->
        <mat-error *ngIf="username.invalid">{{getUsernameErrorMessage()}}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label i18n="Password|password@@passwordLabelSignIn">
          {{languageService.messages['signin.password.label'] | titlecase}}
        </mat-label><!--Password-->
        <input matInput #inputPassword [formControl]="passwordControl" name="password"
               maxlength="60" required type="password">
        <!--<mat-hint align="end">{{inputPassword.value?.length || 0}}/60</mat-hint>-->
        <mat-error *ngIf="password.invalid">{{getPasswordErrorMessage()}}</mat-error>
      </mat-form-field>

      <button mat-button type="submit" class="alpq-btn-primary"
              [disabled]="signInFormGroup.invalid"
              i18n="SignIn|signIn@@signInButton">
        {{languageService.messages['signin.button.login'] | titlecase}}
      </button><!--Sign In-->
    </form>
  </mat-card-content>

</mat-card>
