<div class="tsvpe-cockpit">
  <div class="tsvpe-cockpit-controlPanel">
    <div class="tsvpe-cockpit-form">
      <mat-form-field class="tsvpe-cockpit-form-date">
        <mat-label>
          COB
        </mat-label>
        <input matInput [matDatepicker]="datePicker" [max]="today"
               [formControl]="dateControl" name="date">
        <mat-datepicker-toggle matPrefix [for]="datePicker"></mat-datepicker-toggle>
        <mat-datepicker-toggle matSuffix [for]="datePicker">
          <!--<mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>-->
          <mat-icon matDatepickerToggleIcon svgIcon="expand_more"></mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #datePicker></mat-datepicker>
      </mat-form-field>
    </div>
    &nbsp;&nbsp;&nbsp;&nbsp;
    <mat-radio-group aria-label="Select an option" [(ngModel)]="listTypeSelected"
                     class="radio-group" name="listType"> <!--(change)="onListTypeChange()"-->
      <mat-radio-button *ngFor="let listType of LIST_TYPES" [value]="listType" class="radio-option">
        {{listType.name}}
      </mat-radio-button>
    </mat-radio-group>
    &nbsp;&nbsp;&nbsp;&nbsp;
    <mat-radio-group aria-label="Select an option" [(ngModel)]="runListTypeSelected" class="radio-group" name="runListType">
      <mat-radio-button *ngFor="let runListType of RUN_LIST_TYPES" [value]="runListType" class="radio-option">
        {{runListType.name}}
      </mat-radio-button>
    </mat-radio-group>
    &nbsp;&nbsp;&nbsp;&nbsp;
    <button mat-button type="button" class="alpq-btn-primary"
            (click)="load()" [disabled]="!dateControl.value">Load</button>
  </div>

  <div class="tsvpe-cockpit-table">
    <lib-progress-spinner class="spinner" *ngIf="loading$ | async"></lib-progress-spinner>
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" *ngIf="dataSource.data && dataSource.data.length > 0">
      <ng-container matColumnDef="{{column}}" *ngFor="let column of displayedColumns">
        <th mat-header-cell *matHeaderCellDef> {{getNiceColumnHeader(column) | titlecase}} </th>
        <td mat-cell *matCellDef="let element"
            [ngClass]="[column === 'valid' ? element[column] === 'Yes' ? 'valid' : 'invalid' : '']">{{element[column]}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="onRowClick(row)"></tr>
    </table>
  </div>
</div>
