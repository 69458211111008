<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  Hourly Risk {{data.status}}
</h1>

<div mat-dialog-content>
  <ul>
    <li *ngFor="let option of data.options">{{option}}</li>
  </ul>
</div>

<div mat-dialog-actions>
  <button mat-button class="alpq-btn-secondary" (click)="onNoClick()">
    Cancel
  </button>
  <button mat-button class="alpq-btn-primary" (click)="onDoEod()">
    EOD Check no Hourly Risk
  </button>
</div>
