import {Injectable} from "@angular/core";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs";
import {tap} from "rxjs/operators";

import {LibStorageClientService} from "@alpq/lib-storage-client";

import {LibAuthenticationService} from "./lib-authentication.service";



@Injectable({
  providedIn: 'root'
})
export class AuthenticationInterceptor implements HttpInterceptor {

  constructor(
    private readonly _authService: LibAuthenticationService,
    private _storageClientService: LibStorageClientService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(this._authService.isAuthenticated) {
      //for internal requests, we attach the token
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${this._storageClientService.token}`
        }
      });
      return next.handle(req);
    } else {
      //public requests
      return next.handle(req).pipe(tap((event: HttpEvent<any>) => { }, (err: any) => {}));
    }

  }

}
