import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'lib-dialog-hourly-risk',
  templateUrl: './dialog-hourly-risk.component.html',
  styleUrls: ['./dialog-hourly-risk.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogHourlyRiskComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DialogHourlyRiskComponent>,
              @Inject(MAT_DIALOG_DATA) public data: {status: string | null, options: string[]}) { }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close(null);
  }

  onDoEod(): void {
    this.dialogRef.close(true);
  }
}
