import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParameterCodec,
  HttpParams,
  HttpResponse
} from "@angular/common/http";
import {catchError, finalize, map} from "rxjs/operators";
import {EMPTY, Observable, throwError} from "rxjs";
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';

import {LibNotificationService} from "@alpq/lib-notification";
import {LibStorageClientService} from "@alpq/lib-storage-client";


export interface IGetResponse {
  count: number;
  page: number;
  size: number;
  results: any[];
}

export interface IErrorResponse {
  message: string;
  details: string;
  status: number;
  timestamp: string;
}

//this is created to encode + sign when sending local dates
class CustomEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }

  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}

@Injectable({
  providedIn: 'root'
})
export class LibApiService {

  defaultHeaders: any = {
    "Content-Type": "application/json"
  };

  // @ts-ignore
  webSocketSubject$: WebSocketSubject<any>;

  constructor(private readonly http: HttpClient,
              private readonly _notificationService: LibNotificationService,
              private readonly _storageService: LibStorageClientService) { }

  request$(method: string,
           path: string,
           params?: any,
           data?: any,
           headers?: any,
           responseType?: "json" | "arraybuffer" | "blob" | "text",
           observe?: "body" | "events" | "response"): Observable<any> {

    const languageHeader = {
      "Accept-Language": this._storageService.language + ";q=0.9"
    };
    const headerList = {...this.defaultHeaders, ...languageHeader, ...headers};
    const reqHeaders: HttpHeaders = new HttpHeaders(headerList);
    let reqParams: HttpParams = new HttpParams({encoder: new CustomEncoder()});

    if (params) {
      if (params instanceof HttpParams) {
        reqParams = params;
      }
      else {
        Object.entries(params).forEach((entry: any) => {
          if (entry[1]) {
            if (typeof entry[1] !== 'string') {
              //maybe arrays will be passed in the future
              if (typeof entry[1] === 'object') {
                Object.entries(entry[1]).forEach((entry2: any) => {
                  if (entry2[1]){
                    if (!+entry2[0] && +entry2[0] !== 0) {
                      //array of objects (second key cannot be casted to number)
                      reqParams = reqParams.append(entry[0] + '[' + entry2[0] + ']', entry2[1]);
                    } else {
                      //array of strings
                      reqParams = reqParams.append(entry[0], entry2[1]);
                    }
                  }
                });
              }
            } else {
              reqParams = reqParams.append(entry[0], entry[1]);
            }
          }
        });

        /*Object.keys(params).forEach(function(key) {
          reqParams = reqParams.append(key, params[key]);
        });*/
      }
    }
    return this.http.request(method, path, {
      headers: reqHeaders,
      params: reqParams,
      body: data,
      responseType: responseType ? responseType : 'json',
      observe: observe ? observe : 'body'
    })
      .pipe(
        map(response  => {
          if (response instanceof HttpResponse) {
            return response as HttpResponse<any>;
          }
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          this._notificationService.error(error.error.message ? error.error.message : error.error.reason ? error.error.reason : error.error);
          if (this._notificationService.notifRecorderSwitch) {
            // this._notificationService.addNotifToList(error.error.message ? error.error.message : error.error.reason ? error.error.reason : error.error);
            this._notificationService.addHttpErrorToList(error);
          }
          // return EMPTY;
          return throwError(error);
        }),
        finalize(() => {})
      );
  }

  delete$<T>(path: string, data?: any, params?: any): Observable<any> {
    return this.request$('DELETE', path, params, data, {});
  }

  get$<T>(path: string, params?: any, headers?: any,
          responseType?: "json" | "arraybuffer" | "blob" | "text",
          observe?: "body" | "events" | "response"): Observable<any> {
    return this.request$('GET', path, params, {}, headers ? headers : {}, responseType, observe);
  }

  patch$<T>(path: string, data?: any): Observable<any> {
    return this.request$('PATCH', path, {}, data, {});
  }

  post$<T>(path: string, data?: any, headers?: any, params?: any): Observable<any> {
    return this.request$('POST', path, params, data, headers ? headers : {});
  }

  put$<T>(path: string, data?: any, params?: any ): Observable<any> {
    return this.request$('PUT', path, params, data, {});
  }

  //used mainly to attach the Authorization header...
  setHeader(header: any, value: any): void {
    if (header && value) {
      this.defaultHeaders[header] = value;
    }
  }

  /*------------------------WebSockets------------------------*/
  initWebSocketConnection$(url: string, protocol?: string): WebSocketSubject<any> {
    return this.webSocketSubject$ = webSocket({
      url,
      protocol
    });
  }
}
