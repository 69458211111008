import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl} from "@angular/forms";
import {Subject} from "rxjs";
import {switchMap, takeUntil, tap} from "rxjs/operators";

import {LibLanguageService} from "./lib-language.service";


@Component({
  selector: 'lib-language',
  templateUrl: './lib-language.component.html',
  styleUrls: ['./lib-language.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LibLanguageComponent implements OnInit, OnDestroy {
  private _unsubS$: Subject<any> = new Subject<any>();

  // @ts-ignore
  private _languages: string[];

  get languages(): string[] {
    return this._languages;
  }

  set languages(value: string[]) {
    this._languages = value;
  }

  languageControl = new FormControl<string | null>(null, []);

  constructor(private _languageService: LibLanguageService) { }

  ngOnInit(): void {
    this.languages = this._languageService.getLanguages();
    this.languageControl.patchValue(this._languageService.getLanguage());
    this._watchLanguageChange();
  }

  ngOnDestroy(): void {
    this._unsubS$.next(null);
    this._unsubS$.complete();
  }

  private _watchLanguageChange() {
    this.languageControl.valueChanges
      .pipe(
        // @ts-ignore
        takeUntil(this._unsubS$),
        switchMap((newLanguage: string) => this._languageService.getTranslation(newLanguage)),
        tap(() => this._languageService.broadcastNewLanguage())
      )
      .subscribe();
  }

}
