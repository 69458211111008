import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef, Inject,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {FormControl} from "@angular/forms";
import {MatTableDataSource} from "@angular/material/table";
import {BehaviorSubject, catchError, EMPTY, filter, Observable, of, Subject, switchMap, takeUntil, tap} from "rxjs";

import {
  HOST_PUBLIC_PLATFORM,
  ICheckResponse, IDeltaTableRowForExport,
  IEodData,
  IEodFullInput,
  IEodFullInput2, IEodInput3,
  IEodReport, PROJECT,
  VALIDATION_URL,
  ValidationService
} from "@alpq/lib-api";
import {LibNotificationService} from "@alpq/lib-notification";

import {ICheckMeta, IDeltaTableRow, IEodPageSnapshot, LibTsvpeService} from "./../lib-tsvpe.service";

import moment from 'moment';

export interface IEodTableRow {
  time: string;
  [key: string]: string | number;
}



export interface IEodTableColumn {
  id: string;
  header: string;
  upperHeader: string;
}

export interface IEodTableColumnUpperHeader {
  upperHeader: string;
  colspan: number;
  runId1?: number;
  runId2?: number;
  cob1?: number;
  cob2?: number;
}


@Component({
  selector: 'lib-eod',
  templateUrl: './eod.component.html',
  styleUrls: ['./eod.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EodComponent implements OnInit, AfterViewInit, OnDestroy {
  private readonly _unsubscribeS$: Subject<any> = new Subject<any>();
  private readonly _loadingBS$ = new BehaviorSubject<boolean>(false);
  public loading$ = this._loadingBS$.asObservable();
  private _eodCall = false;

  private _assetRunsCobs: IEodFullInput2[];
  private _cob: string;

  private _eodData: IEodData;

  readonly REPORTS = ['int_pos_abs_MWH','int_pos_abs_MW',
    'int_pos_diff_MWH','int_pos_diff_MW',
    'ext_hedge_diff_MWH','ext_hedge_diff_MW',
    'ext_pos_diff_MWH','ext_pos_diff_MW',
    'ext_Hminusint_diff_MWH','ext_Hminusint_diff_MW',
    'ext_hedge_abs_MWH','ext_hedge_abs_MW',
    'ext_pos_abs_MWH','ext_pos_abs_MW',
    'ext_Hminusint_abs_MWH','ext_Hminusint_abs_MW'];

  reportTypeControl = new FormControl<string | null>(this.REPORTS[0]);

  dataSource =  new MatTableDataSource<IEodTableRow>();
  columns: IEodTableColumn[] = [];
  private _displayedColumns: string[] = ['time'];

  get displayedColumns(): string[] {
    return this._displayedColumns;
  }

  set displayedColumns(value: string[]) {
    this._displayedColumns = value;
  }

  private _displayedColumnUpperHeaders: string[] = [];

  get displayedColumnUpperHeaders(): string[] {
    return this._displayedColumnUpperHeaders;
  }

  set displayedColumnUpperHeaders(value: string[]) {
    this._displayedColumnUpperHeaders = value;
  }

  private _columnUpperHeaders: IEodTableColumnUpperHeader[] = [];

  get columnUpperHeaders(): IEodTableColumnUpperHeader[] {
    return this._columnUpperHeaders;
  }

  set columnUpperHeaders(value: IEodTableColumnUpperHeader[]) {
    this._columnUpperHeaders = value;
  }

  @ViewChild('filterSection', { static: true }) filterSectionElementView: ElementRef;

  scrWidth: number;
  scrHeight: number;
  filterSectionHeight: number;

  constructor(private _tsvpeService: LibTsvpeService,
              private _validationService: ValidationService,
              private _notificationService: LibNotificationService,
              private _elementRef: ElementRef,
              @Inject('env') private readonly _env: any) { }

  ngOnInit(): void {
    this.scrWidth = window.innerWidth;
    this.scrHeight = window.innerHeight;
    this._watchReportType();
    this._tsvpeService.eodPageSnapshotBS$
      .pipe(takeUntil(this._unsubscribeS$),
        switchMap((eodPageSnapshot: IEodPageSnapshot) => {
          const currentIntrinsicAssetsMarked =
            this._tsvpeService.getReportPageSnapshot().dataSourceIntrinsic?.filter((row: IDeltaTableRow) => row['markedRunId1'] && row.runid1 && row.runid2)
            .map((row: IDeltaTableRow) => row.asset);
          const currentExtrinsicAssetsMarked =
            this._tsvpeService.getReportPageSnapshot().dataSourceExtrinsic?.filter((row: IDeltaTableRow) => row['markedRunId1'] && row.runid1 && row.runid2)
              .map((row: IDeltaTableRow) => row.asset);
          const selectedIntrinsic = this._tsvpeService.getReportPageSnapshot().dataSourceIntrinsic?.find((row: IDeltaTableRow) => row['markedRunId1']);
          const selectedExtrinsic = this._tsvpeService.getReportPageSnapshot().dataSourceExtrinsic?.find((row: IDeltaTableRow) => row['markedRunId1']);
          if (eodPageSnapshot && eodPageSnapshot.reportType && eodPageSnapshot.assetRunsCobs
            && eodPageSnapshot.eodData && eodPageSnapshot.cob
            && (
              ((!selectedIntrinsic && !selectedExtrinsic && this._tsvpeService.getReportPageSnapshot().dataSourceIntrinsic!.filter((row: IDeltaTableRow) => row.runid1 && row.runid2).length === eodPageSnapshot.assetRunsCobs.length)
                || (selectedIntrinsic && selectedExtrinsic && currentIntrinsicAssetsMarked && currentExtrinsicAssetsMarked
                  && currentIntrinsicAssetsMarked.toString() === eodPageSnapshot.assetRunsCobs.filter((eodFullInput: IEodFullInput2) => eodFullInput.intrinsic_runId1).map((eodFullInput: IEodFullInput2) => eodFullInput.asset).toString()
                  && currentExtrinsicAssetsMarked.toString() === eodPageSnapshot.assetRunsCobs.filter((eodFullInput: IEodFullInput2) => eodFullInput.extrinsic_runId1).map((eodFullInput: IEodFullInput2) => eodFullInput.asset).toString())
                || (selectedIntrinsic && currentIntrinsicAssetsMarked && !selectedExtrinsic
                  && currentIntrinsicAssetsMarked.toString() === eodPageSnapshot.assetRunsCobs.filter((eodFullInput: IEodFullInput2) => eodFullInput.intrinsic_runId1).map((eodFullInput: IEodFullInput2) => eodFullInput.asset).toString())
                || (selectedExtrinsic && currentExtrinsicAssetsMarked && !selectedIntrinsic
                  && currentExtrinsicAssetsMarked.toString() === eodPageSnapshot.assetRunsCobs.filter((eodFullInput: IEodFullInput2) => eodFullInput.extrinsic_runId1).map((eodFullInput: IEodFullInput2) => eodFullInput.asset).toString()))
              || this._eodCall)) {
            this._cob = eodPageSnapshot.cob;
            this._assetRunsCobs = eodPageSnapshot.assetRunsCobs;
            this._eodData = eodPageSnapshot.eodData;
            this.reportTypeControl.patchValue(eodPageSnapshot.reportType, {emitEvent: false});
            this._initTable(this._eodData, eodPageSnapshot.reportType);
            return EMPTY;
          } else {
            return this._getEodInput2BS$();
          }
        })
      )
      .subscribe({error: (error: any) => this._loadingBS$.next(false)});
  }

  private _getEodInput2BS$(): Observable<any> {
    return this._tsvpeService.eodInput2BS$
      .pipe(
        takeUntil(this._unsubscribeS$),
        filter((eodInput: IEodInput3 | null) => !!eodInput && eodInput.assets && eodInput.assets.length > 0),
        tap((eodInput: IEodInput3 | null) => {
          this._loadingBS$.next(true);
          this._assetRunsCobs = eodInput!.assets;
          this._cob = eodInput!.cob;
        }),
        switchMap((eodInput: IEodInput3 | null) => this._validationService.postEodCheck2$({
          cob: eodInput!.cob,
          horizon: eodInput!.horizon,
          HourlyRiskTaskIdentifier: eodInput!.HourlyRiskTaskIdentifier,
          HourlyRiskStatus: eodInput!.HourlyRiskStatus,
          assets: eodInput!.assets.map((assetRunCob: IEodFullInput2) => {
            return {
              asset: assetRunCob.asset,
              intrinsic_runId1: assetRunCob.intrinsic_runId1,
              intrinsic_runId2: assetRunCob.intrinsic_runId2,
              extrinsic_runId1: assetRunCob.extrinsic_runId1,
              extrinsic_runId2: assetRunCob.extrinsic_runId2,
              intrinsic_cob1: assetRunCob.intrinsic_cob1,
              intrinsic_cob2: assetRunCob.intrinsic_cob2,
              extrinsic_cob1: assetRunCob.extrinsic_cob1,
              extrinsic_cob2: assetRunCob.extrinsic_cob2}})}).pipe(catchError((err: any) => {this._eodCall = true; this._loadingBS$.next(false); return of();}))),
        tap((eodData: IEodData) => {
          this._eodCall = true;
          this._eodData = eodData;
          // this.reportTypeControl.value ? this._initTable(eodData, this.reportTypeControl.value) : null;
          this._tsvpeService.updateEodPageSnapshot({
            reportType: this.reportTypeControl.value!,
            assetRunsCobs: this._assetRunsCobs,
            eodData: this._eodData,
            cob: this._cob
          });
        })
      )
  }

  private _watchReportType(): void {
    this.reportTypeControl.valueChanges
      .pipe(
        takeUntil(this._unsubscribeS$),
        tap((reportType: string | null) => {
          if (this._eodData && reportType) {
            this._initTable(this._eodData, reportType);
            this._tsvpeService.updateEodPageSnapshot({reportType: reportType, eodData: this._eodData, assetRunsCobs: this._assetRunsCobs, cob: this._cob});
          }
        })
      )
      .subscribe();
  }

  private _initTable(eodData: IEodData, reportType: string): void {
    if(!eodData.hasOwnProperty(`${reportType}`)) {
      this._notificationService.warn(`No data found for ${reportType} report type.`);
      this.dataSource.data = [];
      return;
    }

    this._loadingBS$.next(true);
    const reportData: IEodReport = eodData[`${reportType}`];

    this.columns = [{id: 'time', header: 'Time', upperHeader: 'Stamp'}];
    reportData.pzone?.days?.columns.forEach((columnTuple: string[]) => {
      this.columns.push({
        id: columnTuple[0] + columnTuple[1],
        header: columnTuple[1],
        upperHeader: columnTuple[0]
      });
    });
    reportData.main.days.columns.forEach((columnTuple: string[]) => {
      this.columns.push({
        id: columnTuple[0] + columnTuple[1],
        header: columnTuple[1],
        upperHeader: columnTuple[0]
      });
    });

    // for data rows and normal (lower) header rows
    this.displayedColumns = this.columns.map((column: IEodTableColumn) => column.id);
    // for upper header rows
    this.displayedColumnUpperHeaders = Array.from(new Set(this.columns.map((column: IEodTableColumn) => column.upperHeader)));
    // for upper header columns (definitions)
    this.columnUpperHeaders = [];
    this.columns.forEach((column: IEodTableColumn) => {
      const foundColumnUpperHeader = this.columnUpperHeaders.find((upperHeader: IEodTableColumnUpperHeader) => upperHeader.upperHeader === column.upperHeader);
      if (foundColumnUpperHeader) {
        foundColumnUpperHeader.colspan++;
      } else {
        this.columnUpperHeaders.push({upperHeader: column.upperHeader, colspan: 1});
      }
    });
    this.columnUpperHeaders.forEach((upperHeader: IEodTableColumnUpperHeader) => {
      const assetRunCob = this._assetRunsCobs.find((assetRunCob: IEodFullInput2) => assetRunCob.asset === upperHeader.upperHeader);
      if (assetRunCob) {
        if (this.reportTypeControl.value?.startsWith('int')) {
          upperHeader.runId2 = assetRunCob.intrinsic_runId2;
          upperHeader.runId1 = assetRunCob.intrinsic_runId1;
          upperHeader.cob1 = assetRunCob.intrinsic_cob1;
          upperHeader.cob2 = assetRunCob.intrinsic_cob2;
        } else {
          upperHeader.runId2 = assetRunCob.extrinsic_runId2;
          upperHeader.runId1 = assetRunCob.extrinsic_runId1;
          upperHeader.cob1 = assetRunCob.extrinsic_cob1;
          upperHeader.cob2 = assetRunCob.extrinsic_cob2;
        }
      }
    });

    const tableRows: IEodTableRow[] = [];
    reportData.main.days.index.forEach((time: string, indexRow: number) => {
      const row: IEodTableRow = {time: time};
      if (reportData.pzone) {
        reportData.pzone.days.columns.forEach((columnTuple: string[], indexColumn: number) => {
          row[`${columnTuple[0] + columnTuple[1]}`] = reportData.pzone!.days.data[indexRow][indexColumn];
        });
      }
      reportData.main.days.columns.forEach((columnTuple: string[], indexColumn: number) => {
        row[`${columnTuple[0] + columnTuple[1]}`] = reportData.main.days.data[indexRow][indexColumn];
      });
      tableRows.push(row);
    });
    reportData.main.weekends.index.forEach((time: string, indexRow: number) => {
      const row: IEodTableRow = {time: time};
      if (reportData.pzone) {
        reportData.pzone.weekends.columns.forEach((columnTuple: string[], indexColumn: number) => {
          row[`${columnTuple[0] + columnTuple[1]}`] = reportData.pzone!.weekends.data[indexRow][indexColumn];
        });
      }
      reportData.main.weekends.columns.forEach((columnTuple: string[], indexColumn: number) => {
        row[`${columnTuple[0] + columnTuple[1]}`] = reportData.main.weekends.data[indexRow][indexColumn];
      });
      tableRows.push(row);
    });
    reportData.main.weeks.index.forEach((time: string, indexRow: number) => {
      const row: IEodTableRow = {time: time};
      if (reportData.pzone) {
        reportData.pzone.weeks.columns.forEach((columnTuple: string[], indexColumn: number) => {
          row[`${columnTuple[0] + columnTuple[1]}`] = reportData.pzone!.weeks.data[indexRow][indexColumn];
        });
      }
      reportData.main.weeks.columns.forEach((columnTuple: string[], indexColumn: number) => {
        row[`${columnTuple[0] + columnTuple[1]}`] = reportData.main.weeks.data[indexRow][indexColumn];
      });
      tableRows.push(row);
    });
    reportData.main.months.index.forEach((time: string, indexRow: number) => {
      const row: IEodTableRow = {time: time};
      if (reportData.pzone) {
        reportData.pzone.months.columns.forEach((columnTuple: string[], indexColumn: number) => {
          row[`${columnTuple[0] + columnTuple[1]}`] = reportData.pzone!.months.data[indexRow][indexColumn];
        })
      }
      reportData.main.months.columns.forEach((columnTuple: string[], indexColumn: number) => {
        row[`${columnTuple[0] + columnTuple[1]}`] = reportData.main.months.data[indexRow][indexColumn];
      });
      tableRows.push(row);
    });
    this.dataSource.data = tableRows;
    this._loadingBS$.next(false);
  }

  ngAfterViewInit(): void {
    this.filterSectionHeight = this.filterSectionElementView.nativeElement.offsetHeight;
    this._setTableDimensionsByOwnWindow();
  }

  export(): void {
    const reportPageSnapshot = this._tsvpeService.getReportPageSnapshot();

    let intrinsicTableForExport: IDeltaTableRowForExport[] = [];
    let intrinsicTable = reportPageSnapshot.dataSourceIntrinsic;
    intrinsicTable?.forEach((intrinsicRow: IDeltaTableRow) => {
      const ownChecks = reportPageSnapshot.checkResponsesIntrinsic?.filter((check: ICheckResponse) => check.metadata.asset === intrinsicRow.asset);
      if (ownChecks && ownChecks.length > 0) {
        intrinsicRow.checks = [];
        ownChecks.forEach((check: ICheckResponse) => {
          intrinsicRow.checks?.push({
            no: check.metadata.check.number,
            check: check.check,
            name: check.metadata.check.name,
            url: 'https://' + VALIDATION_URL +
              (this._env.production ? '' : this._env.env === 'local' ? '.dev' : '.' + this._env.env) +
              PROJECT + HOST_PUBLIC_PLATFORM +
              '/reports/checks' + `/${check.metadata.check.number}` + `/${check.metadata.run1.runid}` + `/${check.metadata.run2.runid}` + `/${check.metadata.asset}`
          });
        });
      }
    });
    intrinsicTable?.forEach((intrinsicRow: IDeltaTableRow) => {
      let years: {[key: string]: number}[] = [];
      const dateFieldKeys = Object.keys(intrinsicRow).filter((key: string) => moment(key, "YYYY-MM-DD", true).isValid());
      if (dateFieldKeys && dateFieldKeys.length > 0) {
        dateFieldKeys.forEach((dateKey: string) => years.push({[`${dateKey}`]: intrinsicRow[`${dateKey}`] as number}));
      }
      const rowForExport: IDeltaTableRowForExport = {
        asset: intrinsicRow.asset,
        check: intrinsicRow.check ? intrinsicRow.check : null,
        checks: intrinsicRow.checks && intrinsicRow.checks.length > 0 ? intrinsicRow.checks : [],
        cob1: intrinsicRow.cob1 ? intrinsicRow.cob1 : null,
        cob2: intrinsicRow.cob2 ? intrinsicRow.cob2 : null,
        official1: intrinsicRow.official1 ? intrinsicRow.official1 : null,
        run1OfficialNotLast: intrinsicRow.run1OfficialNotLast ? intrinsicRow.run1OfficialNotLast : null,
        runid1: intrinsicRow.runid1 ? intrinsicRow.runid1 : null,
        runid2: intrinsicRow.runid2 ? intrinsicRow.runid2 : null,
        to_export1: intrinsicRow.to_export1 ? intrinsicRow.to_export1 : null,
        valid1: intrinsicRow.valid1 ? intrinsicRow.valid1 : null,
        version1: intrinsicRow.version1 ? intrinsicRow.version1 : null,
        version2: intrinsicRow.version2 ? intrinsicRow.version2 : null,
        years: years
      };
      intrinsicTableForExport.push(rowForExport);
    });

    let extrinsicTableForExport: IDeltaTableRowForExport[] = [];
    let extrinsicTable = reportPageSnapshot.dataSourceExtrinsic;
    extrinsicTable?.forEach((extrinsicRow: IDeltaTableRow) => {
      const ownChecks = reportPageSnapshot.checkResponsesExtrinsic?.filter((check: ICheckResponse) => check.metadata.asset === extrinsicRow.asset);
      if (ownChecks && ownChecks.length > 0) {
        extrinsicRow.checks = [];
        ownChecks.forEach((check: ICheckResponse) => {
          extrinsicRow.checks?.push({
            no: check.metadata.check.number,
            check: check.check,
            name: check.metadata.check.name,
            url: 'https://' + VALIDATION_URL +
              (this._env.production ? '' : this._env.env === 'local' ? '.dev' : '.' + this._env.env) +
              PROJECT + HOST_PUBLIC_PLATFORM +
              '/reports/checks' + `/${check.metadata.check.number}` + `/${check.metadata.run1.runid}` + `/${check.metadata.run2.runid}` + `/${check.metadata.asset}`
          });
        });
      }
    });
    extrinsicTable?.forEach((extrinsicRow: IDeltaTableRow) => {
      let years: {[key: string]: number}[] = [];
      const dateFieldKeys = Object.keys(extrinsicRow).filter((key: string) => moment(key, "YYYY-MM-DD", true).isValid());
      if (dateFieldKeys && dateFieldKeys.length > 0) {
        dateFieldKeys.forEach((dateKey: string) => years.push({[`${dateKey}`]: extrinsicRow[`${dateKey}`] as number}));
      }
      const rowForExport: IDeltaTableRowForExport = {
        asset: extrinsicRow.asset,
        check: extrinsicRow.check ? extrinsicRow.check : null,
        checks: extrinsicRow.checks && extrinsicRow.checks.length > 0 ? extrinsicRow.checks : [],
        cob1: extrinsicRow.cob1 ? extrinsicRow.cob1 : null,
        cob2: extrinsicRow.cob2 ? extrinsicRow.cob2 : null,
        official1: extrinsicRow.official1 ? extrinsicRow.official1 : null,
        run1OfficialNotLast: extrinsicRow.run1OfficialNotLast ? extrinsicRow.run1OfficialNotLast : null,
        runid1: extrinsicRow.runid1 ? extrinsicRow.runid1 : null,
        runid2: extrinsicRow.runid2 ? extrinsicRow.runid2 : null,
        to_export1: extrinsicRow.to_export1 ? extrinsicRow.to_export1 : null,
        valid1: extrinsicRow.valid1 ? extrinsicRow.valid1 : null,
        version1: extrinsicRow.version1 ? extrinsicRow.version1 : null,
        version2: extrinsicRow.version2 ? extrinsicRow.version2 : null,
        years: years
      };
      extrinsicTableForExport.push(rowForExport);
    });

    this._validationService.postSendReport$(
      {
        intrinsic: intrinsicTableForExport,
        extrinsic: extrinsicTableForExport,
        eod: this._eodData,
        comment: reportPageSnapshot.comment ? reportPageSnapshot.comment : '',
        horizon: reportPageSnapshot.horizon ? reportPageSnapshot.horizon : 'ST'
      })
      .pipe(takeUntil(this._unsubscribeS$)).subscribe(() => this._notificationService.success('EoD Check exported.'));
  }

  isNegativeValue(value: any): boolean {
    let negative = false;
    if (typeof value === 'number') {
      if (value < 0) {
        negative = true;
      }
    }
    return negative;
  }

  private _setTableDimensionsByOwnWindow(): void {
    this._elementRef.nativeElement.style.setProperty('--table-width', this.scrWidth + 'px');
    this._elementRef.nativeElement.style.setProperty('--table-height', (this.scrHeight - 122 - this.filterSectionHeight) + 'px');
  }

  isGroupEnd(currRowIndex: number, nextRowIndex: number): boolean {
    return (nextRowIndex && this.dataSource.data[nextRowIndex]) ? this.dataSource.data[currRowIndex].time.length !== this.dataSource.data[nextRowIndex].time.length : false;
  }

  ngOnDestroy() {
    this._unsubscribeS$.next(null);
    this._unsubscribeS$.complete();
    this._loadingBS$.complete();
  }
}
