import { Injectable } from '@angular/core';
import {Observable} from "rxjs";

import {LibApiService} from './../lib-api.service';


export interface IDatabook {
  databook: string[];
  default: string[];
}

export interface IAssetGroup {
  group: string;
  assets: string[];
}

export interface IMonthlySwitchCobs {
  new: string;
  old: string;
}

export interface IMonthlySwitchDatabooks {
  new: IMonthlySwitchDatabooksMeta;
  'calcul-delta': IMonthlySwitchDatabooksMeta;
  old: IMonthlySwitchDatabooksMeta;
}

export interface IMonthlySwitchDatabooksMeta {
  databook: string;
  status: string;
  before?: string | null;
}

export interface IMonthlySwitchGroup {
  [key:string]: string[];
}

export interface IMonthlySwitchConfig {
  cobs: IMonthlySwitchCobs;
  databook: IMonthlySwitchDatabooks;
  group: IMonthlySwitchGroup;
  folder: string;
}

export interface IMonthlySwitchConfigTimed extends IMonthlySwitchConfig {
  timestamp: string;
}

export interface IMonthlySwitchAssetBody {
  runId?: number;
  databook?: string;
  status?: string;
  timestamp?: string;
}

export interface IMonthlySwitchAsset {
  new?: IMonthlySwitchAssetBody;
  'calcul-delta'?: IMonthlySwitchAssetBody;
  old?: IMonthlySwitchAssetBody;
}

export interface IMonthlySwitchAssets {
  [key:string]: IMonthlySwitchAsset;
}

export interface IMonthlySwitchRuns {
  assets: IMonthlySwitchAssets;
}

export interface IMonthlySwitchOutput {
  runs: IMonthlySwitchRuns;
}

export interface IMonthlySwitchReport {
  configuration: IMonthlySwitchConfig;
  output: IMonthlySwitchOutput;
}

export interface IMonthlySwitchReportResponse {
  group: string;
  task_id: string;
  message: string;
}

export interface IMonthlySwitchStatusResponse {
  group: string;
  task_id: string;
  message: string;
  status: string;
  path: string;
}

export interface ISummaryAssetRuns {
  new?: number;
  'calcul-delta'?: number;
  old?: number;
}

export interface ISummarySubgroup {
  runIds: ISummaryAssetRuns;
}

export interface ISummaryGroup {
  [key:string]: ISummarySubgroup;
}

export interface ISummaryGroupKey {
  [key:string]: ISummaryGroup;
}

export interface ISummaryReqPayload {
  suffix: string,
  timestamp: string,
  date_new: string,
  date_old: string,
  Groups: ISummaryGroupKey[];
}

export interface ISummaryReq {
  MonthlySwitchReport : ISummaryReqPayload
}

export const DATABOOK_URL = 'reporting/api/monthlyswitch/databooks';
export const ASSET_GROUPS_URL = 'reporting/api/monthlyswitch/groups';
export const CREATE_REPORT_URL = 'reporting/api/monthlyswitch/generate';
export const STATUS_REPORT_URL = 'reporting/api/monthlyswitch/status';
export const GENERATE_SUMMARY_URL = 'reporting/api/monthlyswitch/generate_summary';

@Injectable({
  providedIn: 'root'
})
export class ReportingService {

  constructor(private _apiService: LibApiService) { }


  getDatabook$(): Observable<IDatabook> {
    return this._apiService.get$<IDatabook>(DATABOOK_URL);
  }

  getAssetGroups$(): Observable<IAssetGroup[]> {
    return this._apiService.get$<IAssetGroup[]>(ASSET_GROUPS_URL);
  }

  createReport$(monthlySwitchReport: IMonthlySwitchReport): Observable<IMonthlySwitchReportResponse> {
    return this._apiService.post$<IMonthlySwitchReportResponse>(CREATE_REPORT_URL, monthlySwitchReport);
  }

  getReportStatus$(taskId: string): Observable<IMonthlySwitchStatusResponse> {
    return this._apiService.get$<IMonthlySwitchStatusResponse>(STATUS_REPORT_URL + `/${taskId}`);
  }

  generateSummary$(summaryReq: ISummaryReq): Observable<any> {
    return this._apiService.post$<any>(GENERATE_SUMMARY_URL, summaryReq);
  }
}
