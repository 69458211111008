import { NgModule } from '@angular/core';
import {CommonModule} from "@angular/common";
import {MatIconModule} from "@angular/material/icon";

import { LibNotificationComponent } from './lib-notification.component';



@NgModule({
  declarations: [
    LibNotificationComponent
  ],
  imports: [
    CommonModule,
    MatIconModule
  ],
  exports: [
    LibNotificationComponent
  ]
})
export class LibNotificationModule { }
