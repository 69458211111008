<div class="tsvpe-report">
  <div class="tsvpe-report-controlPanel">
    <div class="tsvpe-report-form">
      <mat-form-field class="tsvpe-report-form-date">
        <mat-label>
          COB
        </mat-label>
        <input matInput [matDatepicker]="datePicker" [max]="today"
               [formControl]="dateControl" name="date">
        <mat-datepicker-toggle matPrefix [for]="datePicker"></mat-datepicker-toggle>
        <mat-datepicker-toggle matSuffix [for]="datePicker">
          <!--<mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>-->
          <mat-icon matDatepickerToggleIcon svgIcon="expand_more"></mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #datePicker></mat-datepicker>
      </mat-form-field>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <mat-radio-group aria-label="Select an option" [(ngModel)]="listTypeSelected" class="radio-group" name="listType">
        <mat-radio-button *ngFor="let listType of LIST_TYPES" [value]="listType" class="radio-option">
          {{listType.name}}
        </mat-radio-button>
      </mat-radio-group>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <mat-radio-group aria-label="Select an option" [(ngModel)]="runListTypeSelected" class="radio-group" name="runListType">
        <mat-radio-button *ngFor="let runListType of RUN_LIST_TYPES" [value]="runListType" class="radio-option">
          {{runListType.name}}
        </mat-radio-button>
      </mat-radio-group>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <button mat-button type="button" class="alpq-btn-primary"
              (click)="load()" [disabled]="!dateControl.value">Load</button>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <button mat-button type="button" class="alpq-btn-primary" *ngIf="dataSourceIntrinsic && dataSourceIntrinsic.data && dataSourceIntrinsic.data.length > 0"
              (click)="selectAll()">{{runId1Selected ? 'Unselect all' : 'Select ' + (runListTypeSelected.name | lowercase) + ' all'}}</button>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <button mat-button type="button" class="alpq-btn-primary" *ngIf="dataSourceIntrinsic && dataSourceIntrinsic.data && dataSourceIntrinsic.data.length > 0"
              (click)="validate(true)">
        {{'Validate ' + (runListTypeSelected.name | lowercase) + (runId1Selected ? ' selected' : ' all')}}
      </button>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <button mat-button type="button" class="alpq-btn-primary" *ngIf="dataSourceIntrinsic && dataSourceIntrinsic.data && dataSourceIntrinsic.data.length > 0"
              (click)="validate(false)">
        {{'Invalidate ' + (runListTypeSelected.name | lowercase) + (runId1Selected ? ' selected' : ' all')}}
      </button>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <button mat-button type="button" class="alpq-btn-primary" *ngIf="dataSourceIntrinsic && dataSourceIntrinsic.data && dataSourceIntrinsic.data.length > 0"
              (click)="goEOD()">{{runId1Selected ? 'EOD Check selected' : 'EOD Check all'}}</button>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <button mat-button type="button" class="alpq-btn-primary" *ngIf="dataSourceIntrinsic && dataSourceIntrinsic.data && dataSourceIntrinsic.data.length > 0"
              (click)="export()">{{runListTypeSelected.id === 1 ? (runId1Selected ? 'Export selected' : 'Export ' + (runListTypeSelected.name | lowercase) + ' all') : ('Export ' + (runListTypeSelected.name | lowercase) + ' all')}}</button>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <br/>
      <button mat-button type="button" class="alpq-btn-primary" *ngIf="dataSourceIntrinsic && dataSourceIntrinsic.data && dataSourceIntrinsic.data.length > 0"
              [disabled]="!runId1Selected" (click)="rollback()">Rollback selected</button>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <button mat-button type="button" class="alpq-btn-primary" *ngIf="dataSourceIntrinsic && dataSourceIntrinsic.data && dataSourceIntrinsic.data.length > 0"
              [disabled]="!runId1Selected" (click)="copyLR1OnLR2()">Copy LR1 over LR2 on selection</button>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <button mat-button type="button" class="alpq-btn-secondary"
              (click)="seeLogs()">Logs</button>
    </div>
    <span class="load-time" *ngIf="pageLoadTime">Duration: {{pageLoadTime + 's'}}</span>
  </div>

  <mat-form-field class="tsvpe-report-comment" *ngIf="dataSourceIntrinsic && dataSourceIntrinsic.data && dataSourceIntrinsic.data.length > 0">
    <mat-label>Comment</mat-label>
    <textarea matInput [formControl]="commentControl" name="comment"
              cdkTextareaAutosize
              #autosize="cdkTextareaAutosize"
              cdkAutosizeMinRows="1"
              cdkAutosizeMaxRows="5"></textarea>
  </mat-form-field>

  <div class="tsvpe-report-legend" *ngIf="dataSourceIntrinsic && dataSourceIntrinsic.data && dataSourceIntrinsic.data.length > 0">
    <span>
      Click on the Runid1 column to select a run for validation or invalidation.
    </span>
    <span>
      Official run1 ids are marked in bold font.
    </span>
  </div>

  <div class="progress" *ngIf="queryProgress">
    <span class="progress-label">Loading:</span> &nbsp;
    <mat-progress-bar mode="determinate" value="{{queryProgress}}" *ngIf="queryProgress"></mat-progress-bar> &nbsp;
    <span class="progress-label">{{queryProgress | number : '1.0-0'}}%</span>
  </div>

  <div class="tsvpe-report-table" *ngIf="(loading$ | async) || (dataSourceIntrinsic && dataSourceIntrinsic.data && dataSourceIntrinsic.data.length > 0)">
    <lib-progress-spinner class="spinner" *ngIf="loading$ | async"></lib-progress-spinner>
    <table mat-table [dataSource]="dataSourceIntrinsic" class="mat-elevation-z8" *ngIf="!(loading$ | async)">

      <ng-container matColumnDef="{{column}}" *ngFor="let column of displayedColumns1">
        <th mat-header-cell *matHeaderCellDef> {{column | titlecase}} </th>
        <td mat-cell *matCellDef="let element" [ngClass]="[isNegativeValue(element[column]) ? 'negative-val' : '',
          column === 'check' ? getCheckClassBg(element) : '',
          column === 'runid1' ? getValidRunId1ClassBg(element) : '',
          column === 'runid1' ? getMarkedRunId1ClassBg(element) : '',
          column === 'runid1' ? getOfficialRunId1ClassBg(element) : '',
          element.violation ? element.violation.red ? isViolation(element.violation.red, column) ? 'red-violation-bg' : '' : '' : '',
          element.violation ? element.violation.yellow ? isViolation(element.violation.yellow, column) ? 'yellow-violation-bg' : '' : '' : '']"
          (click)="column === 'runid1' ? markRunId1(element) : column === 'check' ? openCheckDialog(element, 0) : null;$event.stopPropagation();">
          <span *ngIf="!isDeltaColumn(column)">{{element[column]}}</span><!-- {{column === 'runid1' && element.run1OfficialNotLast? '!' : ''}}-->
          <span *ngIf="!isDeltaColumn(column) && column === 'runid1' && element.run1OfficialNotLast" class="negative-val"> !</span>
          <span *ngIf="isDeltaColumn(column)">{{element[column] | number | numberCh}}</span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns1;"
          (contextmenu)="openActionsMenu(row, $event);$event.preventDefault()" [matMenuTriggerFor]="actionsMenu"
          (click)="$event.stopPropagation();">
      </tr>
    </table>

    <table mat-table [dataSource]="dataSourceExtrinsic" class="mat-elevation-z8" *ngIf="!(loading$ | async)">

      <ng-container matColumnDef="{{column}}" *ngFor="let column of displayedColumns2">
        <th mat-header-cell *matHeaderCellDef> {{column | titlecase}} </th>
        <td mat-cell *matCellDef="let element" [ngClass]="[isNegativeValue(element[column]) ? 'negative-val' : '',
          column === 'check' ? getCheckClassBg(element) : '',
          column === 'runid1' ? getValidRunId1ClassBg(element) : '',
          column === 'runid1' ? getMarkedRunId1ClassBg(element) : '',
          column === 'runid1' ? getOfficialRunId1ClassBg(element) : '',
          element.violation ? element.violation.red ? isViolation(element.violation.red, column) ? 'red-violation-bg' : '' : '' : '',
          element.violation ? element.violation.yellow ? isViolation(element.violation.yellow, column) ? 'yellow-violation-bg' : '' : '' : '']"
          (click)="column === 'runid1' ? markRunId1(element) : column === 'check' ? openCheckDialog(element, 1) : null;$event.stopPropagation();">
          <span *ngIf="!isDeltaColumn(column)">{{element[column]}}</span>
          <span *ngIf="isDeltaColumn(column)">{{element[column] | number | numberCh}}</span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns2;"
          (contextmenu)="openActionsMenu(row, $event);$event.preventDefault()" [matMenuTriggerFor]="actionsMenu"
          (click)="$event.stopPropagation();">
      </tr>
    </table>

  </div>
</div>

<div>
  <mat-menu #actionsMenu="matMenu">
    <button mat-menu-item (click)="openChangeRunDialog()">Change run(s)</button>
    <button mat-menu-item (click)="openInfoDialog()">Info</button>
  </mat-menu>
</div>




