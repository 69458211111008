import {NgModule} from '@angular/core';
import {MatTabsModule} from "@angular/material/tabs";
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatInputModule} from "@angular/material/input";
import {MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule} from "@angular/material/form-field";
import {MatSelectModule} from "@angular/material/select";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MatTableModule} from "@angular/material/table";
import {MatGridListModule} from "@angular/material/grid-list";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatDialogModule} from "@angular/material/dialog";
import {OverlayModule} from "@angular/cdk/overlay";
import {MatRadioModule} from "@angular/material/radio";
import {MatMenuModule} from "@angular/material/menu";
import {MatListModule} from "@angular/material/list";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";

import {LibProgressSpinnerModule} from "@alpq/lib-progress-spinner";

import { LibTsvpeComponent } from './lib-tsvpe.component';
import { CockpitComponent } from './cockpit/cockpit.component';
import { RunComponent } from './run/run.component';
import { ReportComponent } from './report/report.component';
import { DialogChangeRunComponent } from './report/dialog-change-run/dialog-change-run.component';
import { DialogChecksComponent } from "./report/dialog-checks/dialog-checks.component";
import { NumberChPipe } from "./shared/number-ch.pipe";
import { DialogRunInfoComponent } from './report/dialog-run-info/dialog-run-info.component';
import { DialogLogsComponent } from './report/dialog-logs/dialog-logs.component';
import { EodComponent } from './eod/eod.component';
import { MonthlySwitchComponent } from './monthly-switch/monthly-switch.component';
import { DialogHourlyRiskComponent } from './report/dialog-hourly-risk/dialog-hourly-risk.component';



@NgModule({
  declarations: [
    LibTsvpeComponent,
    CockpitComponent,
    RunComponent,
    ReportComponent,
    DialogChangeRunComponent,
    DialogChecksComponent,
    NumberChPipe,
    DialogRunInfoComponent,
    DialogLogsComponent,
    EodComponent,
    MonthlySwitchComponent,
    DialogHourlyRiskComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatTabsModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
    MatIconModule,
    MatTableModule,
    MatGridListModule,
    MatDatepickerModule,
    MatDialogModule,
    OverlayModule,
    LibProgressSpinnerModule,
    MatRadioModule,
    MatMenuModule,
    MatListModule,
    DragDropModule,
    MatProgressBarModule,
    MatSlideToggleModule
  ],
  providers: [
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } }
],
  exports: [
    LibTsvpeComponent,
    NumberChPipe
  ]
})
export class LibTsvpeModule { }
