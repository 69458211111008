import { NgModule } from '@angular/core';
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";



@NgModule({})
export class LibMaterialModule {
  constructor(
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer
  ) {
    //here we add all svg images that we want to use together with mat-icon
    this.matIconRegistry.addSvgIcon(
      "alpiq-logo",
      this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/icons/alpiq-logo.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "chevron_left",
      this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/icons/chevron_left.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "chevron_right",
      this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/icons/chevron_right.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "expand_more",
      this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/icons/expand_more.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "mode_edit",
      this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/icons/mode_edit.svg")
    );
  }
}
