import { Injectable } from '@angular/core';
import {Observable} from "rxjs";


import {LibApiService} from './../lib-api.service';

export const USER_URL = 'user/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private _apiService: LibApiService) { }

  getUserRoles$(): Observable<any> {
    return this._apiService.get$<any>(USER_URL + '/roles');
  }
}
