import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';

@Component({
  selector: 'lib-authentication',
  templateUrl: './lib-authentication.component.html',
  styleUrls: ['./lib-authentication.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LibAuthenticationComponent implements OnInit {

  get isInternal(): boolean {
    return this._env.internal;
  }

  constructor(@Inject('env') private readonly _env: any) { }

  ngOnInit(): void {
  }

}
