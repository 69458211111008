import { Injectable } from '@angular/core';
import {Observable} from "rxjs";

import {LibApiService} from './../lib-api.service';


export interface IExport {
  exportId: number;
  system: string;
  sent: string;
  who: string;
}

export interface IRun {
  runId: number;
  version: number;
  official: boolean;
  to_export: boolean;
  cob: number;
  databook: string;
  asset: string;
  fuel: string;
  pzone: string;
  timestamp: string;
  modified?: string;
  comment?: string;
  valid?: boolean;
  validationBy?: string;
  reason?: string;
  export?: IExport;
  scen?: string;
  stoch?: boolean;
  tags?: any;
}

export interface ISystem {
  systemId: number;
  name: string;
  description: string;
}

export interface IRunLastParam {
  asset: string;
  databook: string;
  cob: string;
  closest?: string;
  valid?: string;
  stoch?: string;
  system?: string;
  limit?: string;
  lastVersionOnly?: string;
  official?: string;
  before?: string;
  timeReference?: string;
}

export interface IExportAssetPayload {
  asset: string | null;
  cob: number | null;
  runId: number | null;
}


export interface IExportRunPayload {
  run1: IExportAssetPayload;
  run2: IExportAssetPayload;
}

export interface IExportPayload {
  asset_runs: IExportRunPayload[];
}

export interface I {
  calculation_name: string;
  cob: number;
  is_assets_runids_explicit: boolean;
  assets: IExportAssetPayload[];
}

export interface IExportResponse {
  hourlyRiskTaskIdentifier: string;
  submitted_at: string;
  submitted_by: string;
  total_calculations_submitted: number;
  submitted_calculations: any[];
}

export enum HOURLY_RISK_STATUS_ENUM {
  WAITING_GO_SIGNAL = 'Waiting GO signal',
  RECEIVED = 'RECEIVED',
  PENDING = 'PENDING',
  STARTED = 'STARTED',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  REVOKED = 'REVOKED'
}

export interface IHourlyRiskStatusResponse {
  ready: boolean;
  status: string | null;
  successful: boolean;
  value: any;
  done_at: string;
}

export const RUNS_URL = 'runloader/runs';
export const COBS_URL = 'runloader/cobs';
export const ASSETS_RUNLOADER_URL = 'runloader/assets';
export const DATABOOKS_URL = 'runloader/databooks';
export const SYSTEMS_URL = 'runloader/systems';
export const HOURLY_RISK_URL = 'runloader/hourly-risk';


@Injectable({
  providedIn: 'root'
})
export class RunloaderService {

  constructor(private _apiService: LibApiService) { }

  getCobs$(startDate?: number, endDate?: number): Observable<number[]> {
    return this._apiService.get$<number[]>(COBS_URL, {startDate: startDate?.toString(), endDate: endDate?.toString()});
  }

  getRuns$(): Observable<IRun[]> {
    return this._apiService.get$<IRun[]>(RUNS_URL);
  }

  getLastRuns$(params: IRunLastParam): Observable<IRun[]> {
    return this._apiService.get$<IRun[]>(RUNS_URL + '/last', params);
  }

  getClosestRuns$(params: IRunLastParam): Observable<IRun[]> {
    return this._apiService.get$<IRun[]>(RUNS_URL + '/closest', params);
  }

  getRun$(runId: number): Observable<IRun[]> {
    return this._apiService.get$<IRun[]>(RUNS_URL + `/${runId}`);
  }

  putAnnotate$(runId: number, payload: {comment: string, tags: any}): Observable<IRun> {
    return this._apiService.put$<IRun>(RUNS_URL + `/${runId}` + '/annotate', payload);
  }

  putValid$(runId: number, params: {valid: string, reason: string}): Observable<IRun> {
    return this._apiService.put$<IRun>(RUNS_URL + `/${runId}` + '/setValid', null, params);
  }

  postAddExport$(runId: number, params: {system: string}): Observable<IRun> {
    return this._apiService.post$<IRun>(RUNS_URL + `/${runId}` + '/add-export', null, null, params);
  }

  getSystems$(): Observable<ISystem[]> {
    return this._apiService.get$<ISystem[]>(SYSTEMS_URL);
  }

  putOfficial$(runId: number, params: {official: string}): Observable<IRun> {
    return this._apiService.put$<IRun>(RUNS_URL + `/${runId}` + '/setOfficial', null, params);
  }

  putToExport$(runId: number, params: {to_export: string}): Observable<IRun> {
    return this._apiService.put$<IRun>(RUNS_URL + `/${runId}` + '/to-export', null, params);
  }

  export$(system: string, payload: IExportPayload): Observable<IExportResponse> {
    return this._apiService.post$<IExportResponse>(RUNS_URL + '/export' + `/${system}`, payload);
  }

  getHourlyRiskStatusByTaskId$(taskId: string): Observable<IHourlyRiskStatusResponse> {
    return this._apiService.get$<IHourlyRiskStatusResponse>(HOURLY_RISK_URL + `/tasks/${taskId}`);
  }
}
